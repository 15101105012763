import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
  Menu,
  MenuItem,
  Divider,
  TablePagination,
  Stack,
  Chip,
} from "@mui/material";
import moment from "moment";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import CustomizedTableCell from "../../CustomComponents/table/tableCell";
import CustomizedTableHead from "../../CustomComponents/table/tableHead";
import CustomizedTableRow from "../../CustomComponents/table/tableRow";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loaders/Loader";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  allLocations,
  selectedLocation,
  setSelectedLocation,
  getsentimentalLocation,
  requestLoading,
  handleOpenCreateScheduleModal,
  handleOpenDeleteScheduledReportModel,
  // groupsPageNumber,
  setGroupsPageNumber,
  setScheduledRportsPageNumber,
  scheduledRportsPageNumber,
  // rowsPerPage,
  // setRowsPerPage,
  rowsPerPageScheduled,
  setRowsPerPageScheduled,
  // locationGroups,
  scheduledReports,
  scheduledReportsLoading,
  // groupsLoading,
  // setDeleteMultiple,
  handleDeleteScheduleReport,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  handleUpdateStatus,
  handleOpenUpdateStatusModel,
  setScheduleReportFilters = () => {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [deletegroups, setDeleteGroups] = useState([]);
  const [localSearchVal, setLocalSearchVal] = useState("");
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectAllStatus, setSelectAllStatus] = useState(false);

  const STATUS_FILTER_OPTIONS = useMemo(() => {
    return [
      { label: t("Active"), value: "pending,repeat" },
      { label: t("Completed"), value: "done" },
      { label: t("Failed"), value: "failed" },
      { label: t("Paused"), value: "paused" },
    ];
  }, [t]);

  const filter = createFilterOptions();
  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    let dummyArr = [];
    if (e.target.checked) {
      for (var i = 0; i < scheduledReports?.results?.length; i++) {
        deletegroups.push(scheduledReports?.results[i]?.id);
        dummyArr?.push(scheduledReports?.results[i]?.id);
      }

      const uniqueGroupIds = new Set(deletegroups);
      // setDeleteMultiple(true);
      setDeleteGroups([...uniqueGroupIds]);
    } else {
      setDeleteGroups([]);
      // setDeleteMultiple(false);
    }
  };

  const handleTaskChecked = (e, index, taskId) => {
    if (e.target.checked) {
      deletegroups.push(taskId);
    } else {
      var groupIndex = deletegroups.indexOf(taskId);
      deletegroups.splice(groupIndex, 1);
    }
    setDeleteGroups([...deletegroups]);
    if (scheduledReports?.results?.length === deletegroups?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageScheduled(parseInt(event.target.value, 10));
    setScheduledRportsPageNumber(0);
  };
  const handleChangePage = (event, newPage) => {
    setScheduledRportsPageNumber(newPage);
  };

  const headCells = [
    // {
    //   padding: "0px",
    //   alignment: "left",
    //   label: (
    //     <Checkbox
    //       checked={selectAll}
    //       onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
    //     />
    //   ),
    // },
    // { id: "ID", alignment: "left", label: t("ID") },
    { id: "report_names", alignment: "left", label: t("report_names") },
    { id: "created_byy", alignment: "left", label: t("created_byy") },
    { id: "frequency", alignment: "left", label: t("frequency") },
    { id: "LocationStatus", alignment: "center", label: t("LocationStatus") },
    { id: "satisfaction", alignment: "right", label: t("Actions") },
  ];

  const handleClick = (event, id, selectedData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
    // setDeleteMultiple(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
    // setDeleteMultiple(false);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    // setSelectedRow(null);
    // setDeleteMultiple(false);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalSearchVal(value); // Update local state immediately
    // debouncedSetSearchVal(value); // Update external state after debounce
  };

  const handleApplyFilters = () => {
    const status = selectedFilter
      .filter((option) => option.value !== "all")
      .map((item) => item.value)
      .join(",");
    setScheduledRportsPageNumber(0);
    setScheduleReportFilters({
      searchVal: localSearchVal,
      status,
    });
  };

  useEffect(() => {
    return () => {
      setScheduledRportsPageNumber(0);
      setScheduleReportFilters({
        searchVal: "",
        status: "",
      });
    }; // clean up function
  }, []);

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "950px",
              height: "550px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 16px",
            }}
          >
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "3px",
              }}
            >
              {title}
            </Typography>
            <Box sx={{ marginRight: "7px" }}>
              {scheduledReports?.results?.length > 0 &&
                (addPermission && viewPermission ? (
                  <CommonButton
                    onSubmit={() => handleOpenCreateScheduleModal(null)}
                    customHeight={true}
                    label={t("schedule_create")}
                    icon={<AddIcon sx={{ color: "#FFFF" }} />}
                  />
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <CommonButton
                        onSubmit={() => handleOpenCreateScheduleModal(null)}
                        customHeight={true}
                        label={t("schedule_create")}
                        icon={<AddIcon sx={{ color: "#FFFF" }} />}
                        disabled={true}
                      />
                    </span>
                  </BootstrapTooltip>
                ))}
            </Box>
          </DialogTitle>
          <DialogContent dividers style={{ padding: "16px" }}>
            {scheduledReports?.results?.length === 0
              ? localSearchVal.length > 0 || selectedFilter.length > 0
                ? true
                : false
              : true && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6.5}>
                      <TextField
                        placeholder={t("search_by_name")}
                        value={localSearchVal}
                        onChange={handleSearchChange}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        multiple
                        options={STATUS_FILTER_OPTIONS}
                        className="Autocomplete-field"
                        value={selectedFilter}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={
                                (option?.id === 0 && selectAllStatus) ||
                                selected
                              }
                            />
                            {option.label}
                          </li>
                        )}
                        onChange={(_, value) => {
                          const isAllSelected = value.some(
                            (option) => option.value === "all"
                          );

                          if (value.find((option) => option.id === 0)) {
                            if (
                              selectedFilter?.length ===
                              STATUS_FILTER_OPTIONS?.length
                            ) {
                              setSelectedFilter([]);
                              setSelectAllStatus(false);
                            } else {
                              setSelectAllStatus(true);
                              setSelectedFilter(STATUS_FILTER_OPTIONS);
                            }
                          } else {
                            setSelectedFilter(value);

                            if (
                              value?.length === STATUS_FILTER_OPTIONS?.length
                            ) {
                              setSelectAllStatus(true);
                            } else {
                              setSelectAllStatus(false);
                            }
                          }
                        }}
                        getOptionLabel={(option) =>
                          option?.label ? option?.label : ""
                        }
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          return [{ id: 0, label: "All" }, ...filtered];
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder={
                              selectedFilter.length < 1 ? t("All") : ""
                            }
                            {...params}
                            required
                            name="language"
                          />
                        )}
                        renderTags={(value, getTagProps) => {
                          const MAX_DISPLAY_ITEMS = 1;
                          const tooltipText =
                            selectedFilter
                              ?.filter((_, idx) => {
                                const isAllAtIndex0 =
                                  selectedFilter[0]?.value === "all";

                                return isAllAtIndex0 ? idx > 1 : idx > 0;
                              })
                              ?.map((option) => option.label)
                              ?.join(", ") || "";
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "100%",
                              }}
                            >
                              <Stack
                                direction="column"
                                spacing={1}
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  flexWrap: "nowrap",
                                  display: "inline-block",
                                }}
                              >
                                {value
                                  .filter((option) => option.value !== "all")
                                  .slice(0, MAX_DISPLAY_ITEMS)
                                  .map((option, index) => (
                                    <Chip
                                      style={{
                                        background: "#FFF",
                                        border: "1px solid #06BDFF",
                                        borderRadius: "800px",
                                      }}
                                      key={index}
                                      label={option?.label}
                                      {...getTagProps({ index })}
                                    />
                                  ))}
                                {value.filter(
                                  (option) => option.value !== "all"
                                ).length > MAX_DISPLAY_ITEMS && (
                                  <BootstrapTooltip title={tooltipText}>
                                    <Chip
                                      style={{
                                        background: "#FFF",
                                        border: "1px solid #BBBDC1",
                                        borderRadius: "800px",
                                        cursor: "pointer",
                                        fontWeight: 700,
                                        width: "100px",
                                        marginTop: "0px",
                                      }}
                                      label={`+${
                                        value.filter(
                                          (option) => option.value !== "all"
                                        ).length - MAX_DISPLAY_ITEMS
                                      } more`}
                                    />
                                  </BootstrapTooltip>
                                )}
                              </Stack>
                            </Box>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                      <CommonButton
                        onSubmit={handleApplyFilters}
                        leftMargin
                        label={t("Apply_filters")}
                      />
                    </Grid>
                  </Grid>
                )}
            {scheduledReportsLoading ? (
              <Loader />
            ) : scheduledReports !== null &&
              scheduledReports?.results?.length > 0 ? (
              <Grid
                container
                spacing={4}
                sx={{
                  height: "auto",
                }}
              >
                {deletegroups?.length > 0 && (
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "auto",
                    }}
                  >
                    <Box>
                      {deletePermission && viewPermission ? (
                        <Button
                          sx={{
                            color: "white",
                            backgroundColor: "#FC3652",
                            "&:hover": {
                              backgroundColor: "#ff3366",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() =>
                            handleDeleteScheduleReport(
                              "delete",
                              selectedRow?.id
                            )
                          }
                        >
                          {t("Delete")}
                        </Button>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <Button
                              sx={{
                                backgroundColor: "#e0e0e0",
                                color: "white",
                              }}
                              disabled
                              onClick={() =>
                                handleDeleteScheduleReport(
                                  "delete",
                                  selectedRow?.id
                                )
                              }
                            >
                              {t("Delete")}
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Box>
                  </Grid>
                )}

                <Grid
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  sx={
                    {
                      // marginTop: "12px",
                      // overflow: "auto",
                      // maxHeight: "280px",
                      // height: "260px",
                      // border: "1px solid rgba(0,0,0,0.12)",
                      // borderRadius: "8px",
                    }
                  }
                >
                  <TableContainer component={Paper}>
                    <Table
                      aria-labelledby="tableTitle"
                      size={"medium"}
                      aria-label="enhanced table"
                    >
                      <TableHead sx={{ padding: "0px" }}>
                        <TableRow>
                          {headCells.map((headCell) => (
                            <TableCell
                              sx={{
                                fontWeight: 700,
                                fontSize: "14px",
                                paddingRight: headCell?.padding
                                  ? headCell?.padding
                                  : "26px",
                                paddingLeft: headCell?.padding
                                  ? headCell?.padding
                                  : "16px",
                              }}
                              key={headCell.id}
                              align={headCell.alignment}
                            >
                              {headCell.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {scheduledReports?.results?.length > 0 ? (
                          scheduledReports?.results?.map((row, index) => {
                            return (
                              <TableRow
                                sx={{ fontWeight: "700px" }}
                                tabIndex={-1}
                                key={index}
                              >
                                {/* <TableCell
                              hover
                              sx={{ width: "40px", padding: "0px" }}
                            >
                              <Checkbox
                                checked={deletegroups?.includes(row.id)}
                                onChange={(e) => {
                                  handleTaskChecked(e, index, row.id, row);
                                }}
                                label=""
                              />
                            </TableCell> */}

                                {/* <TableCell align="left">{row?.id}</TableCell> */}

                                <TableCell
                                  align="left"
                                  sx={{
                                    width: "250px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row?.title}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.createBy?.firstName !== null &&
                                  row?.createBy?.lastName !== null
                                    ? row?.createBy?.firstName +
                                      " " +
                                      row?.createBy?.lastName
                                    : "--"}
                                </TableCell>
                                <TableCell align="left">
                                  {/* {row?.repeatType?.charAt(0).toUpperCase() +
                                  row?.repeatType?.slice(1).toLowerCase()} */}
                                  {row?.repeatType === "no"
                                    ? t("only_once")
                                    : row?.repeatType === "daily"
                                    ? t("every_day")
                                    : row?.repeatType === "weekly"
                                    ? t("every_week")
                                    : row?.repeatType === "monthly"
                                    ? t("every_month")
                                    : row?.repeatType === "yearly"
                                    ? t("every_year")
                                    : t("custom_text")}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    color:
                                      row?.status === "done"
                                        ? "#06BDFF"
                                        : row?.status === "failed"
                                        ? "#FC3652"
                                        : row?.status === "pending" ||
                                          row?.status === "repeat"
                                        ? "#13CF8F"
                                        : "#F86F35",
                                    width: "150px",
                                  }}
                                >
                                  <FiberManualRecordIcon
                                    sx={{
                                      color:
                                        row?.status === "done"
                                          ? "#06BDFF"
                                          : row?.status === "failed"
                                          ? "#FC3652"
                                          : row?.status === "pending" ||
                                            row?.status === "repeat"
                                          ? "#13CF8F"
                                          : "#F86F35",
                                      width: "10px",
                                      paddingRight: "3px",
                                      marginBottom: "-7px",
                                    }}
                                  />

                                  {row?.status === "paused"
                                    ? t("Paused")
                                    : row?.status === "pending" ||
                                      row?.status === "repeat"
                                    ? t("Active")
                                    : row?.status === "done"
                                    ? t("Completed")
                                    : row?.status.charAt(0).toUpperCase() +
                                      row?.status.slice(1).toLowerCase()}
                                  {/*   {t("deletion_pending")} */}
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  <>
                                    <IconButton
                                      onClick={(e) =>
                                        handleClick(e, row?.id, row)
                                      }
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>

                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={handleClose}
                                    >
                                      {selectedRow?.status === "paused" &&
                                        selectedRow?.status !== "failed" &&
                                        (editPermission && viewPermission ? (
                                          <MenuItem
                                            onClick={() => {
                                              handleOpenUpdateStatusModel(
                                                selectedRow,
                                                false
                                              );
                                              closeMenu();
                                              handleClose();
                                              // setDeleteId(selectedRow?.id);
                                            }}
                                          >
                                            <Typography>
                                              {t("resume")}
                                            </Typography>
                                          </MenuItem>
                                        ) : (
                                          <BootstrapTooltip
                                            title={t("authorized_access")}
                                          >
                                            <span>
                                              <MenuItem disabled>
                                                <Typography>
                                                  {t("resume")}
                                                </Typography>
                                              </MenuItem>
                                            </span>
                                          </BootstrapTooltip>
                                        ))}
                                      {selectedRow?.status === "paused" &&
                                        selectedRow?.status !== "failed" && (
                                          <Divider />
                                        )}
                                      {selectedRow?.status !== "paused" &&
                                        selectedRow?.status !== "failed" &&
                                        (selectedRow?.status === "pending" ||
                                          selectedRow?.status === "repeat") &&
                                        (editPermission && viewPermission ? (
                                          <MenuItem
                                            onClick={() => {
                                              handleOpenUpdateStatusModel(
                                                selectedRow,
                                                true
                                              );
                                              closeMenu();
                                              handleClose();
                                              // setDeleteId(selectedRow?.id);
                                            }}
                                          >
                                            <Typography>
                                              {t("Pause")}
                                            </Typography>
                                          </MenuItem>
                                        ) : (
                                          <BootstrapTooltip
                                            title={t("authorized_access")}
                                          >
                                            <span>
                                              <MenuItem disabled>
                                                <Typography>
                                                  {t("Pause")}
                                                </Typography>
                                              </MenuItem>
                                            </span>
                                          </BootstrapTooltip>
                                        ))}
                                      {selectedRow?.status !== "paused" &&
                                        selectedRow?.status !== "failed" &&
                                        (selectedRow?.status === "pending" ||
                                          selectedRow?.status === "repeat") && (
                                          <Divider />
                                        )}
                                      {selectedRow?.status !== "done" &&
                                        (editPermission && viewPermission ? (
                                          <MenuItem
                                            onClick={() => {
                                              handleOpenCreateScheduleModal(
                                                selectedRow?.id
                                              );
                                              closeMenu();
                                              handleClose();
                                              // setDeleteId(selectedRow?.id);
                                            }}
                                          >
                                            <Typography>{t("Edit")}</Typography>
                                          </MenuItem>
                                        ) : (
                                          <BootstrapTooltip
                                            title={t("authorized_access")}
                                          >
                                            <span>
                                              <MenuItem disabled>
                                                <Typography>
                                                  {t("Edit")}
                                                </Typography>
                                              </MenuItem>
                                            </span>
                                          </BootstrapTooltip>
                                        ))}
                                      {selectedRow?.status !== "done" && (
                                        <Divider variant="middle" />
                                      )}
                                      {deletePermission && viewPermission ? (
                                        <MenuItem
                                          onClick={() => {
                                            closeMenu();
                                            handleClose();
                                            handleOpenDeleteScheduledReportModel(
                                              selectedRow?.id
                                            );
                                            // handleOpenModal(row?.id);
                                          }}
                                        >
                                          <Typography>{t("Delete")}</Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <MenuItem
                                              disabled
                                              onClick={() => {
                                                closeMenu();
                                                handleClose();
                                                handleOpenDeleteScheduledReportModel(
                                                  selectedRow?.id
                                                );
                                                // handleOpenModal(row?.id);
                                              }}
                                            >
                                              <Typography>
                                                {t("Delete")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      )}
                                    </Menu>
                                  </>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow sx={{ fontWeight: "700px" }} tabIndex={-1}>
                            {t("No_result_found")}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                  <Grid container>
                    <Grid item sm={4} md={4} lg={4}></Grid>
                    <Grid item sm={8} md={8} lg={8}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={scheduledReports?.count ?? 0}
                        rowsPerPage={rowsPerPageScheduled}
                        page={scheduledRportsPageNumber ?? 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : scheduledReports === null ? (
              <Grid
                container
                sx={{
                  height: "320px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Grid item sm={8} md={8} lg={8}>
                  <Typography
                    sx={{
                      lineHeight: "28px",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#1b2430",
                      marginTop: "30px",
                      marginBottom: "15px",
                    }}
                  >
                    {t("No_Schedules_Found")}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  height: "320px",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Grid item sm={8} md={8} lg={8}>
                  <Typography
                    sx={{
                      lineHeight: "28px",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#1b2430",
                      marginTop: "30px",
                      marginBottom: "15px",
                    }}
                  >
                    {t("No_Schedules_Found")}
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: "24px",
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#1B2430",

                      marginBottom: "15px",
                    }}
                  >
                    {t("no_schedule_found_description")}
                  </Typography>

                  {addPermission && viewPermission ? (
                    <CommonButton
                      onSubmit={() => handleOpenCreateScheduleModal(null)}
                      customHeight={true}
                      label={t("schedule_create")}
                      icon={<AddIcon sx={{ color: "#FFFF" }} />}
                    />
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <CommonButton
                          disabled={true}
                          onSubmit={() => handleOpenCreateScheduleModal(null)}
                          customHeight={true}
                          label={t("schedule_create")}
                          icon={<AddIcon sx={{ color: "#FFFF" }} />}
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
              marginRight: "7px",
            }}
          >
            <CommonButton
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
              label={t("Cancel")}
            />

            <CommonButton
              //  isLoading={loading}
              type="submit"
              label={t("Ok")}
              onSubmit={handleCloseNewRequestModel}
            />
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
