import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Tooltip,
  tooltipClasses,
  Box,
  TextField,
  Alert,
  CircularProgress,
  MenuItem,
  Checkbox,
  IconButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Slider from "@mui/material/Slider";
import "./model.css";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { languagesData } from "../../../../assets/defaultLanguage";
import { api } from "../../../../contexts/JWTContext";
import Collapsible from "../../../../components/CustomComponents/Collapsible";
import TestPromptModel from "./TestPromptModel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SliderComponent from "./Slider";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  checkboxStyle: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    alignItems: "center",
    width: "100%",
    height: "72px",
  },
  gridStyle: {
    paddingBottom: "8px",
  },
  mainHeadingContainer: {
    //  paddingTop: "10px",
    //  paddingLeft: "20px",
    // paddingRight: "20px",
    //  paddingBottom: "20px",
    width: "100%",
  },
  mainKeyWordContainer: {
    paddingTop: "8px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  keywordLabelText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  tickAddressIcon: {
    color: "#06BDFF",
    cursor: "pointer",
    marginTop: "8px",
  },
  tickAddressIconRequested: {
    color: "#13CF8F",
    cursor: "pointer",
    marginTop: "8px",
  },
  typoStyle: {
    marginTop: "5px",
  },
  lengthTypo: {
    fontWeight: 600,
    marginTop: "3px",
  },
}));

const ReviewTextPresent = ({
  textPresentPrompt,

  formalChecked,
  setFormalChecked,
  setFormalCheckedTwo,
  reviewToneChecked,
  setReviewToneChecked,
  setReviewToneCheckedTwo,
  friendlyChecked,
  setFriendlyChecked,
  setFriendlyCheckedTwo,
  includeChecked,
  setIncludeChecked,
  setIncludeCheckedTwo,
  excludeChecked,
  setExcludeChecked,
  setExcludeCheckedTwo,
  yesChecked,
  setYesChecked,
  setYesCheckedTwo,
  noChecked,
  setNoChecked,
  setNoCheckedTwo,
  moreLanguageChecked,
  setMoreLanguageChecked,
  setMoreLanguageCheckedTwo,
  userLanguageChecked,
  setUserLanguageChecked,
  setUserLanguageCheckedTwo,
  userLanguage,
  setUserLanguage,
  setUserLanguageTwo,
  reviewLanguageChecked,
  setReviewLanguageChecked,
  tags,
  setTags,
  setTagsNotPresent,
  tagsNotPresent,
  openTestModel,
  setTextPresentGeneratedContent,
  textPresentGeneratedContent,
  handleCloseTestModel,

  setDetailedChecked,
  setDetailedCheckedTwo,
  extraData,
  setExtraData,
  setExtraDataTwo,
  setTextPresentPrompt,
  setTextNotPresentPrompt,
  present,
  reviewsData,
  dummyReviewsData,
  allReviewSource,
  openDefaultModel,
  selectedTab,
  setDisplayToast,
  displayToast,
  selectedRating,
  modelPresentPrompt,
  setModelPresentPrompt,
  setModelNotPresentPrompt,
  modelNotPresentPrompt,
  userTranslationLanguage,
  setUserTranslationLanguage,
  setUserTranslationLanguageTwo,
  selectAllPresent,
  setSelectAllPresent,
  setSelectAllNotPresent,
  handleDisplayNames,
  getLocation,
  defaultLocation,
  singleChecked,
  setSingleChecked,
  setSingleCheckedTwo,
  multiChecked,
  setMultiChecked,
  setMultiCheckedTwo,
  tagsRequired,
  setTagsRequired,
  tagsRequiredTwo,
  tagsGridRef,
  langGridRef,
  languageRequired,
  setLanguageRequired,
  scrollStateLang,
  setScrollStateLang,
  scrollStateTags,
  setScrollStateTags,
  excludeNameChecked,
  setExcludeNameChecked,
  setExcludeNameCheckedTwo,
  firstNameChecked,
  setFirstNameChecked,
  setFirstNameCheckedTwo,
  fullNameChecked,
  setFullNameChecked,
  setFullNameCheckedTwo,
  defaultEmailchecked,
  setDefualtEmailChecked,
  setDefualtEmailCheckedTwo,
  customEmailchecked,
  setCustomEmailChecked,
  setCustomEmailCheckedTwo,
  emailValue,
  setEmailValue,
  setEmailValueTwo,
  setEmailValidation,
  emailValidation,
  signatureValue,
  setSignatureValue,
  setSignatureValueTwo,
  autoSelect,

  setCustomLengthValue,
  customLengthValue,
  setCustomLengthValueTwo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedPanels, setExpandedPanels] = useState(["responseTone"]);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [textToReplace, setTextToReplace] = useState(extraData);
  const [tagsToReplace, setTagsToReplace] = useState(tags);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const [displayExtraWarning, setDisplayExtraWarning] = useState(false);
  const [detectLanguageLoading, setDetectedLanguageLoading] = useState(false);
  const [textReplaced, setTextToReplaced] = useState(true);
  const [textToReplaceforPronoun, setTextToReplaceforPronoun] = useState("");
  const [limitTextToReplace, setLimitTextToReplace] = useState("");
  const filter = createFilterOptions();
  const [changeTags, setChangeTags] = useState(false);

  const lengthMarks = [
    {
      value: 1,
      label: t("very_short"),
    },
    {
      value: 2,
      label: t("short_text"),
    },
    {
      value: 3,
      label: t("moderate"),
    },
    {
      value: 4,
      label: t("long"),
    },
    {
      value: 5,
      label: t("very_long"),
    },
  ];
  function valuetext(value) {
    setCustomLengthValue(value.target.value);
    handleDebouncedChange(value.target.value, "length");

    if (autoSelect) {
      setCustomLengthValueTwo(value.target.value);
    }
    return `${value}`;
  }

  useEffect(() => {
    if (scrollStateTags) {
      //  setModelPresentPrompt(textPresentPrompt);
      scrollToGrid();
      if (expandedPanels?.includes("style") === false) {
        expandedPanels.push("style");
        setExpandedPanels([...expandedPanels]);
      }
    }
  }, [scrollStateTags]);
  useEffect(() => {
    if (scrollStateLang) {
      //  setModelPresentPrompt(textPresentPrompt);
      scrollToLangGrid();
      if (expandedPanels?.includes("langs") === false) {
        expandedPanels.push("langs");
        setExpandedPanels([...expandedPanels]);
      }
    }
  }, [scrollStateLang]);

  useEffect(() => {
    if (includeChecked === false && excludeChecked === false) {
      setTags([]);
      setTagsRequired(false);

      if (autoSelect) {
        setTagsNotPresent([]);
        setTagsRequired(false);
      }
    }
  }, [includeChecked, excludeChecked]);
  useEffect(() => {
    if (moreLanguageChecked === false) {
      setUserLanguage([]);
      setLanguageRequired(false);
    }
  }, [moreLanguageChecked]);

  useEffect(() => {
    if (
      tags?.length > 0 ||
      (tagsToReplace?.length > 0 && changeTags === true)
    ) {
      setTagsRequired(false);
      if (modelPresentPrompt?.includes("{{-keywords-}}")) {
        let newStr = modelPresentPrompt.replace(
          "{{-keywords-}}",
          tags?.toString()
        );

        setModelPresentPrompt(newStr.trim().replace(/\s+/g, " "));
        setTagsToReplace(tags);
      } else {
        if (tags?.length > 0) {
          let strToFind =
            tagsToReplace?.length > 0
              ? tagsToReplace?.toString()
              : tags?.toString();
          if (modelPresentPrompt?.includes(strToFind)) {
            let newStr = modelPresentPrompt.replace(
              strToFind,
              tags?.toString()
            );

            setModelPresentPrompt(newStr.trim().replace(/\s+/g, " "));
            setTagsToReplace(tags);
          }
        } else {
          let strToFind =
            tagsToReplace?.length > 0 ? tagsToReplace?.toString() : "";
          if (modelPresentPrompt?.includes("{{-keywords-}}") === false) {
            let newStr = modelPresentPrompt.replace(
              strToFind,
              "{{-keywords-}}"
            );
            setModelPresentPrompt(newStr.trim().replace(/\s+/g, " "));
            setTagsToReplace(tags);
          }
        }
      }
    }

    if (autoSelect) {
      if (
        tagsNotPresent?.length > 0 ||
        (tagsToReplace?.length > 0 && changeTags)
      ) {
        setTagsRequired(false);
        if (modelNotPresentPrompt?.includes("{{-keywords-}}")) {
          let newStr = modelNotPresentPrompt.replace(
            "{{-keywords-}}",
            tagsNotPresent?.toString()
          );

          setModelNotPresentPrompt(newStr.trim().replace(/\s+/g, " "));
          setTagsToReplace(tags);
        } else {
          if (tagsNotPresent?.length > 0) {
            let strToFind =
              tagsToReplace?.length > 0
                ? tagsToReplace?.toString()
                : tagsNotPresent?.toString();
            if (modelNotPresentPrompt?.includes(strToFind)) {
              let newStr = modelNotPresentPrompt.replace(
                strToFind,
                tagsNotPresent?.toString()
              );

              setModelNotPresentPrompt(newStr.trim().replace(/\s+/g, " "));
              setTagsToReplace(tags);
            }
          } else {
            let strToFind =
              tagsToReplace?.length > 0 ? tagsToReplace?.toString() : "";
            if (modelNotPresentPrompt?.includes("{{-keywords-}}") === false) {
              let newStr = modelNotPresentPrompt.replace(
                strToFind,
                "{{-keywords-}}"
              );
              setModelNotPresentPrompt(newStr.trim().replace(/\s+/g, " "));
              setTagsToReplace(tags);
            }
          }
        }
      }
    }
  }, [tags, tagsNotPresent]);

  console.log(
    {
      modelPresentPrompt: modelPresentPrompt,
      modelNotPresentPrompt: modelNotPresentPrompt,
    },
    "modelNotPresentPrompt"
  );

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  const handleChangeFormalChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewTone-}}",
          "{{-formalTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-friendlyTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-friendlyTone-}}",
          "{{-formalTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
      setFormalChecked(e.target.checked);
      setReviewToneChecked(false);
      setFriendlyChecked(false);
    }
    if (autoSelect) {
      if (e.target.checked) {
        if (modelNotPresentPrompt?.includes("{{-reviewTone-}}")) {
          let str = modelNotPresentPrompt.replace(
            "{{-reviewTone-}}",
            "{{-formalTone-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else if (modelNotPresentPrompt?.includes("{{-friendlyTone-}}")) {
          let str = modelNotPresentPrompt.replaceAll(
            "{{-friendlyTone-}}",
            "{{-formalTone-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
        setFormalCheckedTwo(e.target.checked);
        setReviewToneCheckedTwo(false);
        setFriendlyCheckedTwo(false);
      }
    }
  };
  const handleChangeReviewToneChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-formalTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-formalTone-}}",
          "{{-reviewTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-friendlyTone-}}")) {
        let str = modelPresentPrompt.replaceAll(
          "{{-friendlyTone-}}",
          "{{-reviewTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
      setReviewToneChecked(e.target.checked);
      setFriendlyChecked(false);
      setFormalChecked(false);
    }
    if (autoSelect) {
      if (e.target.checked && selectedTab === 0) {
        if (modelNotPresentPrompt?.includes("{{-formalTone-}}")) {
          let str = modelNotPresentPrompt.replaceAll(
            "{{-formalTone-}}",
            "{{-friendlyTone-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } /* else if (modelPresentPrompt?.includes("{{-friendlyTone-}}")) {
          let str = modelPresentPrompt.replaceAll(
            "{{-friendlyTone-}}",
            "{{-reviewTone-}}"
          );
          setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        } */
        //  setReviewToneChecked(e.target.checked);
        setFriendlyCheckedTwo(true);
        setReviewToneCheckedTwo(e.target.checked);
        setFormalCheckedTwo(false);
      }
    }
  };
  const handleChangeFriendlyChecked = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewTone-}}",
          "{{-friendlyTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-formalTone-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-formalTone-}}",
          "{{-friendlyTone-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
      setFriendlyChecked(e.target.checked);
      setReviewToneChecked(false);
      setFormalChecked(false);
    }
    if (autoSelect) {
      if (e.target.checked) {
        if (modelNotPresentPrompt?.includes("{{-reviewTone-}}")) {
          let str = modelNotPresentPrompt.replace(
            "{{-reviewTone-}}",
            "{{-friendlyTone-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else if (modelNotPresentPrompt?.includes("{{-formalTone-}}")) {
          let str = modelNotPresentPrompt.replace(
            "{{-formalTone-}}",
            "{{-friendlyTone-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
        setFriendlyCheckedTwo(e.target.checked);
        setReviewToneCheckedTwo(false);
        setFormalCheckedTwo(false);
      }
    }
  };
  const handleIncludeChecked = async (e) => {
    setChangeTags(false);
    setTags([]);
    setIncludeChecked(e.target.checked);
    setExcludeChecked(false);

    let str = modelPresentPrompt;
    let splittedStr = str.split(".");

    if (e.target.checked) {
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("{{-excludePhrase-}}")
      );
      if (findIndex > -1) {
        splittedStr[findIndex] =
          "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
        let joinedString = splittedStr.join(".");
        setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
        setLimitTextToReplace(
          "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
        );
      } else {
        if (str?.includes("{{-includePhrase-}}") === false) {
          str =
            str +
            " " +
            "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
          let splittedStrTwo = str.split(".");

          let filteredStr = splittedStrTwo?.filter(
            (text) => text.includes("{{-excludePhrase-}}") === false
          );
          let joinedString = filteredStr.join(".");
          setModelPresentPrompt(joinedString);
          setLimitTextToReplace(
            "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
          );
        }
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) =>
          text.includes(
            "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like"
          ) === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
    }

    if (autoSelect) {
      setTagsNotPresent([]);
      setIncludeCheckedTwo(e.target.checked);
      setExcludeCheckedTwo(false);
      let str = modelNotPresentPrompt;
      let splittedStr = str.split(".");

      if (e.target.checked) {
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("{{-excludePhrase-}}")
        );
        if (findIndex > -1) {
          splittedStr[findIndex] =
            "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
          let joinedString = splittedStr.join(".");
          setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
          setLimitTextToReplace(
            "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
          );
        } else {
          if (str?.includes("{{-includePhrase-}}") === false) {
            str =
              str +
              " " +
              "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
            let splittedStrTwo = str.split(".");

            let filteredStr = splittedStrTwo?.filter(
              (text) => text.includes("{{-excludePhrase-}}") === false
            );
            let joinedString = filteredStr.join(".");
            setModelNotPresentPrompt(joinedString);
            setLimitTextToReplace(
              "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
            );
          }
        }
      } else {
        let splittedStr = str.split(".");
        let filteredStr = splittedStr?.filter(
          (text) =>
            text.includes(
              "For {{-ratingStars-}} reviews, {{-includePhrase-}} phrases like"
            ) === false
        );
        let joinedString = filteredStr.join(".");
        setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      }
    }
  };

  const handleExludeChecked = async (e) => {
    setChangeTags(false);
    setTags([]);
    setExcludeChecked(e.target.checked);
    setIncludeChecked(false);

    let str = modelPresentPrompt;

    if (e.target.checked) {
      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("{{-includePhrase-}}")
      );
      if (findIndex > -1) {
        splittedStr[findIndex] =
          "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
        let joinedString = splittedStr.join(".");
        setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
        setTextToReplaceforPronoun(
          "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
        );
      } else {
        if (str?.includes("{{-excludePhrase-}}") === false) {
          str =
            str +
            " " +
            "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
          let splittedStrTwo = str.split(".");
          let filteredStr = splittedStrTwo?.filter(
            (text) => text.includes("{{-includePhrase-}}") === false
          );
          let joinedString = filteredStr.join(".");
          setModelPresentPrompt(joinedString);
          setLimitTextToReplace(
            "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
          );
        }
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-excludePhrase-}}") === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
    }

    if (autoSelect) {
      setTagsNotPresent([]);
      setExcludeCheckedTwo(e.target.checked);
      setIncludeCheckedTwo(false);

      let str = modelNotPresentPrompt;

      if (e.target.checked) {
        let splittedStr = str.split(".");
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("{{-includePhrase-}}")
        );
        if (findIndex > -1) {
          splittedStr[findIndex] =
            "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
          let joinedString = splittedStr.join(".");
          setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
          setTextToReplaceforPronoun(
            "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
          );
        } else {
          if (str?.includes("{{-excludePhrase-}}") === false) {
            str =
              str +
              " " +
              "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases.";
            let splittedStrTwo = str.split(".");
            let filteredStr = splittedStrTwo?.filter(
              (text) => text.includes("{{-includePhrase-}}") === false
            );
            let joinedString = filteredStr.join(".");
            setModelNotPresentPrompt(joinedString);
            setLimitTextToReplace(
              "For {{-ratingStars-}} reviews, {{-excludePhrase-}} phrases like {{-keywords-}} to express appreciation without repeating the same phrases."
            );
          }
        }
      } else {
        let splittedStr = str.split(".");
        let filteredStr = splittedStr?.filter(
          (text) => text.includes("{{-excludePhrase-}}") === false
        );
        let joinedString = filteredStr.join(".");
        setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      }
    }
  };

  const handleFirstNameChecked = async (e) => {
    if (e.target.checked) {
      setFullNameChecked(false);
      setFirstNameChecked(e.target.checked);
      setExcludeNameChecked(false);
      if (modelPresentPrompt?.includes("name {{-client-}}")) {
        let str = modelPresentPrompt.replace(
          "name {{-client-}}",
          "name {{-customerName-}}"
        );

        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else {
        let str = modelPresentPrompt.trim().replace(/\s+/g, " ");

        str = str.replace(
          "A customer has left a review",
          "A customer name {{-customerName-}} has left a review"
        );
        if (
          str?.includes("provide a polite and constructive response") &&
          str.includes("Please address the customer by their name") === false
        ) {
          str = str.replace(
            "provide a polite and constructive response",
            "address the customer by their name and provide a polite and constructive response"
          );
        }
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        setFullNameCheckedTwo(false);
        setFirstNameCheckedTwo(e.target.checked);
        setExcludeNameCheckedTwo(false);
        if (modelNotPresentPrompt?.includes("name {{-client-}}")) {
          let str = modelNotPresentPrompt.replace(
            "name {{-client-}}",
            "name {{-customerName-}}"
          );

          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else {
          let str = modelNotPresentPrompt.trim().replace(/\s+/g, " ");

          str = str.replace(
            "A customer has left a review",
            "A customer name {{-customerName-}} has left a review"
          );
          if (
            str?.includes("provide a polite and constructive response") &&
            str.includes("Please address the customer by their name") === false
          ) {
            str = str.replace(
              "provide a polite and constructive response",
              "address the customer by their name and provide a polite and constructive response"
            );
          }
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };
  const handleFullNameChecked = async (e) => {
    if (e.target.checked) {
      setFullNameChecked(e.target.checked);
      setFirstNameChecked(false);
      setExcludeNameChecked(false);
      if (modelPresentPrompt?.includes("name {{-customerName-}}")) {
        let str = modelPresentPrompt.replace(
          "name {{-customerName-}}",
          "name {{-client-}} "
        );

        if (
          str?.includes("provide a polite and constructive response") &&
          str?.includes("Please address the customer by their name") === false
        ) {
          str = str.replace(
            "provide a polite and constructive response",
            "Please address the customer by their name and provide a polite and constructive response"
          );
        }
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else {
        let str = modelPresentPrompt.trim().replace(/\s+/g, " ");
        str = str.replace(
          "A customer has left a review",
          "A customer name {{-client-}} has left a review"
        );
        if (
          str?.includes("provide a polite and constructive response") &&
          str?.includes("Please address the customer by their name") === false
        ) {
          str = str.replace(
            "provide a polite and constructive response",
            "address the customer by their name and provide a polite and constructive response"
          );
        }
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        setFullNameCheckedTwo(e.target.checked);
        setFirstNameCheckedTwo(false);
        setExcludeNameCheckedTwo(false);
        if (modelNotPresentPrompt?.includes("name {{-customerName-}}")) {
          let str = modelNotPresentPrompt.replace(
            "name {{-customerName-}}",
            "name {{-client-}} "
          );

          if (
            str?.includes("provide a polite and constructive response") &&
            str?.includes("Please address the customer by their name") === false
          ) {
            str = str.replace(
              "provide a polite and constructive response",
              "Please address the customer by their name and provide a polite and constructive response"
            );
          }
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else {
          let str = modelNotPresentPrompt.trim().replace(/\s+/g, " ");
          str = str.replace(
            "A customer has left a review",
            "A customer name {{-client-}} has left a review"
          );
          if (
            str?.includes("provide a polite and constructive response") &&
            str?.includes("Please address the customer by their name") === false
          ) {
            str = str.replace(
              "provide a polite and constructive response",
              "address the customer by their name and provide a polite and constructive response"
            );
          }
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };

  const handleExcludeNameChecked = async (e) => {
    if (e.target.checked) {
      setExcludeNameChecked(e.target.checked);

      setFirstNameChecked(false);
      setFullNameChecked(false);
      if (modelPresentPrompt?.includes("name {{-customerName-}}")) {
        let str = modelPresentPrompt.replace("name {{-customerName-}}", " ");
        if (str.includes("Please address the customer by their name and")) {
          str = str.replace(
            "Please address the customer by their name and",
            "Please"
          );
        }
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("name {{-client-}}")) {
        let str = modelPresentPrompt.replaceAll("name {{-client-}}", " ");
        if (str.includes("Please address the customer by their name and")) {
          str = str.replace(
            "Please address the customer by their name and",
            "Please"
          );
        }
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        setExcludeNameCheckedTwo(e.target.checked);

        setFirstNameCheckedTwo(false);
        setFullNameCheckedTwo(false);
        if (modelNotPresentPrompt?.includes("name {{-customerName-}}")) {
          let str = modelNotPresentPrompt.replace(
            "name {{-customerName-}}",
            " "
          );
          if (str.includes("Please address the customer by their name and")) {
            str = str.replace(
              "Please address the customer by their name and",
              "Please"
            );
          }
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else if (modelNotPresentPrompt?.includes("name {{-client-}}")) {
          let str = modelNotPresentPrompt.replaceAll("name {{-client-}}", " ");
          if (str.includes("Please address the customer by their name and")) {
            str = str.replace(
              "Please address the customer by their name and",
              "Please"
            );
          }
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    //  setTagsRequired(true);
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";

    // setTagsRequired(false);
    if (autoSelect) {
      setTagsNotPresent([...tagsNotPresent, value]);
    }
    setChangeTags(true);
  };
  const removeTag = (index) => {
    setChangeTags(true);
    setTags(tags.filter((el, i) => i !== index));
    if (autoSelect) {
      setTagsNotPresent(tagsNotPresent.filter((el, i) => i !== index));
    }
  };
  const handleChangeYesChecked = (e) => {
    if (e.target.checked) {
      setYesChecked(e.target.checked);
      setNoChecked(false);

      if (modelPresentPrompt.includes("{{-withOut-}} any signatures.")) {
        let str = modelPresentPrompt?.replace(
          "{{-withOut-}} any signatures.",
          "{{-includeSignature-}}."
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        setYesCheckedTwo(e.target.checked);
        setNoCheckedTwo(false);

        if (modelNotPresentPrompt.includes("{{-withOut-}} any signatures.")) {
          let str = modelNotPresentPrompt?.replace(
            "{{-withOut-}} any signatures.",
            "{{-includeSignature-}}."
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };
  const handleNoChecked = (e) => {
    if (e.target.checked) {
      setNoChecked(e.target.checked);
      setYesChecked(false);
      setSignatureValue(null);
      if (modelPresentPrompt?.includes("{{-includeSignature-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-includeSignature-}}",
          "{{-withOut-}} any signatures."
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt.includes("{{-includeSignature-}}")) {
        let str = modelPresentPrompt?.replace(
          "{{-includeSignature-}}",
          `{{-withOut-}} any signatures.`
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        setNoCheckedTwo(e.target.checked);
        setYesCheckedTwo(false);
        setSignatureValueTwo(null);
        if (modelNotPresentPrompt?.includes("{{-includeSignature-}}")) {
          let str = modelNotPresentPrompt.replace(
            "{{-includeSignature-}}",
            "{{-withOut-}} any signatures."
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else if (modelNotPresentPrompt.includes("{{-includeSignature-}}")) {
          let str = modelNotPresentPrompt?.replace(
            "{{-includeSignature-}}",
            `{{-withOut-}} any signatures.`
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };

  const handleSingleChecked = async (e) => {
    setSingleChecked(e.target.checked);
    setMultiChecked(false);
    let str = modelPresentPrompt;
    if (e.target.checked) {
      if (
        modelPresentPrompt?.includes(
          "Use the pronoun  {{-SingularPronoun-}} to address customer"
        ) === false &&
        modelPresentPrompt?.includes(
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        ) === false
      ) {
        str =
          str +
          " " +
          "Use the pronoun {{-SingularPronoun-}} to address customer.";
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        setTextToReplaceforPronoun(
          "Use the pronoun {{-SingularPronoun-}} to address customer."
        );
      } else if (
        modelPresentPrompt?.includes(
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        )
      ) {
        str = str.replace(
          "Use the pronoun {{-PluralPronoun-}} to address customer.",
          "Use the pronoun {{-SingularPronoun-}} to address customer."
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        setTextToReplaceforPronoun(
          "Use the pronoun {{-SingularPronoun-}} to address customer."
        );
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-SingularPronoun-}}") === false
      );
      let joinedString = filteredStr.join(".");
      setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
    }
    if (autoSelect) {
      setSingleCheckedTwo(e.target.checked);
      setMultiCheckedTwo(false);
      let str = modelNotPresentPrompt;
      if (e.target.checked) {
        if (
          modelNotPresentPrompt?.includes(
            "Use the pronoun  {{-SingularPronoun-}} to address customer"
          ) === false &&
          modelNotPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          ) === false
        ) {
          str =
            str +
            " " +
            "Use the pronoun {{-SingularPronoun-}} to address customer.";
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
          setTextToReplaceforPronoun(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
        } else if (
          modelNotPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          )
        ) {
          str = str.replace(
            "Use the pronoun {{-PluralPronoun-}} to address customer.",
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
          setTextToReplaceforPronoun(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          );
        }
      } else {
        let splittedStr = str.split(".");
        let filteredStr = splittedStr?.filter(
          (text) => text.includes("{{-SingularPronoun-}}") === false
        );
        let joinedString = filteredStr.join(".");
        setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      }
    }
  };

  const handleMultiChecked = async (e) => {
    setMultiChecked(e.target.checked);
    setSingleChecked(false);
    let str = modelPresentPrompt;
    if (e.target.checked) {
      if (
        modelPresentPrompt?.includes(
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        ) === false &&
        modelPresentPrompt?.includes(
          "Use the pronoun {{-SingularPronoun-}} to address customer."
        ) === false
      ) {
        str =
          str +
          " " +
          "Use the pronoun {{-PluralPronoun-}} to address customer.";
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        setTextToReplaceforPronoun(
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        );
      } else if (
        modelPresentPrompt?.includes(
          "Use the pronoun {{-SingularPronoun-}} to address customer."
        )
      ) {
        str = str.replace(
          "Use the pronoun {{-SingularPronoun-}} to address customer.",
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        setTextToReplaceforPronoun(
          "Use the pronoun {{-PluralPronoun-}} to address customer."
        );
      }
    } else {
      let splittedStr = str.split(".");
      let filteredStr = splittedStr?.filter(
        (text) => text.includes("{{-PluralPronoun-}}") === false
      );
      let joinedString = filteredStr.join(".");

      setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
    }
    if (autoSelect) {
      setMultiCheckedTwo(e.target.checked);
      setSingleCheckedTwo(false);
      let str = modelNotPresentPrompt;
      if (e.target.checked) {
        if (
          modelNotPresentPrompt?.includes(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          ) === false &&
          modelNotPresentPrompt?.includes(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          ) === false
        ) {
          str =
            str +
            " " +
            "Use the pronoun {{-PluralPronoun-}} to address customer.";
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
          setTextToReplaceforPronoun(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
        } else if (
          modelPresentPrompt?.includes(
            "Use the pronoun {{-SingularPronoun-}} to address customer."
          )
        ) {
          str = str.replace(
            "Use the pronoun {{-SingularPronoun-}} to address customer.",
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
          setTextToReplaceforPronoun(
            "Use the pronoun {{-PluralPronoun-}} to address customer."
          );
        }
      } else {
        let splittedStr = str.split(".");
        let filteredStr = splittedStr?.filter(
          (text) => text.includes("{{-PluralPronoun-}}") === false
        );
        let joinedString = filteredStr.join(".");

        setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      }
    }
  };

  const handleChangeMoreLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-localLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-localLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-reviewer'sLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewer'sLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
      setMoreLanguageChecked(e.target.checked);
      setReviewLanguageChecked(false);
      setUserLanguageChecked(false);
      setUserLanguage([]);
    }
  };
  const handleChangeReviewLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-localLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-localLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-reviewer'sLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else {
        languagesData.forEach((language) => {
          if (modelPresentPrompt?.includes(language?.language)) {
            let str = modelPresentPrompt.replace(
              language?.language,
              "{{-reviewer'sLanguage-}}"
            );
            setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
          }
        });
      }
      setMoreLanguageChecked(false);
      setReviewLanguageChecked(e.target.checked);
      setUserLanguageChecked(false);
      setUserLanguage([]);
    }
  };

  const debounceTimeout = useRef(null);

  const handleDebouncedChange = (value, key) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      if (key === "extra") {
        handleChangeExtraData(value);
      } /*  else if (key === "length") {
        let numberValue = numberToWords(value);
        handleChangeCustomLengthData(numberValue);
      } */
    }, 500);
  };

  const handleChangeCustomLengthData = (value) => {
    if (value.length > 0) {
      let str = modelPresentPrompt;
      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("Limit the response")
      );
      if (findIndex > -1) {
        splittedStr[findIndex] = `Limit the response to ${value} sentences`;
        let joinedString = splittedStr.join(".");
        setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      } else {
        str = str + " " + `Limit the response to ${value} sentences`;
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (value.length > 0) {
        let str = modelNotPresentPrompt;
        let splittedStr = str.split(".");
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("Limit the response")
        );
        if (findIndex > -1) {
          splittedStr[findIndex] = `Limit the response to ${value} sentences.`;
          let joinedString = splittedStr.join(".");
          setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
        } else {
          str = str + " " + `Limit the response to ${value} sentences.`;
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };

  const handleChangeExtraData = (value) => {
    if (value.length > 0) {
      if (modelPresentPrompt?.includes("{{-selectedExtras-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedExtras-}}",
          "Please consider this note in response:" + " " + value
        );
        setModelPresentPrompt(str);
        setTextToReplaced(false);
      } else if (
        modelPresentPrompt?.includes(
          "Please consider this note in response:" + " " + textToReplace
        )
      ) {
        let str = modelPresentPrompt.replace(
          "Please consider this note in response:" + " " + textToReplace,
          "Please consider this note in response:" + " " + value
        );
        setModelPresentPrompt(str);
        setTextToReplaced(false);
      }
    } else {
      if (
        modelPresentPrompt?.includes(
          "Please consider this note in response:" + " " + textToReplace
        )
      ) {
        let str = modelPresentPrompt.replace(
          "Please consider this note in response:" + " " + textToReplace,
          "{{-selectedExtras-}}"
        );
        setModelPresentPrompt(str);
      }
    }
    if (autoSelect) {
      if (value.length > 0) {
        if (modelNotPresentPrompt?.includes("{{-selectedExtras-}}")) {
          let str = modelNotPresentPrompt.replace(
            "{{-selectedExtras-}}",
            "Please consider this note in response:" + " " + value
          );
          setModelNotPresentPrompt(str);
          setTextToReplaced(false);
        } else if (
          modelNotPresentPrompt?.includes(
            "Please consider this note in response:" + " " + textToReplace
          )
        ) {
          let str = modelNotPresentPrompt.replace(
            "Please consider this note in response:" + " " + textToReplace,
            "Please consider this note in response:" + " " + value
          );
          setModelNotPresentPrompt(str);
          setTextToReplaced(false);
        }
      } else {
        if (
          modelNotPresentPrompt?.includes(
            "Please consider this note in response:" + " " + textToReplace
          )
        ) {
          let str = modelNotPresentPrompt.replace(
            "Please consider this note in response:" + " " + textToReplace,
            "{{-selectedExtras-}}"
          );
          setModelNotPresentPrompt(str);
        }
      }
    }

    setTextToReplace(value);
    // setTextToReplaced(true);
  };

  useEffect(() => {
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);

  const handleChangeUserLanguage = (e) => {
    if (e.target.checked) {
      if (modelPresentPrompt?.includes("{{-reviewer'sLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-reviewer'sLanguage-}}",
          // userData?.language
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-localLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else {
        languagesData.forEach((language) => {
          if (modelPresentPrompt?.includes(language?.language)) {
            let str = modelPresentPrompt.replace(
              language?.language,
              "{{-localLanguage-}}"
            );
            setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
          }
        });
      }
      setMoreLanguageChecked(false);
      setReviewLanguageChecked(false);
      setUserLanguageChecked(e.target.checked);
    }
  };
  const handleLanguageChange = async (lang) => {
    if (lang?.length > 0) {
      let userSelectedLang = lang?.map((item) => item?.language);
      if (modelPresentPrompt?.includes("{{-selectedLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-selectedLanguage-}}",
          userSelectedLang?.length > 1
            ? "{{-MultipleLanguage-}}"
            : userSelectedLang?.toString()
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          userSelectedLang?.length > 1
            ? "{{-MultipleLanguage-}}"
            : userSelectedLang?.toString()
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else {
        let langString = [];
        let userSelectedLangs = [];
        languagesData.forEach((language) => {
          userLanguage?.forEach((item) => {
            if (item?.language === language?.language) {
              userSelectedLangs?.push(language);
            }
          });
        });
        langString = userSelectedLangs?.map((item) => item?.language);

        if (modelPresentPrompt?.includes(langString?.toString())) {
          let str = modelPresentPrompt.replace(
            langString?.toString(),
            userSelectedLang?.length > 1
              ? "{{-MultipleLanguage-}}"
              : userSelectedLang?.toString()
          );
          setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    } else {
      let langString = [];
      let userSelectedLangs = [];
      languagesData.forEach((language) => {
        userLanguage?.forEach((item) => {
          if (item?.language === language?.language) {
            userSelectedLangs?.push(language);
          }
        });
      });
      langString = userSelectedLangs?.map((item) => item?.language);

      if (modelPresentPrompt?.includes(langString?.toString())) {
        let str = modelPresentPrompt.replace(
          langString?.toString(),
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-MultipleLanguage-}}")) {
        let str = modelPresentPrompt.replace(
          "{{-MultipleLanguage-}}",
          "{{-selectedLanguage-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }

    setUserLanguage(lang);
  };
  /*   const handleShortChecked = async (e) => {
    if (e.target.checked) {
      let str = modelPresentPrompt;
      if (modelPresentPrompt?.includes("{{-detailedResponse-}}")) {
        str = modelPresentPrompt.replace(
          "{{-detailedResponse-}}",
          "{{-shortResponse-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      } else if (modelPresentPrompt?.includes("{{-selectedLength-}}")) {
        str = modelPresentPrompt.replace(
          "{{-selectedLength-}}",
          "{{-shortResponse-}}"
        );
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
      setShortChecked(e.target.checked);
      setDetailedChecked(false);
      setCustomLength(false);
      setCustomLengthValue(null);

      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("Limit the response")
      );
      if (findIndex > -1) {
        splittedStr[findIndex] = "Limit the response to two sentences.";
        let joinedString = splittedStr.join(".");
        setModelPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
      } else {
        str = str + " " + "Limit the response to two sentences.";
        setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
    if (autoSelect) {
      if (e.target.checked) {
        let str = modelNotPresentPrompt;
        if (modelNotPresentPrompt?.includes("{{-detailedResponse-}}")) {
          str = modelNotPresentPrompt.replace(
            "{{-detailedResponse-}}",
            "{{-shortResponse-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        } else if (modelNotPresentPrompt?.includes("{{-selectedLength-}}")) {
          str = modelNotPresentPrompt.replace(
            "{{-selectedLength-}}",
            "{{-shortResponse-}}"
          );
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
        setShortCheckedTwo(e.target.checked);
        setDetailedCheckedTwo(false);
        setCustomLengthTwo(false);
        setCustomLengthValueTwo(null);

        let splittedStr = str.split(".");
        let findIndex = splittedStr.findIndex((text) =>
          text.includes("Limit the response")
        );
        if (findIndex > -1) {
          splittedStr[findIndex] = "Limit the response to two sentences.";
          let joinedString = splittedStr.join(".");
          setModelNotPresentPrompt(joinedString.trim().replace(/\s+/g, " "));
        } else {
          str = str + " " + "Limit the response to two sentences.";
          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  };
  const handleCustomLengthChecked = async (e) => {
    let str = modelPresentPrompt;
    if (e.target.checked) {
      setCustomLength(e.target.checked);
      setDetailedChecked(false);
      setShortChecked(false);
    }
    if (autoSelect) {
      if (e.target.checked) {
        setCustomLengthTwo(e.target.checked);
        setDetailedCheckedTwo(false);
        setShortCheckedTwo(false);
      }
    }
  };
  const handleDetailChecked = async (e) => {
    let str = modelPresentPrompt;
    if (e.target.checked) {
      setDetailedChecked(e.target.checked);
      setShortChecked(false);
      setCustomLength(false);
      setCustomLengthValue(null);

      if (modelPresentPrompt?.includes("{{-shortResponse-}}")) {
        str = modelPresentPrompt.replace(
          "{{-shortResponse-}}",
          "{{-detailedResponse-}}"
        );
        // setModelPresentPrompt(str);
      } else if (modelPresentPrompt?.includes("{{-selectedLength-}}")) {
        str = modelPresentPrompt.replace(
          "{{-selectedLength-}}",
          "{{-detailedResponse-}}"
        );
      }

      let splittedStr = str.split(".");
      let findIndex = splittedStr.findIndex((text) =>
        text.includes("Limit the response")
      );
      if (findIndex > -1) {
        splittedStr[findIndex] = "";
        str = splittedStr.join(".");
      }

      setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
      if (autoSelect) {
        let str = modelNotPresentPrompt;
        if (e.target.checked) {
          setDetailedCheckedTwo(e.target.checked);
          setShortCheckedTwo(false);
          setCustomLengthTwo(false);
          setCustomLengthValueTwo(null);

          if (modelNotPresentPrompt?.includes("{{-shortResponse-}}")) {
            str = modelNotPresentPrompt.replace(
              "{{-shortResponse-}}",
              "{{-detailedResponse-}}"
            );
            // setModelPresentPrompt(str);
          } else if (modelNotPresentPrompt?.includes("{{-selectedLength-}}")) {
            str = modelNotPresentPrompt.replace(
              "{{-selectedLength-}}",
              "{{-detailedResponse-}}"
            );
          }

          let splittedStr = str.split(".");
          let findIndex = splittedStr.findIndex((text) =>
            text.includes("Limit the response")
          );
          if (findIndex > -1) {
            splittedStr[findIndex] = "";
            str = splittedStr.join(".");
          }

          setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
        }
      }
    }
  }; */

  const handleDisplayLanguageName = (langKey) => {
    const selectedLanguage = languagesData?.find(
      (language) => language?.value === langKey
    );
    return selectedLanguage?.language;
  };

  const handleTranslateReviewReplyText = async () => {
    setDetectedLanguageLoading(true);

    try {
      const res = await api.post(`/translation/mutipleText`, {
        texts: [extraData],
        language: [userTranslationLanguage],
      });
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          if (modelPresentPrompt?.includes("{{-selectedExtras-}}")) {
            let str = modelPresentPrompt.replace(
              "{{-selectedExtras-}}",
              res?.data?.data[0]?.text
            );
            setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
            setTextToReplaced(false);
          } else if (modelPresentPrompt?.includes(textToReplace)) {
            let str = modelPresentPrompt.replace(
              textToReplace,
              res?.data?.data[0]?.text
            );
            setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
            setTextToReplaced(false);
          }
          setTextToReplace(res?.data?.data[0]?.text);
          setDisplayExtraWarning(false);
        }
        setDetectedLanguageLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setDetectedLanguageLoading(false);
    }
  };
  const scrollToGrid = () => {
    // Check if gridRef is not null
    if (tagsGridRef.current) {
      // Scroll to the grid element
      tagsGridRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setScrollStateTags(false);
      }, 500);
    }
  };
  const scrollToLangGrid = () => {
    // Check if gridRef is not null
    if (langGridRef.current) {
      // Scroll to the grid element
      langGridRef.current.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setScrollStateLang(false);
      }, 500);
    }
  };

  const handleDefaultEmailChecked = (e) => {
    if (e.target.checked) {
      setDefualtEmailChecked(e.target.checked);
      setCustomEmailChecked(false);
      setEmailValue(null);
      setEmailValidation(false);
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      let str = modelPresentPrompt;
      if (emailRegex.test(str)) {
        str = str.replace(emailRegex, "{{businessEmail}}");
      }

      setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
    }
    if (autoSelect) {
      if (e.target.checked) {
        setDefualtEmailCheckedTwo(e.target.checked);
        setCustomEmailCheckedTwo(false);
        setEmailValueTwo(null);
        setEmailValidation(false);
        const emailRegex =
          /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        let str = modelNotPresentPrompt;
        if (emailRegex.test(str)) {
          str = str.replace(emailRegex, "{{businessEmail}}");
        }

        setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
  };
  const handleCustomEmailChecked = (e) => {
    if (e.target.checked) {
      setDefualtEmailChecked(false);
      setCustomEmailChecked(e.target.checked);
    }
    if (autoSelect) {
      if (e.target.checked) {
        setDefualtEmailCheckedTwo(false);
        setCustomEmailCheckedTwo(e.target.checked);
      }
    }
  };
  const handleChangeEmailData = (value) => {
    if (value.length > 0 && !emailValidation) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
      let str = modelPresentPrompt;

      if (str?.includes("{{businessEmail}}")) {
        str = str.replace("{{businessEmail}}", value);
      } else if (emailRegex.test(str)) {
        str = str.replace(emailRegex, value);
      }

      setModelPresentPrompt(str.trim().replace(/\s+/g, " "));
    }
    if (autoSelect) {
      if (value.length > 0 && !emailValidation) {
        const emailRegex =
          /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        let str = modelNotPresentPrompt;

        if (str?.includes("{{businessEmail}}")) {
          str = str.replace("{{businessEmail}}", value);
        } else if (emailRegex.test(str)) {
          str = str.replace(emailRegex, value);
        }

        setModelNotPresentPrompt(str.trim().replace(/\s+/g, " "));
      }
    }
  };

  return (
    <>
      <Grid container className={classes.mainKeyWordContainer}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
          >
            <Collapsible
              title={t("responseTone")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("responseTone")}
              id={"responseTone"}
              fontAlignment={true}
              required={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={formalChecked}
                          onChange={handleChangeFormalChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {" "}
                          {t("formalText")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={friendlyChecked}
                          onChange={handleChangeFriendlyChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("Friendly")}
                        </Typography>
                      </Box>
                    </Grid>
                    {present && (
                      <Grid item xs={3} md={3} sm={3} lg={3}>
                        <Box className={classes.checkboxStyle}>
                          <Checkbox
                            sx={{ marginTop: "4px" }}
                            checked={reviewToneChecked}
                            onChange={handleChangeReviewToneChecked}
                            name="google"
                            color="primary"
                          />

                          <Typography className={classes.typoStyle}>
                            {t("reviewTone")}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
          >
            <Collapsible
              title={t("pronoun")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("pronoun")}
              id={"pronoun"}
              fontAlignment={true}
              displayInfoIcon={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={singleChecked}
                          onChange={handleSingleChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography>{t("individual_text")}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={multiChecked}
                          onChange={handleMultiChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("Company")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
            ref={tagsGridRef}
          >
            <Collapsible
              title={t("style")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("style")}
              id={"style"}
              fontAlignment={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={excludeChecked}
                          onChange={handleExludeChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("exclude")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={includeChecked}
                          onChange={handleIncludeChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("include")}
                        </Typography>
                      </Box>
                    </Grid>
                    {(includeChecked || excludeChecked) && (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography className={classes.keywordLabelText}>
                          {t("Keywords")}
                        </Typography>

                        <Grid
                          className={
                            tagsRequired
                              ? "tags-input-container-error"
                              : "tags-input-container"
                          }
                        >
                          {tags.map((tag, index) => (
                            <div className="tag-item" key={index}>
                              <span className="text">
                                {tag}
                                <span
                                  className="close"
                                  onClick={() => removeTag(index)}
                                >
                                  {" "}
                                  X
                                </span>
                              </span>
                            </div>
                          ))}

                          <input
                            type="text"
                            className="tags-input"
                            placeholder={
                              includeChecked
                                ? t("styleHelperTextForInclude")
                                : t("styleHelperText")
                            }
                            //   onKeyPress={this.keyPress}
                            onKeyDown={handleKeyDown}
                            // disabled={tagsRequired}
                            //  defaultValue={productData.tags}
                          />
                        </Grid>
                        <Typography
                          sx={{
                            padding: "4px",
                            color: "gray",
                            fontSize: "10px",
                          }}
                        >
                          {t("Press_Enter")}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
            ref={tagsGridRef}
          >
            <Collapsible
              title={t("promptText_CustomerName")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("CustomerName")}
              id={"CustomerName"}
              fontAlignment={true}
              required={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={fullNameChecked}
                          onChange={handleFullNameChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("full_name")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={firstNameChecked}
                          onChange={handleFirstNameChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("First_name")}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={excludeNameChecked}
                          onChange={handleExcludeNameChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("exclude")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
            ref={tagsGridRef}
          >
            <Collapsible
              title={t("Business_emailModel")}
              displayInfoIcon={true}
              infoText={t("businessEmailTooltip")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("businessEmail")}
              id={"businessEmail"}
              fontAlignment={true}
              required={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={defaultEmailchecked}
                          onChange={handleDefaultEmailChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("default")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={customEmailchecked}
                          onChange={handleCustomEmailChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("custom_text")}
                        </Typography>
                      </Box>
                    </Grid>

                    {customEmailchecked && (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography className={classes.keywordLabelText}>
                          {t("Email_Address_Buisness")}
                        </Typography>

                        <TextField
                          type="email"
                          name="businessEmail"
                          placeholder="e.g. john@obenan.com"
                          value={emailValue}
                          fullWidth
                          /*   onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleChangeEmailData(event.target.value);
                            }
                          }} */
                          onChange={(e) => {
                            const email = e.target.value;
                            if (email.length > 0) {
                              const emailRegex =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                              const isValid = emailRegex.test(email);
                              setEmailValue(email);
                              if (autoSelect) {
                                setEmailValueTwo(email);
                              }
                              setEmailValidation(!isValid);
                              if (isValid == true) {
                                handleChangeEmailData(email);
                              }
                            } else {
                              setEmailValue(email);
                              setEmailValidation(false);
                              if (autoSelect) {
                                setEmailValueTwo(email);
                              }
                            }
                          }}
                          error={emailValidation}
                          helperText={emailValidation ? t("invalid_email") : ""}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
          >
            <Collapsible
              title={t("signatures")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("signatures")}
              id={"signatures"}
              fontAlignment={true}
              required={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={noChecked}
                          onChange={handleNoChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("No_text")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={yesChecked}
                          onChange={handleChangeYesChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("Yes")}
                        </Typography>
                      </Box>
                    </Grid>

                    {yesChecked && (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography className={classes.keywordLabelText}>
                          {t("add_signatures")}
                        </Typography>

                        <TextField
                          required
                          fullWidth
                          value={signatureValue}
                          // helperText={t("Enter_signature_text")}
                          placeholder={t("enter_signature")}
                          onChange={(event) => {
                            setSignatureValue(event.target.value);
                            if (autoSelect) {
                              setSignatureValueTwo(event.target.value);
                            }
                          }}
                          //  fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
          >
            <Collapsible
              title={t("length")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("length")}
              id={"length"}
              fontAlignment={true}
              required={true}
              ComponentToRender={
                <Box
                  sx={{
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  <SliderComponent
                    customLengthValue={customLengthValue}
                    valuetext={valuetext}
                    lengthMarks={lengthMarks}
                  />
                  {/*    <Slider
                      aria-label="Always visible"
                      defaultValue={customLengthValue}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={lengthMarks}
                      min={1}
                      max={5}
                      // valueLabelDisplay="on"
                    /> */}
                </Box>

                /*   <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={shortChecked}
                          onChange={handleShortChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("short_text")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={detailedCheked}
                          onChange={handleDetailChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("detailed_text")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3}>
                      <Box className={classes.checkboxStyle}>
                        <Checkbox
                          sx={{ marginTop: "4px" }}
                          checked={customLength}
                          onChange={handleCustomLengthChecked}
                          name="google"
                          color="primary"
                        />

                        <Typography className={classes.typoStyle}>
                          {t("custom_text")}
                        </Typography>
                      </Box>
                    </Grid>

                    {customLength && (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography className={classes.keywordLabelText}>
                          {t("select_length")}
                        </Typography>

                        <TextField
                          type="number"
                          value={customLengthValue}
                          inputProps={{
                            type: "number",
                            min: 1,
                            step: 1,
                          }}
                          placeholder="e.g. 10"
                          fullWidth
                         
                          onChange={(e) => {
                            setCustomLengthValue(e.target.value);
                            if (autoSelect) {
                              setCustomLengthValueTwo(e.target.value);
                            }
                            handleDebouncedChange(e.target.value, "length");
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid> */
              }
            />
          </Grid>
          {present && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridStyle}
              ref={langGridRef}
            >
              <Collapsible
                title={t("langs")}
                handleChange={handleExpandPanel}
                expanded={expandedPanels.includes("language")}
                id={"language"}
                fontAlignment={true}
                required={true}
                displayInfoIcon={true}
                infoText={t("Location language_tooltipText")}
                ComponentToRender={
                  <Grid container>
                    <Grid container spacing={2}>
                      <Grid item xs={3} md={3} sm={3} lg={4}>
                        <Box className={classes.checkboxStyle}>
                          <Checkbox
                            sx={{ marginTop: "4px" }}
                            checked={userLanguageChecked}
                            onChange={handleChangeUserLanguage}
                            name="google"
                            color="primary"
                          />

                          <Typography className={classes.typoStyle}>
                            {t("Location_language")}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={3} md={3} sm={3} lg={4}>
                        <Box className={classes.checkboxStyle}>
                          <Checkbox
                            sx={{ marginTop: "4px" }}
                            checked={reviewLanguageChecked}
                            onChange={handleChangeReviewLanguage}
                            name="google"
                            color="primary"
                          />

                          <Typography className={classes.typoStyle}>
                            {t("reviewer_language")}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={3} md={3} sm={3} lg={4}>
                        <Box className={classes.checkboxStyle}>
                          <Checkbox
                            sx={{ marginTop: "4px" }}
                            checked={moreLanguageChecked}
                            onChange={handleChangeMoreLanguage}
                            name="google"
                            color="primary"
                          />

                          <Typography className={classes.typoStyle}>
                            {t("More_Languages")}
                          </Typography>
                        </Box>
                      </Grid>

                      {moreLanguageChecked && (
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <Typography className={classes.keywordLabelText}>
                            {t("Select_Language")}
                          </Typography>

                          <Autocomplete
                            multiple
                            disablePortal
                            disableCloseOnSelect
                            id="combo-box-demo"
                            options={languagesData}
                            sx={{ width: "100%" }}
                            getOptionLabel={(option) => option?.language}
                            className="Autocomplete-field"
                            // value={locationId}
                            value={userLanguage?.length > 0 ? userLanguage : []}
                            onChange={(event, value) => {
                              handleLanguageChange(value);
                              setLanguageRequired(false);
                              if (value.find((option) => option.id === 0)) {
                                // Check if all options are selected

                                if (
                                  languagesData?.length === userLanguage?.length
                                ) {
                                  handleLanguageChange([]);
                                  setSelectAllPresent(false);
                                } else {
                                  setSelectAllPresent(true);
                                  handleLanguageChange(languagesData);
                                }
                              } else {
                                handleLanguageChange(value);
                                if (value?.length === languagesData?.length) {
                                  setSelectAllPresent(true);
                                } else {
                                  setSelectAllPresent(false);
                                }
                              }
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              return [
                                { id: 0, language: "Select All" },
                                ...filtered,
                              ];
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected || selectAllPresent}
                                />
                                {option?.language}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                placeholder={t("Select_Language")}
                                {...params}
                                required
                                error={languageRequired}
                                name="language"
                              />
                            )}
                          ></Autocomplete>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridStyle}
          >
            <Collapsible
              title={t("extras")}
              handleChange={handleExpandPanel}
              expanded={expandedPanels.includes("extras")}
              id={"extras"}
              fontAlignment={true}
              ComponentToRender={
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className={classes.keywordLabelText}>
                          {t("Additional_Information")}
                        </Typography>

                        {/* {detectLanguageLoading ? (
                          <CircularProgress
                            style={{
                              width: "17px",
                              height: "17px",
                              color: "#06BDFF",
                              marginTop: "8px",
                              marginLeft: "28px",
                            }}
                          />
                        ) : textReplaced === false ? (
                          <BootstrapTooltip title={t("Text_added")}>
                            <CheckCircleOutlineIcon
                              className={classes.tickAddressIconRequested}
                              // onClick={() => handleRequestAddressChange()}
                            />
                          </BootstrapTooltip>
                        ) : (
                          <BootstrapTooltip title={t("press_enter_to_save")}>
                            <CheckCircleOutlineIcon
                              className={classes.tickAddressIcon}
                              //  onClick={() => handleRequestAddressChange()}
                            />
                          </BootstrapTooltip>
                        )} */}
                      </Box>

                      <TextField
                        required
                        fullWidth
                        // onKeyPress={(event) => {
                        //   if (event.key === "Enter") {
                        //     handleChangeExtraData(event.target.value);
                        //   }
                        // }}
                        value={extraData}
                        // helperText={t("Enter_extra_text")}
                        placeholder={t("addtional_placeholder")}
                        onChange={(event) => {
                          setExtraData(event.target.value);
                          if (autoSelect) {
                            setExtraDataTwo(event.target.value);
                          }
                          setDisplayExtraWarning(false);
                          setTextToReplaced(true);
                          handleDebouncedChange(event.target.value, "extra");
                        }}
                        //  fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sm={12}
                      lg={12}
                      sx={{ display: "flex" }}
                    >
                      <IconButton
                        sx={{
                          //  marginLeft: "5px",
                          cursor: "pointer",
                          padding: "0px",
                        }}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#1B2430",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          lineHeight: "16px",
                          color: "#495059",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      >
                        {t("extrasTooltip")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      {openTestModel && (
        <TestPromptModel
          open={openTestModel}
          handleCloseTestModel={handleCloseTestModel}
          textPresentPrompt={modelPresentPrompt}
          setTextPresentGeneratedContent={setTextPresentGeneratedContent}
          textPresentGeneratedContent={textPresentGeneratedContent}
          reviewsData={reviewsData}
          dummyReviewsData={dummyReviewsData}
          allReviewSource={allReviewSource}
          selectedTab={selectedTab}
          selectedRating={selectedRating}
          handleDisplayNames={handleDisplayNames}
          getLocation={getLocation}
          defaultLocation={defaultLocation}
          present={present}
          signatureValue={signatureValue}
          selectedLanguage={userLanguage}
          handleDisplayLanguageName={handleDisplayLanguageName}
          customLengthValue={customLengthValue}
        />
      )}
    </>
  );
};

export default ReviewTextPresent;
