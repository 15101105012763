import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import moment from "moment";

import { Grid, Typography, Button, CardContent, Box } from "@mui/material";
// import { GoogleImg } from "../../../../assets/images/GooglePic";
import GoogleImg from "../../../../assets/images/GooglePic.png";
import locationIcon from "../../../../assets/images/locationIcon.png";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StarRatings from "react-star-ratings";
import { useStyles } from "../../SentimentalAnalysis/styles/styles";
import { useEffect } from "react";
import useDateFormat from "../../../../hooks/useDateFormat";

const ReviewCard = ({
  item,
  allReviewSource,
  setReviewsValue,
  handleCloseReviewModel,
  reviewValue,
  handleChangeReviewsInPrompt,
  handleDisplayNames,
  setSelectedReviewData,
}) => {
  const classes = useStyles();
  const [reviewSource, setReviewSource] = useState(null);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (allReviewSource?.results?.length > 0) {
      const itemSource = allReviewSource?.results?.find(
        (itemOne) => itemOne?.id === item?.thirdPartyReviewSourcesId
      );
      setReviewSource(itemSource);
    }
  }, []);

  const handleClickReview = (reviewItem) => {
    handleChangeReviewsInPrompt(
      reviewItem?.ratingText ? reviewItem?.ratingText : reviewItem?.reviewText,
      reviewItem
    );
    setReviewsValue(
      reviewItem?.ratingText ? reviewItem?.ratingText : reviewItem?.reviewText
    );

    handleCloseReviewModel();
  };

  return (
    <>
      <Grid
        container
        sx={{
          "&:hover": {
            backgroundColor: "#E0F7FF",
            border: "1px solid #06BDFF",
          },
          backgroundColor:
            reviewValue ===
            (item?.ratingText ? item?.ratingText : item?.reviewText)
              ? "#E0F7FF"
              : "white",
          border:
            reviewValue ===
            (item?.ratingText ? item?.ratingText : item?.reviewText)
              ? "1px solid #06BDFF"
              : "1px solid #E0E0E0",

          cursor: "pointer",
          margin: "auto",
          width: "95%",
          borderRadius: "8px",
        }}
        onClick={() => handleClickReview(item)}
      >
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.reviewModalStyle}
          >
            <Box sx={{ display: "flex" }}>
              <Box>
                <img
                  alt=""
                  src={
                    item?.icon
                      ? item?.icon
                      : reviewSource !== null
                      ? `${baseURL}/${reviewSource?.image}`
                      : GoogleImg
                  }
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "5px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <StarRatings
                      rating={item?.ratingValue}
                      starDimension="20px"
                      starRatedColor="gold"
                      numberOfStars={5}
                      starSpacing="2px"
                      name="rating"
                    />

                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      {item?.reviewerTitle}
                    </Typography>

                    <Typography sx={{}}> {formatDate(item?.date)}</Typography>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <LocationOnOutlinedIcon sx={{ color: "#8D9298" }} />
                    <Typography sx={{ color: "#8D9298" }}>
                      {item?.hasOwnProperty("locationId")
                        ? handleDisplayNames(item?.locationId)
                        : "Hovarda 36-40 Rupert Street, London"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <Typography
                sx={{
                  color: "#495059",
                }}
              >
                {item?.ratingText ? item?.ratingText : item?.reviewText}
              </Typography>

              <Typography className={classes.negativeText}></Typography>
            </Box>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default ReviewCard;
