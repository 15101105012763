import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import GoogleIcon from "@mui/icons-material/Google";
import DeleteIcon from "@mui/icons-material/Delete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AutorenewSharpIcon from "@mui/icons-material/AutorenewSharp";
import {
  Box,
  IconButton,
  Typography,
  Checkbox,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  tooltipClasses,
} from "@mui/material";

import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import moment from "moment";
import useDateFormat from "../../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Table = ({
  row,
  index,
  handleConnectWithGoogle,
  handleNavigation,
  setOpen,
  setDeleteId,
  closeMenu,
  handleOpenDeleteModel,
  handleClick,
  handleClose,
  selectedRow,
  anchorEl,
  handleFacebookConnect,
  handleTaskChecked,
  disconnectLocations,
  checked,
  handleRequestedToConnect,
  handleOpenRequestModel,
  handleOpenFacebookModel,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  handleOpenRejectRequestModel,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [googleConnect, setGoogleConnect] = useState(null);
  const [facebookConnect, setFacebookConnect] = useState(null);
  const [displayFacebookIcon, setFacebookIcon] = useState(false);
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (row.enableFacebook === true) {
      setFacebookIcon(true);
    }
    row?.socialLink?.length > 0 &&
      row?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
        if (element?.type === "facebook") {
          setFacebookConnect(element);
        }
      });
  }, []);

  let textColor;

  let dotColor;
  if (row?.deleteRequest === true) {
    textColor = "#F86F35";

    dotColor = "#F86F35";
  } else if (row?.status === "approved") {
    textColor = "#13CF8F";

    dotColor = "#13CF8F";
  } else if (row?.status === "rejected") {
    textColor = "#FC3652";
    dotColor = "#FC3652";
  } else if (row?.status === "requested") {
    textColor = "#F8A92B";
    dotColor = "#F8A92B";
  } else {
    textColor = "black"; // Default color
  }

  return (
    <>
      <CustomizedTableRow
        // hover
        tabIndex={-1}
        key={`${row.id}-${index}`}
      >
        <CustomizedTableCell hover>
          <Checkbox
            checked={disconnectLocations.includes(row.id)}
            onChange={(e) => {
              handleTaskChecked(e, index, row.id, row);
            }}
            // disabled={row?.socialLink?.length===0}
            //   inputProps={{ "aria-label": "controlled" }}
            label=""
          />
        </CustomizedTableCell>
        <CustomizedTableCell>{row.id}</CustomizedTableCell>
        <CustomizedTableCell align="left" sx={{ width: "300px" }}>
          {/* {row?.name ? row?.name + "(" + row?.city + ")" : ""} */}
          <Typography>
            {row?.internalName
              ? row?.internalName +
                (row?.city !== null && row?.city !== undefined
                  ? "(" + row?.city + ")"
                  : "")
              : ""}
          </Typography>
          {row?.state === "in-active" && (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#FF1616",
              }}
            >
              {t("Permanently_Closed")}
            </Typography>
          )}
        </CustomizedTableCell>
        <CustomizedTableCell align="left">
          {row?.addressLine1 !== "" &&
          row?.addressLine1 !== null &&
          row?.addressLine1 !== undefined
            ? row?.addressLine1
            : ""}
        </CustomizedTableCell>

        <CustomizedTableCell align="left">
          {`${formatDate(row?.createdAt)}`}
        </CustomizedTableCell>
        {/*  <CustomizedTableCell align="left">
          {row?.partner?.name}
        </CustomizedTableCell> */}
        {/*   <CustomizedTableCell padding="none" align="center">
          {row?.defaultLocation === 0 ? (
            ""
          ) : (
            <Box mr={2}>
              {row?.locationSubscription.map(function (item, i) {
                return (
                  <>
                    {item?.status === "active" ? (
                      <Chip
                        sx={{
                          padding: "5px",
                          margin: "5px",
                        }}
                        label={item.subscription.name}
                        m={1}
                      />
                    ) : null}
                  </>
                );
              })}
            </Box>
          )}
        </CustomizedTableCell> */}

        <CustomizedTableCell padding="none" align="left">
          {row?.defaultLocation === null ? (
            ""
          ) : (
            <Box mr={2}>
              <Chip
                sx={{
                  padding: "5px",
                  margin: "5px",
                }}
                label={"Default"}
                m={1}
              />
            </Box>
          )}
        </CustomizedTableCell>
        <CustomizedTableCell
          align="left"
          sx={{ color: textColor, width: "150px" }}
        >
          <FiberManualRecordIcon
            sx={{ fontSize: 10, color: dotColor, paddingRight: "3px" }}
          />
          {row?.deleteRequest
            ? t("deletion_pending")
            : row?.status.charAt(0).toUpperCase() +
              row?.status.slice(1).toLowerCase()}
          {/*   {t("deletion_pending")} */}
        </CustomizedTableCell>

        <CustomizedTableCell padding="none" align="center">
          <Box mr={2} sx={{ alignItems: "center" }}>
            {editPermission && viewPermission ? (
              <BootstrapTooltip title={t("EditLocation")}>
                <IconButton
                  aria-label="edit"
                  size="large"
                  onClick={() =>
                    navigate(`/user/edit_location?state=${row?.id}/`)
                  }
                >
                  <Edit />
                </IconButton>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <IconButton
                    disabled
                    aria-label="edit"
                    size="large"
                    onClick={() =>
                      navigate(`/user/edit_location?state=${row?.id}/`)
                    }
                  >
                    <Edit disabled />
                  </IconButton>
                </span>
              </BootstrapTooltip>
            )}

            {displayFacebookIcon === true &&
              (facebookConnect !== null ? (
                editPermission && viewPermission ? (
                  <BootstrapTooltip title={t("Connected_with_facebook")}>
                    <IconButton
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        navigate(`/user/edit_location?state=${row?.id}/`, {
                          state: {
                            showProduct: true,
                          },
                        });
                      }}
                    >
                      <FacebookOutlinedIcon
                        sx={{
                          color: "#0639c2",
                        }}
                      />
                    </IconButton>
                  </BootstrapTooltip>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <IconButton
                      aria-label="edit"
                      size="large"
                      disabled
                      onClick={() => {
                        navigate(`/user/edit_location?state=${row?.id}/`, {
                          state: {
                            showProduct: true,
                          },
                        });
                      }}
                    >
                      <FacebookOutlinedIcon
                        disabled
                        sx={{
                          color: "#0639c2",
                        }}
                      />
                    </IconButton>
                  </BootstrapTooltip>
                )
              ) : addPermission && viewPermission ? (
                <BootstrapTooltip title={t("facebook_connect")}>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    onClick={() => {
                      handleFacebookConnect(row);
                    }}
                  >
                    <FacebookOutlinedIcon
                      sx={{
                        color: "gray",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    disabled
                    onClick={() => {
                      handleFacebookConnect(row);
                    }}
                  >
                    <FacebookOutlinedIcon
                      disabled
                      sx={{
                        color: "gray",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ))}
            {googleConnect !== null && googleConnect.validToken === true ? (
              editPermission && viewPermission ? (
                <BootstrapTooltip title={t("Connected_with_google")}>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    onClick={() => {
                      handleConnectWithGoogle(true);
                    }}
                  >
                    <GoogleIcon
                      sx={{
                        color: "#34A853",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <IconButton
                      disabled
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleConnectWithGoogle(true);
                      }}
                    >
                      <GoogleIcon
                        disabled
                        sx={{
                          color: "#34A853",
                        }}
                      />
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              )
            ) : googleConnect !== null && googleConnect.validToken === false ? (
              editPermission && viewPermission ? (
                <BootstrapTooltip title={t("token_expired")}>
                  <IconButton
                    aria-label="edit"
                    size="large"
                    onClick={() => {
                      handleNavigation(row);
                    }}
                  >
                    <GoogleIcon
                      sx={{
                        color: "#FF1616",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <IconButton
                      disabled
                      aria-label="edit"
                      size="large"
                      onClick={() => {
                        handleNavigation(row);
                      }}
                    >
                      <GoogleIcon
                        disabled
                        sx={{
                          color: "#FF1616",
                        }}
                      />
                    </IconButton>
                  </span>
                </BootstrapTooltip>
              )
            ) : addPermission && viewPermission ? (
              <BootstrapTooltip title={t("Connect_With_Google_Text")}>
                <IconButton
                  aria-label="edit"
                  size="large"
                  onClick={() => {
                    handleConnectWithGoogle(false);
                  }}
                >
                  <GoogleIcon
                    sx={{
                      color: "#8D9298",
                    }}
                  />
                </IconButton>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <IconButton
                    disabled
                    aria-label="edit"
                    size="large"
                    onClick={() => {
                      handleConnectWithGoogle(false);
                    }}
                  >
                    <GoogleIcon
                      disabled
                      sx={{
                        color: "#8D9298",
                      }}
                    />
                  </IconButton>
                </span>
              </BootstrapTooltip>
            )}

            {/*   {displayFacebookIcon===true && (
              <CustomWidthTooltip
                title={
                  facebookConnect !== null
                    ? t("Connected_with_facebook")
                    : t("facebook_connect")
                }
              >
                <IconButton
                  aria-label="edit"
                  size="large"
                  onClick={() => {
                    if (facebookConnect===null) {
                      handleFacebookConnect(row);
                    } else {
                      navigate(`/user/edit_location?state=${row?.id}/`, {
                        state: {
                          showProduct: true,
                        },
                      });
                    }
                  }}
                >
                  <FacebookOutlinedIcon
                    sx={{
                      color: facebookConnect !== null ? "#0639c2" : "gray",
                    }}
                  />
                </IconButton>
              </CustomWidthTooltip>
            )} */}
            <IconButton onClick={(e) => handleClick(e, row)} variant="outlined">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {selectedRow?.status !== "rejected" &&
                selectedRow?.status !== "requested" &&
                (deletePermission && viewPermission ? (
                  selectedRow?.deleteRequest ? (
                    <BootstrapTooltip title={t("delete_request_sent")}>
                      <span>
                        <MenuItem
                          disabled
                          onClick={() => {
                            closeMenu();
                            handleOpenDeleteModel(selectedRow?.id);
                          }}
                        >
                          <DeleteIcon
                            disabled
                            sx={{
                              color: "#545353",
                              paddingRight: "5px",
                            }}
                          />
                          <Typography
                            aria-label="delete"
                            size="large"
                            onClick={() => {
                              setOpen(true);
                              setDeleteId(selectedRow?.id);
                            }}
                          >
                            {t("Request_Delete")}
                          </Typography>
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        handleOpenDeleteModel(selectedRow?.id);
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color: "#545353",
                          paddingRight: "5px",
                        }}
                      />
                      <Typography
                        aria-label="delete"
                        size="large"
                        onClick={() => {
                          setOpen(true);
                          setDeleteId(selectedRow?.id);
                        }}
                      >
                        {t("Request_Delete")}
                      </Typography>
                    </MenuItem>
                  )
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <MenuItem
                        disabled
                        onClick={() => {
                          closeMenu();
                          handleOpenDeleteModel(selectedRow?.id);
                        }}
                      >
                        <DeleteIcon
                          disabled
                          sx={{
                            // color: "#545353",
                            paddingRight: "5px",
                          }}
                        />
                        <Typography
                          aria-label="delete"
                          size="large"
                          onClick={() => {
                            setOpen(true);
                            setDeleteId(selectedRow?.id);
                          }}
                        >
                          {t("Request_Delete")}
                        </Typography>
                      </MenuItem>
                    </span>
                  </BootstrapTooltip>
                ))}

              {selectedRow?.status === "rejected" && (
                <>
                  {editPermission && viewPermission ? (
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        handleOpenRejectRequestModel(selectedRow?.id);
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color: "#545353",
                          paddingRight: "5px",
                        }}
                      />
                      <Typography
                        aria-label="delete"
                        size="large"
                        onClick={() => {
                          // setOpen(true);
                          setDeleteId(selectedRow?.id);
                        }}
                      >
                        {t("Delete")}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <MenuItem
                          disabled
                          onClick={() => {
                            closeMenu();
                            //    handleOpenRejectRequestModel(selectedRow?.id);
                          }}
                        >
                          <AutorenewSharpIcon
                            disabled
                            sx={{
                              //  color: "#545353",
                              paddingRight: "5px",
                            }}
                          />

                          <Typography
                            aria-label="requested"
                            size="large"
                            onClick={() => {
                              // handleOpenRequestModel(selectedRow?.id);
                            }}
                          >
                            {t("Delete")}
                          </Typography>
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  )}
                </>
              )}

              {selectedRow?.status === "requested" && (
                <>
                  {editPermission && viewPermission ? (
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        handleOpenRejectRequestModel(selectedRow?.id);
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color: "#545353",
                          paddingRight: "5px",
                        }}
                      />
                      <Typography
                        aria-label="delete"
                        size="large"
                        onClick={() => {
                          // setOpen(true);
                          setDeleteId(selectedRow?.id);
                        }}
                      >
                        {t("Delete")}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <MenuItem
                          disabled
                          onClick={() => {
                            closeMenu();
                            //    handleOpenRejectRequestModel(selectedRow?.id);
                          }}
                        >
                          <AutorenewSharpIcon
                            disabled
                            sx={{
                              //  color: "#545353",
                              paddingRight: "5px",
                            }}
                          />

                          <Typography
                            aria-label="requested"
                            size="large"
                            onClick={() => {
                              // handleOpenRequestModel(selectedRow?.id);
                            }}
                          >
                            {t("Delete")}
                          </Typography>
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  )}
                </>
              )}

              {selectedRow?.status === "rejected" && (
                <>
                  <Divider />
                  {editPermission && viewPermission ? (
                    <MenuItem
                      onClick={() => {
                        closeMenu();
                        // handleRequestedToConnect();
                      }}
                    >
                      <AutorenewSharpIcon
                        sx={{
                          color: "#545353",
                          paddingRight: "5px",
                        }}
                      />

                      <Typography
                        aria-label="requested"
                        size="large"
                        onClick={() => {
                          handleOpenRequestModel(selectedRow?.id);
                        }}
                      >
                        {t("Requested_connect")}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <MenuItem
                          disabled
                          onClick={() => {
                            closeMenu();
                            // handleRequestedToConnect();
                          }}
                        >
                          <AutorenewSharpIcon
                            disabled
                            sx={{
                              //  color: "#545353",
                              paddingRight: "5px",
                            }}
                          />

                          <Typography
                            aria-label="requested"
                            size="large"
                            onClick={() => {
                              handleOpenRequestModel(selectedRow?.id);
                            }}
                          >
                            {t("Requested_connect")}
                          </Typography>
                        </MenuItem>
                      </span>
                    </BootstrapTooltip>
                  )}
                </>
              )}
            </Menu>
          </Box>
        </CustomizedTableCell>
      </CustomizedTableRow>
    </>
  );
};

export default Table;
