import { toast } from "react-toastify";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import React, { useEffect, useState } from "react";
import NotConnected from "../../../../components/CustomComponents/Errors/NotConnected";
import NoPostFound from "../../../../components/CustomComponents/Errors/NoPostFound";

import Googleicon from "../../../../assets/Icons/Group.svg";
import QuestionMark from "../../../../assets/Icons/Question.svg";
import { Box, Button, Menu, MenuItem, Modal, Typography } from "@mui/material";
import TitleHeader from "../../../../components/CustomComponents/title";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import Iframe from "../../../../components/Models/IframeModal/Iframe";
import useAuth from "../../../../hooks/useAuth";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as AddGroup } from "../../../../assets/images/AddGroup.svg";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../../components/Loaders/Loader";
import AllPostsCard from "./AllPostsCard";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import LocationNotFound from "../../../../components/CustomComponents/Errors/NoLocationFound";
import { useStyles } from "../styles/style";
import ReconnectCard from "../../../../components/CustomComponents/Cards/ReconnectCard";
import { Virtuoso } from "react-virtuoso";
import { languageOptions } from "../../../../assets/defaultLanguage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditBulkPosts from "../EditPosts/BulkEditModal";
import { LanguageChange } from "../../../../utils/LocalStorageHelpers";
import { checkLocalStorageData } from "../../../../utils/LocalStorageHelpers";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const AllPost = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isDataValid = checkLocalStorageData();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [open, setOpen] = useState(false);
  const [openPauseModel, setOpenPauseModel] = useState(false);
  const [openResumeModel, setOpenResumeModel] = useState(false);
  const [openBulkEditModel, setOpenBulkEditModel] = useState(false);
  //const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [upcomingPosts, setUpcomingPosts] = useState([]);
  const [completedPosts, setCompletedPosts] = useState([]);
  const [deletePosts, setDeletePosts] = useState([]);
  const [selectAllUpcoming, setSelectAllUpcoming] = useState(false);
  const [selectAllCompleted, setSelectAllCompleted] = useState(false);
  //const [openModal, setOpenModal] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [locationId, setLocationId] = useState([]);
  const [socialLink, setsociallink] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [expiredLocations, setExpiredLocation] = useState(null);
  const [displayReconnectButton, setDisplayReconnectButton] = useState(false);
  const [displayReconnectError, setDisplayReconnectError] = useState(false);
  const { signOut, permissionsAcess, user } = useAuth();
  const [pageNumber, setPageNumber] = useState(0);
  const locationIdParams = location?.state?.locationId;
  const [selectedTab, setSelectedTab] = useState(0);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  const [callApi, setCallApi] = useState(false);
  let selectedLanguage = "";
  let userLang = "";
  selectedLanguage = languageOptions[userLang];
  const [viewPermission, setViewPermission] = useState(false);
  const [locationEditPermission, setLocationEditPermission] = useState(false);
  const [locationAddPermission, setLocationAddPermission] = useState(false);
  const [locationDeletePermission, setLocationDeletePermission] =
    useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [locationViewPermission, setLocationViewPermission] = useState(false);
  const [selectedPosts, setSelectedPosts] = useState({
    paused: [],
    resumed: [],
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [bulkEditData, setBulkEditData] = useState([]);

  useEffect(() => {
    if (isDataValid) {
      if (languageData !== "en" || stateData?.language === null) {
        LanguageChange();
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/post"
      );

      filteredPermissions?.forEach((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
      let filteredLocationPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredLocationPermissions?.forEach((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setLocationViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isDataValid) {
      getAllLocations();
      getSelectedCustomer();
      getAllGroups();
    }
  }, []);

  useEffect(() => {
    if (allLocation?.results?.length > 0) {
      let arr = [];
      allLocation?.results?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    }
  }, [allLocation]);

  useEffect(() => {
    if (locationIdParams) {
      setLocationId(locationIdParams);
    }
  }, [locationIdParams]);

  useEffect(() => {
    if (isDataValid) {
      if (selectedTab === 0) {
        getAllUpcomingPosts();
      } else {
        getAllCompletedPosts();
      }
    }
  }, [selectedTab]);
  useEffect(() => {
    if (isDataValid) {
      if (selectedTab === 0) {
        getAllUpcomingPosts();
      } else {
        getAllCompletedPosts();
      }
    }
  }, [locationId, selectedGroup]);

  const closeMenu = () => {
    // setAnchorMenu(null);
  };
  const getAllGroups = async () => {
    // setLocationLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data);

        // setLocationLoading(false);
      }
    } catch (error) {
      //  setLocationLoading(false);
      setAllGroups(null);
    }
  };

  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClosePauseModel = () => {
    setOpenPauseModel(false);
  };
  const handleCloseResumeModel = () => {
    setOpenResumeModel(false);
  };
  const handleOpen = () => setOpen(true);
  const handleOpenPauseModel = () => setOpenPauseModel(true);
  const handleOpenResumeModel = () => setOpenResumeModel(true);
  const handleOpenBulkEditModel = () => setOpenBulkEditModel(true);

  const getAllUpcomingPosts = async (page) => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.forEach((location) => {
        locationIds.push(location?.id);
      });
    }

    try {
      setIsLoading(true);
      const res = await api.get(
        `/post?status=upcoming&limit=10&page=${
          page === true ? 1 : pageNumber + 1
        }&groupId=${
          selectedGroup !== null ? selectedGroup?.id : ""
        }&locationIds=${locationIds?.length > 0 ? locationIds : ""}`
      );

      if (res.status === 200 || res.status === 201) {
        setUpcomingPosts(res?.data?.data);
        if (res?.data?.data?.results?.length > 0) {
          setCallApi(true);
        } else {
          setCallApi(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.res?.data?.message);
      setIsLoading(false);
      setUpcomingPosts([]);
    }
  };

  const getAllCompletedPosts = async (page) => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.forEach((location) => {
        locationIds.push(location?.id);
      });
    }

    try {
      setIsLoading(true);
      const res = await api.get(
        `/post?status=completed&limit=10&page=${
          page === true ? 1 : pageNumber + 1
        }&groupId=${
          selectedGroup !== null ? selectedGroup?.id : ""
        }&locationIds=${locationIds?.length > 0 ? locationIds : ""}`
      );

      if (res.status === 200 || res.status === 201) {
        setCompletedPosts(res?.data?.data);
        if (res?.data?.data?.results?.length > 0) {
          setCallApi(true);
        } else {
          setCallApi(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.res?.data?.message);
      setIsLoading(true);
      setCompletedPosts([]);
    }
  };

  const fetchMoreUpcomingData = async () => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.forEach((location) => {
        locationIds.push(location?.id);
      });
    }

    if (
      upcomingPosts?.results?.length < upcomingPosts?.count &&
      callApi === true
    ) {
      try {
        const res = await api.get(
          `/post?status=upcoming&limit=10&page=${pageNumber + 2}&groupId=${
            selectedGroup !== null ? selectedGroup?.id : ""
          }&locationIds=${locationIds?.length > 0 ? locationIds : ""}`
        );
        if (res.status === 200) {
          // setReviewsData((prev) => {});
          let data;
          let tempReviews = upcomingPosts?.results;
          data = { ...res.data.data };
          data["results"] = [...tempReviews, ...res.data.data?.results];
          if (res?.data?.data?.results?.length > 0) {
            setCallApi(true);
          } else {
            setCallApi(false);
          }

          setUpcomingPosts(data);
          setPageNumber(pageNumber + 1);
        } else {
          setUpcomingPosts([]);
        }
      } catch (error) {
        setUpcomingPosts([]);
      }
    }
  };
  const fetchMoreCompletedData = async () => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.forEach((location) => {
        locationIds.push(location?.id);
      });
    }

    if (
      completedPosts?.results?.length < completedPosts?.count &&
      callApi === true
    ) {
      try {
        const res = await api.get(
          `/post?status=completed&limit=10&page=${pageNumber + 2}&groupId=${
            selectedGroup !== null ? selectedGroup?.id : ""
          }&locationIds=${locationIds?.length > 0 ? locationIds : ""}`
        );
        if (res.status === 200) {
          // setReviewsData((prev) => {});
          let data;
          let tempReviews = completedPosts?.results;
          data = { ...res.data.data };
          data["results"] = [...tempReviews, ...res.data.data?.results];
          if (res?.data?.data?.results?.length > 0) {
            setCallApi(true);
          } else {
            setCallApi(false);
          }
          setCompletedPosts(data);
          setPageNumber(pageNumber + 1);
        } else {
          setCompletedPosts([]);
        }
      } catch (error) {
        setCompletedPosts([]);
      }
    }
  };

  const handleLocationChange = (option) => {
    setPageNumber(0);
    setLocationId(option);

    if (option?.length > 0) {
      const arrr = option;
      let arr = [];
      arrr?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });

      /*   const filteredArr = arr?.filter(
        (item) =>
          item?.socialLink?.length > 0 &&
          item?.socialLink[0]?.validToken===false
      ); */
      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    } else {
    }
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };
  const getAllLocations = async () => {
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });

      if (res.status === 200) {
        setAllLocation(res.data.data);
        let defaultlocation = null;
        if (res.data.data.results.length > 0) {
          defaultlocation = res.data.data.results?.find(
            (item, index) => item.defaultLocation !== null
          );
          // setDefaultvalueLocation(defaultlocation);
        } else {
          //  setDefaultvalueLocation(null);
        }

        let socialLinktext = true;
        for (var i = 0; i < res.data.data?.results.length; i++) {
          if (res.data.data?.results[i].socialLink.length > 0) {
            socialLinktext = false;
          }
        }
        if (socialLinktext) {
          setsociallink(true);
        } else {
          setsociallink(false);
        }
      } else {
        toast.error("No location found ");
      }
    } catch (error) {
      setAllLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleDeleteBulkPosts = async (deletePostIds) => {
    setDeleteLoading(true);
    try {
      const res = await api.patch(`/post/deleteBulk`, {
        postIds: deletePostIds,
      });
      if (res.status === 200) {
        if (selectedTab === 0) {
          getAllUpcomingPosts(true);
        } else {
          getAllCompletedPosts(true);
        }

        toast.success("Posts Deleted Successfully");
        setOpen(false);
        closeMenu(false);
        setDeletePosts([]);
        setSelectAllCompleted(false);
        setSelectAllUpcoming(false);
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeletePosts([]);
      setDeleteLoading(false);
      toast.error("Posts Delete Failed");
    }
  };
  const handlePosts = async (deletePostIds) => {
    setDeleteLoading(true);
    try {
      const res = await api.patch(`/post/pausedPosts`, {
        postIds: deletePostIds,
      });
      if (res.status === 200) {
        if (selectedTab === 0) {
          getAllUpcomingPosts(true);
        } else {
          getAllCompletedPosts(true);
        }
        toast.success(
          selectedPosts.paused.length > 0
            ? "Post Resumed Successfully"
            : "Post Paused Successfully"
        );
        setOpenPauseModel(false);
        setOpenResumeModel(false);
        closeMenu(false);
        setDeletePosts([]);
        setSelectedPosts({ paused: [], resumed: [] });
        setSelectAllUpcoming(false);
        handleClose();
      }
      setDeleteLoading(false);
    } catch (error) {
      setOpenPauseModel(false);
      setOpenResumeModel(false);
      setDeleteLoading(false);
      toast.error(
        selectedPosts.paused.length > 0
          ? "Post Resumed Failed"
          : "Post Paused Failed"
      );
      setDeletePosts([]);
      setSelectedPosts({ paused: [], resumed: [] });
      setSelectAllUpcoming(false);
    }
  };

  const separatedPosts = (id, status) => {
    setSelectedPosts((prevState) => {
      if (!status) {
        if (!prevState.resumed.includes(id)) {
          return {
            ...prevState,
            resumed: [...prevState.resumed, id],
          };
        } else {
          return {
            ...prevState,
            resumed: prevState.resumed.filter((postId) => postId !== id),
          };
        }
      } else {
        if (!prevState.paused.includes(id)) {
          return {
            ...prevState,
            paused: [...prevState.paused, id],
          };
        } else {
          return {
            ...prevState,
            paused: prevState.paused.filter((postId) => postId !== id),
          };
        }
      }
    });
  };

  const handleChangeUpcomingCheckButton = (e) => {
    setSelectAllUpcoming(e.target.checked);
    if (e.target.checked === false) {
      setSelectedPosts({ paused: [], resumed: [] });
    } else {
      upcomingPosts?.results?.forEach((item, i) => {
        setSelectedPosts((prevState) => {
          return {
            ...prevState,
            resumed: prevState.resumed.concat(
              upcomingPosts?.results
                .filter(
                  (item) =>
                    !item.isPaused && !prevState.resumed.includes(item.id)
                )
                .map((item) => item.id)
            ),
            paused: prevState.paused.concat(
              upcomingPosts?.results
                .filter(
                  (item) => item.isPaused && !prevState.paused.includes(item.id)
                )
                .map((item) => item.id)
            ),
          };
        });
      });
    }

    if (e.target.checked) {
      for (var i = 0; i < upcomingPosts?.results.length; i++) {
        deletePosts.push(upcomingPosts?.results[i].id);
      }
      const uniquePosts = new Set(deletePosts);
      setDeletePosts([...uniquePosts]);
    } else {
      setDeletePosts([]);
    }
  };
  const handleChangeCompletedCheckButton = (e) => {
    setSelectAllCompleted(e.target.checked);
    if (e.target.checked) {
      for (var i = 0; i < completedPosts?.results.length; i++) {
        deletePosts.push(completedPosts?.results[i].id);
      }
      const uniquePosts = new Set(deletePosts);
      setDeletePosts([...uniquePosts]);
    } else {
      setDeletePosts([]);
    }
  };

  const handleUpcomingPostChecked = (e, index, postId, status) => {
    separatedPosts(postId, status);
    if (e.target.checked) {
      deletePosts.push(postId);
    } else {
      var postIndex = deletePosts.indexOf(postId);
      deletePosts.splice(postIndex, 1);
    }

    if (upcomingPosts?.results?.length > 0) {
      const filtered = upcomingPosts?.results;
      if (filtered?.length === deletePosts?.length) {
        setDeletePosts([]);
        setSelectAllUpcoming(true);
      } else {
        setSelectAllUpcoming(false);

        setDeletePosts(filtered);
      }
    }
    setDeletePosts([...deletePosts]);
  };
  const handleCompletedPostChecked = (e, index, postId) => {
    if (e.target.checked) {
      deletePosts.push(postId);
    } else {
      var postIndex = deletePosts.indexOf(postId);
      deletePosts.splice(postIndex, 1);
    }

    if (completedPosts?.results?.length > 0) {
      const filtered = completedPosts?.results;
      if (filtered?.length === deletePosts?.length) {
        setDeletePosts([]);
        setSelectAllCompleted(true);
      } else {
        setSelectAllCompleted(false);

        setDeletePosts(filtered);
      }
    }
    setDeletePosts([...deletePosts]);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}
  }
  const handleConnectWithGoogle = () => {
    fetchURL();
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
    setPageNumber(0);
    setDeletePosts([]);
    setSelectAllCompleted(false);
    setSelectAllUpcoming(false);
  };

  const handleChangeGroups = (value) => {
    setPageNumber(0);
    setSelectedGroup(value);
  };
  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    // setSelectedReviewItem(item);
  };

  const getBulkEditData = () => {
    const filteredData = upcomingPosts?.results.filter((obj) =>
      deletePosts.includes(obj.id)
    );
    const updatedData = filteredData?.map((item) => {
      return {
        ...item,
        postPictures: item?.postPicture,
        locationData: item?.postLocations.map((location) => {
          return {
            ...location.location,
          };
        }),
      };
    });
    setBulkEditData(updatedData);
    handleOpenBulkEditModel();
    handleClose();
  };

  return (
    <>
      {allLocation === null || allLocation?.results?.length <= 0 ? (
        <>
          <TitleHeader
            title={t("All_Posts")}
            subHeading={t("post_subHeading")}
            extraButton={true}
            extraButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} alt="QuestionMark" />
              </Tooltip>
            }
            handleDefaultTemplate={handleOpenTemplateMenu}
          />
        </>
      ) : socialLink ? (
        <>
          <TitleHeader
            title={t("All_Posts")}
            subHeading={t("post_subHeading")}
            extraButton={true}
            extraButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} alt="QuestionMark" />
              </Tooltip>
            }
            handleDefaultTemplate={handleOpenTemplateMenu}
          />
        </>
      ) : (
        <>
          <TitleHeader
            title={t("All_Posts")}
            subHeading={t("post_subHeading")}
            IframeButton={true}
            IframeButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} alt="QuestionMark" />
              </Tooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            //     handleDefaultTemplate={handleOpenTemplateMenu}
            createButton={true}
            name={t("Add_Bulk_Post")}
            onClick={() => {
              navigate("/user/posts", {
                state: { location: locationId },
              });
            }}
            addPermission={addPermission}
            viewPermission={viewPermission}
          />
        </>
      )}
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Post_Tutorial")}
            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f8yxuj4dh"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1100px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
        </Dialog>
      )}
      {(allLocation !== null || allLocation?.results?.length > 0) && (
        <Grid container spacing={2}>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={
                allLocation?.results?.length > 0 ? allLocation?.results : []
              }
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? " (" + option.addressLine1
                      : "") +
                    (option?.city !== "" &&
                    option?.city !== null &&
                    option?.city !== undefined
                      ? (option?.addressLine1 ? ", " : " (") + option.city
                      : "") +
                    ")"
                  : ""
              }
              className="Autocomplete-field"
              value={locationId ? locationId : []}
              onChange={(e, value) => {
                handleLocationChange(value);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option?.internalName} (${
                    option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1
                      : ""
                  }${
                    option?.city !== "" &&
                    option?.city !== null &&
                    option?.city !== undefined
                      ? ", " + option.city
                      : ""
                  })`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder={t("SelectLocation")}
                  {...params}
                  required
                  name="language"
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item xs={4.5} sm={4.5} md={4.5} lg={4.5}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                allGroups !== null && allGroups?.rows?.length > 0
                  ? allGroups?.rows
                  : []
              }
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              className="Autocomplete-field"
              value={selectedGroup}
              onChange={(e, value) => {
                handleChangeGroups(value);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("Select_group")}
                  {...params}
                  required
                  name="language"
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid
            item
            xs={0.5}
            sm={0.5}
            md={0.5}
            lg={0.5}
            sx={{ textAlign: "center" }}
          >
            {locationViewPermission && locationAddPermission ? (
              <BootstrapTooltip title={t("manage_groups")}>
                <IconButton
                  onClick={() => handleNavigationToGroups()}
                  sx={{ paddingTop: "13px", cursor: "pointer" }}
                >
                  <AddGroup />
                </IconButton>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    disabled
                    onClick={() => handleNavigationToGroups()}
                    sx={{ paddingTop: "13px", cursor: "pointer" }}
                  >
                    <AddGroup />
                  </IconButton>
                </span>
              </BootstrapTooltip>
            )}
          </Grid>
        </Grid>
      )}

      {(allLocation !== null || allLocation?.results?.length > 0) && (
        <Grid
          container
          sx={{
            height: "56px",
            background: "#FFF",
            paddingTop: "8px",
            paddingBottom: "8px",
            marginTop: "10px",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="scrollable"
          >
            <Tab className={classes.resultsTabs} label={t("upcomingPosts")} />
            <Tab className={classes.resultsTabs} label={t("completedPosts")} />
          </Tabs>
        </Grid>
      )}

      {allLocation === null || allLocation?.results?.length <= 0 ? (
        <>
          <Box className={classes.locationbox}>
            <LocationNotFound
              text={t("No_Location_Added")}
              subtext={t("Google_location_msg")}
            />
          </Box>
        </>
      ) : socialLink ? (
        <>
          <>
            <Box className={classes.locationbox}>
              <NotConnected
                text={t("Connect With Google")}
                subtext={
                  "It seems your location is not connected with Google. Please connect it to get posts from Google"
                }
              />

              <CommonButton
                onSubmit={handleConnectWithGoogle}
                icon={
                  <img
                    alt=""
                    src={Googleicon}
                    style={{ paddingRight: "8px" }}
                    alt="Google Icon"
                  />
                }
                label={" Connect with Google"}
              />
            </Box>
          </>
        </>
      ) : (
        <>
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {displayReconnectError && (
                  <Grid container sx={{ marginTop: "10px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <ReconnectCard
                        location={expiredLocations}
                        displayReconnectButton={displayReconnectButton}
                        addPermission={locationAddPermission}
                        editPermission={locationEditPermission}
                        deletePermission={locationDeletePermission}
                        viewPermission={locationViewPermission}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container sx={{ height: "100%" }}>
                  <Grid item xs={12} md={12} lg={12}>
                    {selectedTab === 0 &&
                      (upcomingPosts?.results?.length > 0 ? (
                        <>
                          <Grid container className={classes.iconContainerPost}>
                            <Grid item xs={6} sm={6} md={8} lg={8}>
                              <Checkbox
                                checked={selectAllUpcoming}
                                onChange={(e) =>
                                  handleChangeUpcomingCheckButton(e)
                                }
                              />
                              {t("Select_All")}
                            </Grid>
                            {deletePosts.length > 0 && (
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <>
                                  <Button
                                    sx={{ width: "110px" }}
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e)}
                                    variant="outlined"
                                    color="inherit"
                                  >
                                    {t("Actions")} <KeyboardArrowDownIcon />
                                  </Button>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                  >
                                    {deletePosts?.length > 1 &&
                                      (editPermission && viewPermission ? (
                                        <MenuItem
                                          sx={{ width: "110px" }}
                                          onClick={() => getBulkEditData()}
                                        >
                                          <Typography>
                                            {t("Bulk_Edit")}
                                          </Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <MenuItem
                                              sx={{ width: "110px" }}
                                              disabled
                                            >
                                              <Typography>
                                                {t("Bulk_Edit")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      ))}
                                    {deletePermission && viewPermission ? (
                                      <MenuItem
                                        sx={{ width: "110px" }}
                                        onClick={() => handleOpen()}
                                      >
                                        <Typography>{t("Delete")}</Typography>
                                      </MenuItem>
                                    ) : (
                                      <BootstrapTooltip
                                        title={t("authorized_access")}
                                      >
                                        <span>
                                          <MenuItem
                                            sx={{ width: "110px" }}
                                            disabled
                                          >
                                            <Typography>
                                              {t("Delete")}
                                            </Typography>
                                          </MenuItem>
                                        </span>
                                      </BootstrapTooltip>
                                    )}
                                    {selectedPosts.paused.length > 0 &&
                                      (editPermission && viewPermission ? (
                                        <MenuItem
                                          sx={{ width: "110px" }}
                                          onClick={() =>
                                            handleOpenResumeModel()
                                          }
                                        >
                                          <Typography>{t("resume")}</Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <MenuItem
                                              sx={{ width: "110px" }}
                                              disabled
                                            >
                                              <Typography>
                                                {t("resume")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      ))}
                                    {selectedPosts.resumed.length > 0 &&
                                      (editPermission && viewPermission ? (
                                        <MenuItem
                                          sx={{ width: "110px" }}
                                          onClick={() => handleOpenPauseModel()}
                                        >
                                          <Typography>{t("Pause")}</Typography>
                                        </MenuItem>
                                      ) : (
                                        <BootstrapTooltip
                                          title={t("authorized_access")}
                                        >
                                          <span>
                                            <MenuItem
                                              sx={{ width: "110px" }}
                                              disabled
                                            >
                                              <Typography>
                                                {t("Pause")}
                                              </Typography>
                                            </MenuItem>
                                          </span>
                                        </BootstrapTooltip>
                                      ))}
                                  </Menu>
                                </>
                              </Grid>
                            )}
                          </Grid>

                          <Virtuoso
                            data={upcomingPosts?.results}
                            endReached={fetchMoreUpcomingData}
                            itemContent={(index) => (
                              <div className="scrollLeft">
                                <div key={index}>
                                  <AllPostsCard
                                    item={upcomingPosts?.results[index]}
                                    getAllposts={getAllUpcomingPosts}
                                    handlePostChecked={
                                      handleUpcomingPostChecked
                                    }
                                    index={index}
                                    deletePostsArr={deletePosts}
                                    deletePermission={deletePermission}
                                    editPermission={editPermission}
                                    viewPermission={viewPermission}
                                    setPageNumber={setPageNumber}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <Box className={classes.locationbox}>
                            <NoPostFound text={t("upcomingPostsFoundText")} />
                          </Box>
                        </>
                      ))}

                    {selectedTab === 1 &&
                      (completedPosts?.results?.length > 0 ? (
                        <>
                          <Grid container className={classes.iconContainerPost}>
                            <Grid item xs={6} sm={6} md={8} lg={8}>
                              <Checkbox
                                checked={selectAllCompleted}
                                onChange={(e) =>
                                  handleChangeCompletedCheckButton(e)
                                }
                                //   inputProps={{ "aria-label": "controlled" }}
                              />
                              {t("Select_All")}
                            </Grid>
                            {deletePosts.length > 0 && (
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={4}
                                lg={4}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Card className={classes.iconCard}>
                                  {deletePermission && viewPermission ? (
                                    <BootstrapTooltip title={t("Delete")}>
                                      <IconButton
                                        className={classes.iconStylePost}
                                        aria-label="delete"
                                        size="large"
                                        // color="red"
                                        onClick={() => handleOpen()}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ) : (
                                    <BootstrapTooltip
                                      title={t("authorized_access")}
                                    >
                                      <span>
                                        <IconButton
                                          className={classes.iconStylePost}
                                          aria-label="delete"
                                          size="large"
                                          // color="red"
                                          disabled
                                          onClick={() => handleOpen()}
                                        >
                                          <DeleteIcon disabled />
                                        </IconButton>
                                      </span>
                                    </BootstrapTooltip>
                                  )}
                                </Card>
                              </Grid>
                            )}
                          </Grid>

                          <Virtuoso
                            data={completedPosts?.results}
                            endReached={fetchMoreCompletedData}
                            itemContent={(index) => (
                              <div className="scrollLeft">
                                <div>
                                  <AllPostsCard
                                    item={completedPosts?.results[index]}
                                    getAllposts={getAllCompletedPosts}
                                    handlePostChecked={
                                      handleCompletedPostChecked
                                    }
                                    index={index}
                                    deletePostsArr={deletePosts}
                                    deletePermission={deletePermission}
                                    editPermission={editPermission}
                                    viewPermission={viewPermission}
                                    setPageNumber={setPageNumber}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <Box className={classes.locationbox}>
                            <NoPostFound text={t("completedPostsFoundText")} />
                          </Box>
                        </>
                      ))}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        </>
      )}

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={
              deletePosts?.length > 0 &&
              (deletePosts?.length === 1
                ? t("delete_post")
                : t("Delete_Bulk_Posts"))
            }
            description={
              deletePosts?.length > 0 &&
              (deletePosts?.length === 1
                ? t("Delect_single_post_desc")
                : t("Delect_Bulk_post_desc"))
            }
            loading={deleteLoading}
            onConfirm={() => handleDeleteBulkPosts(deletePosts)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openPauseModel && (
        <Dialog
          open={openPauseModel}
          onClose={handleClosePauseModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={
              selectedPosts.resumed.length > 1 || deletePosts.length > 1
                ? t("pause_bulk_post")
                : t("pause_post")
            }
            description={
              selectedPosts.resumed.length > 1 || deletePosts.length > 1
                ? t("pause_bulk_post_text")
                : t("pause_post_text")
            }
            loading={deleteLoading}
            onConfirm={() => handlePosts(selectedPosts.resumed)}
            onCancel={handleClosePauseModel}
          />
        </Dialog>
      )}

      {openResumeModel && (
        <Dialog
          open={openResumeModel}
          onClose={handleCloseResumeModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={
              selectedPosts.paused.length > 1 || deletePosts.length > 1
                ? t("resume_bulk_post")
                : t("resume_post")
            }
            loading={deleteLoading}
            description={
              selectedPosts.paused.length > 1 || deletePosts.length > 1
                ? t("resume_bulk_post_text")
                : t("resume_post_text")
            }
            onConfirm={() => handlePosts(selectedPosts.paused)}
            onCancel={handleCloseResumeModel}
          />
        </Dialog>
      )}
      {openBulkEditModel && (
        <EditBulkPosts
          open={openBulkEditModel}
          close={() => setOpenBulkEditModel(false)}
          bulkEditData={bulkEditData}
          setPageNumber={setPageNumber}
          getAllUpcomingPosts={getAllUpcomingPosts}
        />
      )}
    </>
  );
};

export default AllPost;
