import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  tooltipClasses,
  Typography,
  Paper,
  TextField,
} from "@mui/material";
import { useStyles } from "./style";
import CommonButton from "../../CustomComponents/Buttons/CommonButton.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components/macro";
import Collapsible from "../../CustomComponents/Collapsible.js";
import { countriesData } from "../../../assets/countriesList/index.js";
import moment from "moment";
import DatePickerComponent from "../../../components/CustomComponents/DateRangePicker/RangePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const SmallCheckbox = styled(Checkbox)({
  // padding: "0px 12px 0px 0px",
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px", // Adjust the size of the icon inside the checkbox
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FilterModal = (props) => {
  const initialState = {
    groups: [],
    group: [],
    locations: [],
    location: [],
    timePeriod: "All",
    time: { id: 0, name: "All", value: "All" },
    starRatings: [],
    rate: [],
    sources: [],
    reviewSource: [],
    actions: 2,
    reviewedBy: "",
    tags: [],
  };

  const navigate = useNavigate();
  const classes = useStyles();
  const today = new Date();
  const previousYear = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );

  const { t } = useTranslation();
  const {
    open,
    close,
    groups,
    tags,
    sources,
    locations,
    setFilterObj,
    setCallApi,
    modalFilters,
    modalFiltersDate,
    setModalFilters,
    setModalFiltersDate,
    checked,
    setChecked,
    defaultSection,
  } = props;

  const [anchorBulk, setAnchorBulk] = useState(null);
  const [isSorted, setIsSorted] = useState("");
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [checkedLocation, setCheckedLocation] = useState(checked);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [section, setSection] = useState(
    defaultSection ? defaultSection : "locations"
  );
  const [filters, setFilters] = useState(modalFilters);
  const [filteredDate, setFilteredDate] = useState(modalFiltersDate);

  const [filtersSelectAll, setFiltersSelectAll] = useState({
    starRatings: false,
    sources: false,
    tags: false,
    group: false,
  });

  const timePeriod = [
    { id: 0, name: "All", value: "All" },
    { id: 1, name: "today", value: "Today" },
    { id: 2, name: "yesterday", value: "Yesterday" },
    { id: 3, name: "2days", value: "2days" },
    { id: 4, name: "7days", value: "7days" },
    { id: 5, name: "week", value: "week" },
    { id: 6, name: "14days", value: "14days" },
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 9, name: "60days", value: "60days" },
    { id: 10, name: "90days", value: "90days" },
    { id: 11, name: "6months", value: "6months" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  const starRatings = [
    { id: 1, name: "1_star", value: 1 },
    { id: 2, name: "2_star", value: 2 },
    { id: 3, name: "3_star", value: 3 },
    { id: 4, name: "4_star", value: 4 },
    { id: 5, name: "5_star", value: 5 },
  ];

  const actions = [
    { id: 1, name: "Haven't_Replied", value: 1 },
    { id: 2, name: "Replied", value: 0 },
    { id: 3, name: "Drafted_Action", value: "true" },
    { id: 4, name: "Alll", value: 2 },
  ];

  const handleClose = () => {
    setAnchorBulk(null);
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  // const resetStates = (status) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     groups: initialState.groups,
  //     locations: initialState.locations,
  //   }));
  //   setExpandedPanels([]);
  //   setCheckedLocation(status);
  // };

  const clearFilters = () => {
    setFilteredDate({ startDate: "", endDate: "" });
    setModalFiltersDate({ startDate: "", endDate: "" });
    setFilters(initialState);
    setCheckedLocation(false);
    setExpandedPanels([]);
    setIsSorted("");
    setFiltersSelectAll({
      starRatings: false,
      sources: false,
      tags: false,
      group: false,
    });
  };

  const getCountryWiseLocations = (locationsArray) => {
    return countriesData
      .map((country) => {
        const locations = locationsArray
          .filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          .map((location) => location);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
        };
      })
      .filter((country) => country.locationsArray.length > 0);
  };

  const handleExpandCountry = (arr) => {
    const locationsArray = locations.results.filter((location) =>
      arr.includes(location.id)
    );
    const countryNames = getCountryWiseLocations(locationsArray).map(
      (item) => item.countryName
    );
    countryNames.push("groups");
    setExpandedPanels(countryNames);
  };

  const handleChangeAutoSelect = (event) => {
    setCheckedLocation(event);
    if (filters.group.length > 0) {
      if (event) {
        let locationIds = filters.group[0].groups.map(
          (item) => item.locationId
        );
        let newLocations = locations.results.filter((element) =>
          locationIds.includes(element.id)
        );
        let mergedLocations = [...newLocations, ...filters.location].reduce(
          (acc, current) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        let mergedLocationIds = mergedLocations.map((item) => item.id);
        setFilters((prevFilters) => ({
          ...prevFilters,
          locations: mergedLocationIds,
          location: mergedLocations,
        }));
        handleExpandCountry(locationIds);
      } else {
        let locationIds = filters.group[0].groups.map(
          (item) => item.locationId
        );
        const filtered = filters.location.filter(
          (element) => !locationIds.includes(element.id)
        );
        const filteredIds = filtered.map((item) => item.id);
        setFilters((prevFilters) => ({
          ...prevFilters,
          locations: filteredIds,
          location: filtered,
        }));
      }
    }
  };

  const handleChangeAllCountryLocations = (e, locationsArr, countryName) => {
    if (e.target.checked) {
      let arr = [...filters.location];
      let mergedArr = arr.concat(locationsArr);
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: mergedArr.map((item) => item.id),
        location: mergedArr,
      }));
    } else {
      const filtered = filters.location?.filter(
        (item) => item?.countryCode !== countryName
      );
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: filtered
          .filter((item) => item?.countryCode !== countryName)
          .map((item) => item.id),
        location: filtered,
      }));
    }
  };

  const handleCheckForAllLocations = (countryLocations, selectedLocations) => {
    const array2IdSet = new Set(selectedLocations.map((obj) => obj.id));
    return countryLocations.every((obj) => array2IdSet.has(obj.id));
  };

  const handleExpandAllPanels = () => {
    const arr = [...expandedPanels];
    getCountryWiseLocations(locations?.results).map((item) =>
      arr.push(item?.countryName)
    );
    const uniqueArray = [...new Set(arr)];
    setExpandedPanels(uniqueArray);
    setExpandAllPanels(false);
  };

  const handleCollapseAllPanelsPanels = () => {
    const filtered = expandedPanels?.filter((item) => item === "groups");
    setExpandedPanels(filtered);
    setExpandAllPanels(true);
  };

  const handleChangeSelectAllLocations = (e) => {
    if (e.target.checked) {
      let locationIds = locations?.results?.map((item) => item.id);
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: locationIds,
        location: locations?.results,
      }));
      handleExpandAllPanels();
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        locations: [],
        location: [],
      }));
      handleCollapseAllPanelsPanels();
    }
  };

  const handleCheckedCountries = (arr) => {
    let locationIds = arr.map((item) => item.id);
    let newSet = new Set(filters.locations);
    return locationIds.some((element) => newSet.has(element));
  };

  const handleSelectAllSources = (e) => {
    if (e.target.checked) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        sources: true,
      }));
      let sourceIds = sources?.results?.map((item) => item.id);
      setFilters((prevFilters) => ({
        ...prevFilters,
        sources: sourceIds,
        reviewSource: sources?.results,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        sources: false,
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        sources: [],
        reviewSource: [],
      }));
    }
  };

  const handleSelectAllTags = (e) => {
    if (e.target.checked) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        tags: true,
      }));
      let tagsId = tags?.results?.map((item) => item.id);
      setFilters((prevFilters) => ({
        ...prevFilters,
        tags: tagsId,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        tags: false,
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        tags: [],
      }));
    }
  };

  const handleSelectAllRating = (e) => {
    if (e.target.checked) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        starRatings: true,
      }));
      let starRatingId = starRatings.map((item) => item.value);
      setFilters((prevFilters) => ({
        ...prevFilters,
        starRatings: starRatingId,
        rate: starRatings,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        starRatings: false,
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        starRatings: [],
        rate: [],
      }));
    }
  };

  const handleSelectAllGroup = (e) => {
    if (e.target.checked) {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        group: true,
      }));
      let groupIds = groups?.rows.map((item) => item.id);
      setFilters((prevFilters) => ({
        ...prevFilters,
        groups: groupIds,
        group: groups?.rows,
      }));
    } else {
      setFiltersSelectAll((prevFiltersSelectAll) => ({
        ...prevFiltersSelectAll,
        group: false,
      }));
      setFilters((prevFilters) => ({
        ...prevFilters,
        groups: [],
        group: [],
      }));
    }
  };

  const handleSorted = (arr, ele) => {
    if (isSorted === "asc") {
      const sortedArr = arr.sort((a, b) => a[ele].localeCompare(b[ele]));
      return sortedArr;
    } else if (isSorted === "desc") {
      const sortedArr = arr.sort((a, b) => b[ele].localeCompare(a[ele]));
      return sortedArr;
    } else {
      return arr;
    }
  };

  const handleChangeTime = (value) => {
    const handleFilterDate = (s, e) => {
      let start = moment()
        .subtract(s.amount, s.unit)
        .endOf(s.key)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .subtract(e.amount, e.unit)
        .startOf(e.key)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: end, endDate: start });
      setModalFiltersDate({ startDate: end, endDate: start });
      return `${end},${start}`;
    };
    if (value === "All") {
      setFilteredDate({ startDate: "", endDate: "" });
      setModalFiltersDate({ startDate: "", endDate: "" });
      return "";
    } else if (value === "Today") {
      let start = moment()
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .endOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      let str = `${start},${end}`;
      return `${str}`;
    } else if (value === "Yesterday") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 1, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "2days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 2, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "7days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 7, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "14days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 14, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "30days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 30, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "60days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 60, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "90days") {
      let start = { amount: 1, unit: "d", key: "day" };
      let end = { amount: 90, unit: "d", key: "day" };
      return `${handleFilterDate(start, end)}`;
    } else if (value === "week") {
      let today = moment();
      let mondayOfPreviousWeek = today
        .clone()
        .subtract(7, "days")
        .startOf("isoWeek")
        .toDate();
      let sundayOfPreviousWeek = today
        .clone()
        .subtract(7, "days")
        .endOf("isoWeek")
        .toDate();

      let start = moment(mondayOfPreviousWeek)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(sundayOfPreviousWeek)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;
      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      return `${str}`;
    } else if (value === "month") {
      let today = moment();

      let firstDayOfPreviousMonth = today
        .clone()
        .subtract(1, "month")
        .startOf("month")
        .toDate();
      let endDayOfPreviousMonth = today
        .clone()
        .subtract(1, "month")
        .endOf("month")
        .toDate();
      let start = moment(firstDayOfPreviousMonth)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDayOfPreviousMonth)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let str = `${start},${end}`;
      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      return `${str}`;
    } else if (value === "6months") {
      let today = moment();
      let sixMonthsAgo = today.clone().subtract(6, "months").startOf("month");
      let startOfPrevious6Months = sixMonthsAgo
        .clone()
        .subtract(1, "month")
        .startOf("month");
      let endOfPrevious6Months = today
        .clone()
        .subtract(1, "month")
        .endOf("month");

      let startDateOfPrevious6Months = sixMonthsAgo.toDate();
      let endDateOfPrevious6Months = endOfPrevious6Months.toDate();

      let start = moment(startDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;

      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      return `${str}`;
    } else if (value === "12months") {
      let today = moment();

      let twelveMonthsAgo = today
        .clone()
        .subtract(12, "months")
        .startOf("month");

      let startOfPrevious12Months = twelveMonthsAgo
        .clone()
        .subtract(1, "month")
        .startOf("month");
      let endOfPrevious12Months = today
        .clone()
        .subtract(1, "month")
        .endOf("month");

      let startDateOfPrevious12Months = twelveMonthsAgo.toDate();
      let endDateOfPrevious6Months = endOfPrevious12Months.toDate();

      let start = moment(startDateOfPrevious12Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment(endDateOfPrevious6Months)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      let str = `${start},${end}`;

      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      return `${str}`;
    } else if (value === "custom") {
      let start = moment(previousYear)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let end = moment()
        .endOf("day")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      setFilteredDate({ startDate: start, endDate: end });
      setModalFiltersDate({ startDate: start, endDate: end });
      let str = `${start},${end}`;
      return `${str}`;
    }
  };

  useEffect(() => {
    if (checkedLocation) {
      let group = groups?.rows?.filter((item) => item.id === filters.groups);
      let groupLocationIds = group[0]?.groups.map((item) => item.locationId);
      if (groupLocationIds !== undefined) {
        if (
          groupLocationIds.every((element) =>
            filters.locations.includes(element)
          ) === false
        ) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            groups: initialState.groups,
          }));
        }
      }
    }
  }, [filters]);

  useEffect(() => {
    if (modalFilters.groups.length > 0) {
      setExpandedPanels((prev) => [...prev, "groups"]);
    }

    if (modalFilters.locations.length > 0) {
      const expandedLocations = getCountryWiseLocations(modalFilters.location);
      const expandedLocationsNames = expandedLocations.map(
        (location) => location.countryName
      );
      setExpandedPanels((prev) => [...prev, ...expandedLocationsNames]);
    }

    if (modalFilters.sources.length > 0) {
      setExpandedPanels((prev) => [...prev, "sources"]);
    }

    if (modalFilters.starRatings.length > 0) {
      setExpandedPanels((prev) => [...prev, "starRatings"]);
    }

    if (modalFilters.timePeriod !== "") {
      setExpandedPanels((prev) => [...prev, "timePeriod"]);
    }

    if (modalFilters.reviewedBy !== "") {
      setExpandedPanels((prev) => [...prev, "reviewedBy"]);
    }
  }, [filters]);

  useEffect(() => {
    if (sources?.results) {
      if (sources?.results?.length !== filters.sources.length) {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          sources: false,
        }));
      } else {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          sources: true,
        }));
      }
    }
    if (tags?.results) {
      if (tags?.results?.length !== filters.tags.length) {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          tags: false,
        }));
      } else {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          tags: true,
        }));
      }
    }
    if (starRatings) {
      if (starRatings?.length !== filters.starRatings.length) {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          starRatings: false,
        }));
      } else {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          starRatings: true,
        }));
      }
    }
    if (groups?.rows) {
      if (groups?.rows?.length !== filters.groups.length) {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          group: false,
        }));
      } else {
        setFiltersSelectAll((prevFiltersSelectAll) => ({
          ...prevFiltersSelectAll,
          group: true,
        }));
      }
    }
  }, [filters]);

  const handleSubmit = () => {
    let data = {};
    if (filters.groups.length > 0) {
      data["groupId"] = filters.groups.join();
    }

    if (filters.locations.length > 0) {
      data["filters[locationId][$equals]"] = filters.locations.join(",");
    }

    if (filteredDate.startDate !== "" && filteredDate.endDate !== "") {
      data[
        "filters[date][$between]"
      ] = `${filteredDate.startDate},${filteredDate.endDate}`;
    }

    if (filters.sources.length > 0) {
      data["filters[thirdPartyReviewSourcesId][$equals]"] =
        filters.sources.join(",");
    }

    if (filters.starRatings.length > 0) {
      data["filters[ratingValue][$equals]"] = filters.starRatings.join(",");
    }

    if (filters.reviewedBy !== "") {
      data["search"] = filters.reviewedBy.trim();
    }

    if (filters.tags.length > 0) {
      data["filters[reviewTags][tagId][$equals]"] = filters.tags.join(",");
    }

    if (filters.actions !== 2) {
      if (filters.actions === "true") {
        data["filters[isDraft][$equals]"] = filters.actions;
      } else {
        data["haventReplied"] = filters.actions;
      }
    }

    setChecked(checkedLocation);
    setModalFilters(filters);
    setModalFiltersDate(filteredDate);
    setFilterObj(data);
    setCallApi(true);
    close(false);
  };

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };

  return (
    <Modal open={open}>
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className={classes.styleMainModelContainer}>
            <Grid
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("Filters")}
              </Typography>

              <IconButton
                autoFocus
                onClick={() => close(false)}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            <Grid className={classes.contentContainer}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Grid sx={{ display: "flex", gap: "10px" }}>
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("locations")}
                    label={t("TaskLocation")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "locations" ? "false" : "true"}
                  />
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("others")}
                    label={t("others")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "others" ? "false" : "true"}
                  />
                </Grid>
                <Grid>
                  <>
                    <CommonButton
                      type="submit"
                      isLoading={false}
                      disabled={false}
                      onSubmit={handleClickMenu}
                      label={
                        isSorted === "asc"
                          ? t("sortA-Z")
                          : isSorted === "desc"
                          ? t("sortZ-A")
                          : t("Sort_By")
                      }
                      icon={<KeyboardArrowDownIcon />}
                      leftMargin={true}
                      customHeight={true}
                      displayWhite={"true"}
                      iconLocation={true}
                      aria-owns={anchorBulk ? "simple-menu" : undefined}
                      aria-haspopup="true"
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorBulk}
                      open={Boolean(anchorBulk)}
                      onClose={handleClose}
                      PaperProps={{
                        sx: {
                          background: "#EBEBEC",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("asc");
                          handleClose();
                        }}
                      >
                        {t("sortA-Z")}
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          lineHeight: "20px",
                          cursor: "pointer",
                          "&:hover": {
                            background: "#DBDBDC",
                          },
                        }}
                        onClick={(e) => {
                          setIsSorted("desc");
                          handleClose();
                        }}
                      >
                        {t("sortZ-A")}
                      </MenuItem>
                    </Menu>
                  </>
                </Grid>
              </Grid>
              {section === "locations" && (
                <>
                  <Grid>
                    <Collapsible
                      title={t("group_access")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("groups")}
                      id={"groups"}
                      countryNameStyling={
                        filters.groups.length > 0 ? true : false
                      }
                      handleSelectAllGroups={handleSelectAllGroup}
                      selectAllGroups={filtersSelectAll.group}
                      displayGroupSelectAll={
                        groups?.rows?.length > 0 ? true : false
                      }
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {groups !== null && groups?.rows?.length > 0 ? (
                            handleSorted(groups?.rows, "name")?.map(
                              (item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.groups.includes(item.id)
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    filters.groups.includes(item.id)
                                      ? setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          groups: prevFilters.groups.filter(
                                            (i) => i !== item.id
                                          ),
                                          group: prevFilters.group.filter(
                                            (i) => i !== item
                                          ),
                                        }))
                                      : setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          groups: [
                                            ...prevFilters.groups,
                                            item.id,
                                          ],
                                          group: [...prevFilters.group, item],
                                        }));
                                    // const ids = item.groups.map(
                                    //   (group) => group.locationId
                                    // );
                                    // checkedLocation &&
                                    //   !expandedPanels.includes("locations") &&
                                    //   setExpandedPanels([
                                    //     ...expandedPanels,
                                    //     "locations",
                                    //   ]);
                                    // if (checkedLocation) {
                                    //   if (filters.groups === item.id) {
                                    //     setFilters((prevFilters) => ({
                                    //       ...prevFilters,
                                    //       groups: 0,
                                    //       group: [],
                                    //       locations: initialState.locations,
                                    //       location: [],
                                    //     }));
                                    //   } else {
                                    //     handleExpandCountry(ids);
                                    //     setFilters((prevFilters) => ({
                                    //       ...prevFilters,
                                    //       groups: item !== null ? item.id : 0,
                                    //       group: item !== null ? [item] : [],
                                    //       locations: ids,
                                    //       location: locations?.results?.filter(
                                    //         (loc) => ids.includes(loc.id)
                                    //       ),
                                    //     }));
                                    //   }
                                    // } else {
                                    //   if (filters.groups === item.id) {
                                    //     setFilters((prevFilters) => ({
                                    //       ...prevFilters,
                                    //       groups: 0,
                                    //       group: [],
                                    //     }));
                                    //   } else {
                                    //     setFilters((prevFilters) => ({
                                    //       ...prevFilters,
                                    //       groups: item !== null ? item.id : 0,
                                    //       group: item !== null ? [item] : [],
                                    //     }));
                                    //   }
                                    // }
                                  }}
                                >
                                  <Checkbox
                                    checked={filters.groups.includes(item.id)}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Item>
                              )
                            )
                          ) : (
                            <Grid
                              container
                              sx={{
                                height: "200px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid item sm={8} md={8} lg={8}>
                                <Typography
                                  sx={{
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    color: "#1b2430",
                                    marginTop: "30px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("No_Groups_Found")}
                                </Typography>
                                <Typography
                                  sx={{
                                    lineHeight: "24px",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#1B2430",

                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("create_groups_text")}
                                </Typography>
                                <CommonButton
                                  onSubmit={handleNavigationToGroups}
                                  customHeight={true}
                                  label={t("create_groups")}
                                  icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                />
                                {/* {locationAddPermission &&
                                locationViewPermission ? (
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <CommonButton
                                        disabled={true}
                                        onSubmit={handleNavigationToGroups}
                                        customHeight={true}
                                        label={t("create_groups")}
                                        icon={
                                          <AddIcon sx={{ color: "#FFFF" }} />
                                        }
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )} */}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  {/* <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      checked={checkedLocation}
                      onChange={(e) => handleChangeAutoSelect(e.target.checked)}
                      size="small"
                    />
                    <Typography sx={{ fontSize: "12px" }}>
                      {t("auto-select-checkbox-group")}
                    </Typography>
                  </Grid> */}
                  <Grid>
                    <Grid
                      container
                      sx={{
                        padding: "8px",
                        border: "1px solid #EBEBEC",
                        borderRadius: "8px",
                      }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 600, fontSize: "1.125rem" }}
                          >
                            {t("Locations")}
                          </Typography>

                          <Grid>
                            <SmallCheckbox
                              name="selectAll"
                              color="primary"
                              checked={
                                locations !== null &&
                                locations?.results?.length ===
                                  filters?.location?.length
                              }
                              onChange={(e) =>
                                handleChangeSelectAllLocations(e)
                              }
                              sx={{ marginRight: "4px" }}
                            />
                            {t("All_locationss")}
                            {expandAllPanels ? (
                              <IconButton onClick={handleExpandAllPanels}>
                                <ExpandMoreIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={handleCollapseAllPanelsPanels}
                              >
                                <ExpandLessIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ gap: "10px" }}>
                        {locations !== null &&
                          locations?.results?.length > 0 &&
                          handleSorted(
                            getCountryWiseLocations(locations?.results),
                            "countryName"
                          ).map((e, i) => (
                            <Grid item key={i} sx={{ width: "100%" }}>
                              <Collapsible
                                title={e.countryName}
                                handleChange={handleExpandPanel}
                                expanded={expandedPanels.includes(
                                  e.countryName
                                )}
                                expandedPanels={expandedPanels}
                                setExpandedPanels={setExpandedPanels}
                                id={e.countryName}
                                countryNameStyling={handleCheckedCountries(
                                  e.locationsArray
                                )}
                                displaySelectAll={true}
                                itemData={e}
                                handleCheckForAllLocations={
                                  handleCheckForAllLocations
                                }
                                handleChangeAllCountryLocations={
                                  handleChangeAllCountryLocations
                                }
                                modellocations={filters.location}
                                countriesArr={e}
                                ComponentToRender={
                                  <Grid container sx={{ gap: "10px" }}>
                                    {handleSorted(
                                      e.locationsArray,
                                      "internalName"
                                    ).map((item, i) => (
                                      <Item
                                        key={i}
                                        sx={{
                                          border: filters.locations.includes(
                                            item.id
                                          )
                                            ? "1px solid #06BDFF"
                                            : "1px solid #EBEBEC",
                                        }}
                                        onClick={() => {
                                          filters.locations.includes(item.id)
                                            ? setFilters((prevFilters) => ({
                                                ...prevFilters,
                                                locations:
                                                  prevFilters.locations.filter(
                                                    (i) => i !== item.id
                                                  ),
                                                location:
                                                  prevFilters.location.filter(
                                                    (i) => i !== item
                                                  ),
                                              }))
                                            : setFilters((prevFilters) => ({
                                                ...prevFilters,
                                                locations: [
                                                  ...prevFilters.locations,
                                                  item.id,
                                                ],
                                                location: [
                                                  ...prevFilters.location,
                                                  item,
                                                ],
                                              }));
                                        }}
                                      >
                                        <Checkbox
                                          checked={filters.locations.includes(
                                            item.id
                                          )}
                                          size="small"
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: "#1B2430",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item.internalName}
                                        </Typography>
                                      </Item>
                                    ))}
                                  </Grid>
                                }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {section === "others" && (
                <>
                  <Grid>
                    <Collapsible
                      title={t("review_search")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("reviewedBy")}
                      countryNameStyling={filters.reviewedBy !== ""}
                      id={"reviewedBy"}
                      ComponentToRender={
                        <TextField
                          placeholder={t("search")}
                          fullWidth
                          id="outlined-name"
                          value={filters.reviewedBy}
                          style={{ height: "52px" }}
                          sx={{
                            background: "#FFF",
                            padding: "0px",
                            height: "52px",
                          }}
                          onChange={(e) => {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              reviewedBy: e.target.value,
                            }));
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Sources")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("sources")}
                      id={"sources"}
                      countryNameStyling={filters.sources.length > 0}
                      handleSelectAllGroups={handleSelectAllSources}
                      selectAllGroups={filtersSelectAll.sources}
                      displayGroupSelectAll={true}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {sources !== null &&
                            sources?.results?.length > 0 &&
                            handleSorted(sources?.results, "name")?.map(
                              (item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.sources.includes(item.id)
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    filters.sources.includes(item.id)
                                      ? setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          sources: prevFilters.sources.filter(
                                            (i) => i !== item.id
                                          ),
                                          reviewSource: [
                                            prevFilters.reviewSource.filter(
                                              (i) => i.id !== item.id
                                            ),
                                          ],
                                        }))
                                      : setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          sources: [
                                            ...prevFilters.sources,
                                            item.id,
                                          ],
                                          reviewSource: [
                                            ...prevFilters.reviewSource,
                                            item,
                                          ],
                                        }));
                                  }}
                                >
                                  <Checkbox
                                    checked={filters.sources.includes(item.id)}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.name.replaceAll("_", " ")}
                                  </Typography>
                                </Item>
                              )
                            )}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Tags")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("tags")}
                      id={"tags"}
                      countryNameStyling={filters.tags.length > 0}
                      handleSelectAllGroups={handleSelectAllTags}
                      selectAllGroups={
                        filters.tags.length > 0 && filtersSelectAll.tags
                      }
                      displayGroupSelectAll={true}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {tags !== null && tags?.results?.length > 0 ? (
                            handleSorted(tags?.results, "name")?.map(
                              (item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border: filters.tags.includes(item.id)
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                  }}
                                  onClick={() =>
                                    filters.tags.includes(item.id)
                                      ? setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          tags: prevFilters.tags.filter(
                                            (i) => i !== item.id
                                          ),
                                        }))
                                      : setFilters((prevFilters) => ({
                                          ...prevFilters,
                                          tags: [...prevFilters.tags, item.id],
                                        }))
                                  }
                                >
                                  <Checkbox
                                    checked={filters.tags.includes(item.id)}
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.name.replaceAll("_", " ")}
                                  </Typography>
                                </Item>
                              )
                            )
                          ) : (
                            <Grid
                              container
                              sx={{
                                height: "200px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid item sm={8} md={8} lg={8}>
                                <Typography
                                  sx={{
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    color: "#1b2430",
                                    marginTop: "30px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("No_Tags_Found")}
                                </Typography>
                                {/* <Typography
                                  sx={{
                                    lineHeight: "24px",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#1B2430",

                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("create_groups_text")}
                                </Typography>
                                <CommonButton
                                  onSubmit={handleNavigationToGroups}
                                  customHeight={true}
                                  label={t("create_groups")}
                                  icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                /> */}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Time_Period")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("timePeriod")}
                      id={"timePeriod"}
                      countryNameStyling={filters.timePeriod !== ""}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {timePeriod.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border:
                                  filters.timePeriod === item.value
                                    ? "1px solid #06BDFF"
                                    : "1px solid #EBEBEC",
                              }}
                              onClick={() => {
                                handleChangeTime(item.value);
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  timePeriod: item.value,
                                  time: item,
                                }));
                              }}
                            >
                              <Checkbox
                                checked={
                                  filters.timePeriod === item.value
                                    ? true
                                    : false
                                }
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {t(item.name)}
                              </Typography>
                            </Item>
                          ))}
                          {filters.timePeriod === "custom" && (
                            <Grid
                              container
                              spacing={2}
                              lg={12}
                              sx={{ display: "flex", marginBottom: "8px" }}
                            >
                              <DatePickerComponent
                                handleChangeStartDate={(e) => {
                                  setFilteredDate((prevFilteredDate) => ({
                                    ...prevFilteredDate,
                                    startDate: moment(e)
                                      .startOf("day")
                                      .utc()
                                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                  }));
                                  setModalFiltersDate((prevFilteredDate) => ({
                                    ...prevFilteredDate,
                                    startDate: moment(e)
                                      .startOf("day")
                                      .utc()
                                      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                  }));
                                }}
                                handleChangeEndDate={(e) => {
                                  setFilteredDate((prevFilteredDate) => ({
                                    ...prevFilteredDate,
                                    endDate:
                                      e !== "" && e !== null
                                        ? moment(e)
                                            .endOf("day")
                                            .utc()
                                            .format(
                                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                            )
                                        : "",
                                  }));
                                  setModalFiltersDate((prevFilteredDate) => ({
                                    ...prevFilteredDate,
                                    endDate:
                                      e !== "" && e !== null
                                        ? moment(e)
                                            .endOf("day")
                                            .utc()
                                            .format(
                                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                            )
                                        : "",
                                  }));
                                }}
                                startDate={filteredDate.startDate}
                                endDate={filteredDate.endDate}
                                fromFilters={true}
                                rangeValue={"between"}
                              />
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("ratings")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("starRatings")}
                      id={"starRatings"}
                      countryNameStyling={filters.starRatings.length > 0}
                      handleSelectAllGroups={handleSelectAllRating}
                      selectAllGroups={filtersSelectAll.starRatings}
                      displayGroupSelectAll={true}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {starRatings.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border: filters.starRatings.includes(item.value)
                                  ? "1px solid #06BDFF"
                                  : "1px solid #EBEBEC",
                              }}
                              onClick={() =>
                                filters.starRatings.includes(item.value)
                                  ? setFilters((prevFilters) => ({
                                      ...prevFilters,
                                      starRatings:
                                        prevFilters.starRatings.filter(
                                          (i) => i !== item.value
                                        ),
                                      rate: prevFilters.rate.filter(
                                        (i) => i.value !== item.value
                                      ),
                                    }))
                                  : setFilters((prevFilters) => ({
                                      ...prevFilters,
                                      starRatings: [
                                        ...prevFilters.starRatings,
                                        item.value,
                                      ],
                                      rate: [...prevFilters.rate, item],
                                    }))
                              }
                            >
                              <Checkbox
                                checked={filters.starRatings.includes(
                                  item.value
                                )}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {t(item.name)}
                              </Typography>
                            </Item>
                          ))}
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid>
                    <Collapsible
                      title={t("Action")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("actions")}
                      countryNameStyling={filters.actions !== ""}
                      id={"actions"}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {actions.map((item, i) => (
                            <Item
                              key={i}
                              sx={{
                                border:
                                  filters.actions === item.value
                                    ? "1px solid #06BDFF"
                                    : "1px solid #EBEBEC",
                              }}
                              onClick={() =>
                                setFilters((prevFilters) => ({
                                  ...prevFilters,
                                  actions: item.value,
                                }))
                              }
                            >
                              <Checkbox
                                checked={filters.actions === item.value}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#1B2430",
                                }}
                              >
                                {t(item.name)}
                              </Typography>
                            </Item>
                          ))}
                        </Grid>
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                py: "10px",
              }}
            >
              <CommonButton
                type="submit"
                isLoading={false}
                disabled={false}
                onSubmit={clearFilters}
                label={t("Clear_Filters")}
                leftMargin={true}
                customHeight={true}
                displayWhite={"true"}
              />
              <CommonButton
                type="submit"
                isLoading={false}
                disabled={false}
                onSubmit={handleSubmit}
                label={t("Apply_filters")}
                leftMargin={true}
                customHeight={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FilterModal;
