import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  divider: {
    padding: "1.25rem",
    paddingBottom: "1rem",
  },
  dialogBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  dialogBoxHeading: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
  },
  dialogBoxText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  toast: {
    width: "100%",
    display: "flex",
    padding: "0 1.25rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#FFF1D7",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    //   gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#FAB431",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
  },
  addRuleMainBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    margin: "0 1rem",
    padding: "4px",
  },
  SupervisedData: {
    width: "100%",
    display: "contents",
  },
  addRuleLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 4px",
    padding: "1rem",
    paddingTop: 0,
  },

  addRuleLocationTitle: {
    margin: "0 1rem",
    padding: "2rem",
    borderRadius: "10px",
    backgroundColor: "#E0E0E0",
  },
  Condition: {
    backgroundColor: "#E0E0E0",
    padding: "2rem",
    margin: "0 1rem",
    borderRadius: "10px",
  },
  Reviews: {
    marginBottom: "1rem",
    marginLeft: "3rem",
  },
  Rating: {
    marginBottom: "1rem",
  },
  Auto_Reply: {
    marginTop: "2rem",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Auto_Reply_templates: {
    marginTop: "12px",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  mainContainer: {
    background: "#FDEDED",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  errorText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#ef5350",
    padding: "6px 0px",
  },
  errorIcon: {
    color: "#ef5350",
    marginTop: "5px",
  },
  sourceImage: {
    width: "40px",
    height: "40px",
  },
  sourceName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  subscribedText: {
    background: "#06BDFF",
    marginRight: "12px",
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  titleRule: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  editTitle: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  skullBox: {
    alignItem: "center",
    width: "100%",
    justifyContent: "center",
    paddingY: "8px",
    display: "flex",
    height: "40px",
  },
  unsuperText: {
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "1.125rem",
    justifyContent: "center",
    display: "flex",
    paddingBottom: "8px",
  },
  superText: {
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "1.125rem",
    justifyContent: "center",
    display: "flex",
    paddingBottom: "8px",
  },
  boxPerson: {
    alignItem: "center",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    paddingY: "8px",
    height: "40px",
  },
  unsuperTextDefault: {
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "1.125rem",
    justifyContent: "center",
    display: "flex",
    paddingBottom: "8px",
  },
  superTextDefault: {
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "16px",
    justifyContent: "center",
    display: "flex",
    paddingBottom: "8px",
  },
  extrasTooltip: {
    fontSize: "12px",
    lineHeight: "16px",
    color: "#495059",
    marginLeft: "5px",
    marginTop: "3px",
  },
  extrasTooltipIcon: {
    color: "#1B2430",
    width: "20px",
    height: "20px",
  },
}));
