import React, { useState, useEffect } from "react";
import { api } from "../../../../contexts/JWTContext";
import moment from "moment";
import Grid from "@mui/material/Grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useParams } from "react-router-dom";
import DraftsIcon from "@mui/icons-material/Drafts";
import { CircularProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/system";
import {
  Typography,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Menu,
  MenuItem,
  Modal,
  Divider,
  Select,
  FormControl,
  Autocomplete,
  Stack,
  Paper,
  Tooltip,
  tooltipClasses,
  Checkbox,
} from "@mui/material";
import GoogleLogo from "../../../../assets/icon-google.svg";
import TranslateIcon from "../../../../assets/Icons/translateIcon.svg";
import { ReactComponent as AILogo } from "../../../../assets/images/Ai.svg";
import { ReactComponent as DraftLogo } from "../../../../assets/images/DraftLogo.svg";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";
import { ReactComponent as ReviewAgentIcon } from "../../../../assets/images/ReviewAgent.svg";
import { ReactComponent as RepliedWithSupervised } from "../../../../assets/Icons/RepliesWithSupervisedRule.svg";
import { ReactComponent as PastReviewsJobWithTemplates } from "../../../../assets/Icons/PastReviewsJob.svg";
import { ReactComponent as ManuallyReplied } from "../../../../assets/Icons/ManuallyReplied.svg";
import { ReactComponent as AiIconForPastReviewsReplied } from "../../../../assets/Icons/AiBlack.svg";
import { ReactComponent as ThirdPartyReplied } from "../../../../assets/Icons/ThirdPartyReplied.svg";
import { ReactComponent as ReviewAgentFetched } from "../../../../assets/images/reviewAgentFetched.svg";

import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReviewsCard from "../../../../components/ReviewSource/ReviewsCard";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import CancelButton from "../../../../components/CustomComponents/Buttons/CancelButton";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import ClearIcon from "@mui/icons-material/Clear";
import { languagesData } from "../../../../assets/defaultLanguage";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../../components/Loaders/Loader";
import { useStyles } from "../Style/style";
import TaskModel from "../Components/Models/TaskModel";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import Highlight from "./Highlight";
import useDateFormat from "../../../../hooks/useDateFormat";
//import TranslateIcon from "@mui/icons-material/Translate";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const baseURL = process.env.REACT_APP_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "400px",
  maxHeight: "99vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const dummyText = ["google", "yelp", "data", "fakeReview", "Amstradem"];

function GoogleReviews({
  search,
  item,
  tags,
  refreshTags,
  getLocationGoogleReview,
  allTemplate,
  users,
  allReviewSource,
  locations,
  viewPermission,
  addPermission,
  editPermission,
  deletePermission,
  viewPermissionTasks,
  addPermissionTasks,
  editPermissionTasks,
  index,
  allData,
  setReviewsData,
  allReviewSourceForAgent,
  enabledReviewSources,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const params = useParams();
  const { id } = params;
  const { formatDate } = useDateFormat();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const userData = JSON.parse(localStorage.getItem("user"));
  const [loginUser, setLoginUser] = useState(userData);
  const [replyComment, setReplyComment] = useState("");
  const [showReplyComment, setShowReplyComment] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectReply, setSelectedReply] = useState();
  const [open, setOpen] = useState(false);
  const [displayDraft, setDisplayDraft] = useState(false);
  const [replyByOpenAi, setReplyByOpenAi] = useState(false);

  const [openTicketModel, setOpenTicketModel] = useState(false);
  const [description, setDescription] = useState(null);
  const [openTagsModel, setOpenTagsModel] = useState(false);
  const [selectedTags, setSelectedTags] = React.useState();
  const [statusData, setStatusData] = useState("open");
  const [isLoading, setIsLoading] = useState(false);
  //const [ContentCopyIcon, setContentCopyIcon] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [addedComment, setAddedComment] = useState(
    "Please check the following review and let me know your opinion"
  );
  const [language, setLanguage] = useState("");
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [selectedDueDate, setSelectedDueDate] = useState("7");
  const [selectedReviewItem, setSelectedReviewItem] = useState("");
  const [toggleInput, setToggleInput] = useState(false);
  const [statusRequired, setStatusRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  const [displayGooglePic, setDisplayGooglePic] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState("");
  const [loader, setLoader] = useState(false);
  const [displayText, setDisplayText] = useState(false);
  const [displayTextTwo, setDisplayTextTwo] = useState(false);
  const [replyRequired, setReplyRequired] = useState(false);
  const [reviewSource, setReviewSource] = useState(null);
  const [reviewLocation, setReviewLocation] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [translationLoading, setTranslationLoading] = useState(false);
  const [ruleExist, setRuleExist] = useState(true);

  useEffect(() => {
    if (allReviewSource?.length > 0) {
      const itemReviewSource = allReviewSource?.find(
        (data) => data?.id === item?.thirdPartyReviewSourcesId
      );

      setReviewSource(itemReviewSource);
    }
    if (locations?.length > 0) {
      const itemReviewLocation = locations?.find(
        (data) => data?.id === item?.locationId
      );

      setReviewLocation(itemReviewLocation);
    }
  }, [item, allReviewSource, locations, allData]);
  useEffect(() => {
    if (item?.hasOwnProperty("reviewReplyTranslated")) {
      //  setDisplayReplyComment(true);
      let tempArray = { ...allData };
      tempArray.results[index]["replyToEdit"] = item?.reviewReplyTranslated;
      setReviewsData(tempArray);
      //  setReplyComment(item?.reviewReplyTranslated);
    } else if (item?.reviewReply) {
      let tempArray = { ...allData };
      tempArray.results[index]["replyToEdit"] = item?.reviewReply;
      setReviewsData(tempArray);
      // setReplyComment(item?.reviewReply);
    }
  }, [item]);

  useEffect(() => {
    if (item?.reviewTags?.length > 0) {
      let tempArray = [];
      item?.reviewTags?.map((item) => {
        item?.tag && tempArray.push(item?.tag);
      });
      setSelectedTags(tempArray);
    } else {
      setSelectedTags([]);
    }
  }, [item?.reviewTags]);
  useEffect(() => {
    if (item?.hasOwnProperty("reviewReplyTranslated")) {
      let tempArray = { ...allData };
      tempArray.results[index]["replyToEdit"] = item?.reviewReplyTranslated;
      setReviewsData(tempArray);
      //  setReplyComment(item?.reviewReplyTranslated);
    } else if (item?.reviewReply) {
      let tempArray = { ...allData };
      tempArray.results[index]["replyToEdit"] = item?.reviewReply;
      setReviewsData(tempArray);
      // setReplyComment(item?.reviewReply);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("languageChange", handleUpdateUser);

    return () => {
      window.removeEventListener("languageChange", handleUpdateUser);
    };
  }, []);

  const handleUpdateUser = () => {
    const updatedUser = JSON.parse(localStorage.getItem("user"));
    setLoginUser(updatedUser);
    let tempArray = { ...allData };
    tempArray.results?.map((item) => {
      delete item["reviewReplyTranslated"];
      delete item["translatedText"];
    });
    tempArray.results[index]["replyToEdit"] = item?.reviewReply;
    // setReplyComment(item?.reviewReply);

    setReviewsData(tempArray);
  };
  const personalizedKeywords = [
    "reviewer",
    "starRating",
    "location",
    "first_name",
    "last_name",
    "business_email",
    "business_phone_number",
  ];

  const handleCheckEnabledForReply = (sourceId, locId) => {
    if (enabledReviewSources?.length > 0) {
      const filterteredItem = enabledReviewSources?.find(
        (sourceItem) =>
          sourceItem?.enableReviewAgent === true &&
          sourceItem?.sourceId === sourceId &&
          sourceItem?.locationId === locId
      );

      if (filterteredItem !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  };
  const personalizedKeywordsHandler = (
    text,
    reviewer,
    location,
    starRating,
    locationObj
  ) => {
    const name = reviewer?.split(" ");

    for (let index = 0; index < personalizedKeywords.length; index++) {
      if (personalizedKeywords[index] === "reviewer") {
        if (reviewer?.length > 0) {
          text = text.replace(/{{reviewer}}/g, reviewer);
        } else {
          text = text.replace(/{{reviewer}}/g, "");
        }
      } else if (personalizedKeywords[index] === "starRating") {
        if (starRating?.length > 0) {
          text = text.replace(/{{starRating}}/g, starRating);
        } else {
          text = text.replace(/{{starRating}}/g, "");
        }
      } else if (personalizedKeywords[index] === "location") {
        if (location?.length > 0) {
          text = text.replace(/{{location}}/g, location);
        } else {
          text = text.replace(/{{location}}/g, "");
        }
      } else if (personalizedKeywords[index] === "first_name") {
        if (name?.length > 0) {
          text = text.replace(/{{first_name}}/g, name[0]);
        } else {
          text = text.replace(/{{first_name}}/g, "");
        }
      } else if (personalizedKeywords[index] === "last_name") {
        if (name?.length > 0 > 0) {
          text = text.replace(/{{last_name}}/g, name[1]);
        } else {
          text = text.replace(/{{last_name}}/g, "");
        }
      } else if (personalizedKeywords[index] === "business_email") {
        if (locationObj?.businessEmail !== null) {
          text = text.replace(
            /{{business_email}}/g,
            locationObj?.businessEmail
          );
        } else {
          text = text.replace(/{{business_email}}/g, "");
        }
      } else if (personalizedKeywords[index] === "business_phone_number") {
        if (locationObj?.telephone !== null) {
          text = text.replace(
            /{{business_phone_number}}/g,
            locationObj?.telephone
          );
        } else {
          text = text.replace(/{{business_phone_number}}/g, "");
        }
      }
    }
    return text;
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedReviewItem(item);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const openTicketModelHandler = (item) => {
    /*   if (item?.task !== null) {
      setStatusData(item?.task?.status);
      setAddedComment(item?.task?.description);
      let tempUsersArray = [];
      item?.task?.taskUsers?.length > 0 &&
        item?.task?.taskUsers?.map((item) => {
          tempUsersArray.push(item?.user);
        });
      setSelectedUser(tempUsersArray);
    } */
    setSelectedReviewItem(item);
    setOpenTicketModel(true);
    setAnchorEl(null);
  };

  const closeTicketModelHanlder = () => {
    setOpenTicketModel(false);
    setAnchorEl(null);
  };

  const openTagsModelHanlder = () => {
    setOpenTagsModel(true);
    setAnchorEl(null);
  };
  const closeTagsModelHanlder = () => {
    setOpenTagsModel(false);
    setAnchorEl(null);
  };
  const handleChangeStatus = (event) => {
    setStatusData(event.target.value);
    // setStatusRequired(false);
  };
  const commentChangeHandler = (e) => {
    setAddedComment(e);
    if (e.length <= 11) {
      setDescriptionRequired(true);
    } else {
      setDescriptionRequired(false);
    }
    setDescription(e);
  };
  const handleChangeUser = (event) => {
    setSelectedUser(event);
    setUserRequired(false);
  };
  const postReplyHandler = async (reviewId) => {
    try {
      const data = {
        id: reviewId,
        locationId: item?.locationId,
        text: item?.replyToEdit,
        reviewId: item?.id,
        reviewReplyType: "manual",
        // isdrafted : "true",
      };
      if (item?.replyToEdit.length > 0) {
        setIsLoading(true);
        const res = await api.post(`/google/review`, data);
        if (res.status === 200) {
          toast.success("Replied in review successfully");
          let tempArray = { ...allData };
          tempArray.results[index]["reviewReply"] = item?.replyToEdit;
          tempArray.results[index]["reviewReplyType"] = "manual";

          setReviewsData(tempArray);

          setShowReplyComment(false);

          // setReplyByOpenAi(true);
          setIsLoading(false);
        } else {
          toast.error("Something went wrong");
        }
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);

      setIsLoading(false);
    }
  };
  const postReplyOtherHandler = async (reviewId) => {
    if (item?.replyToEdit?.length === 0) {
      setReplyRequired(true);
    }

    try {
      const data = {
        reviewReply: item?.replyToEdit,
        isDraft: true,
        id: reviewId,
      };

      if (item?.replyToEdit.length > 0) {
        setSaveLoading(true);
        setDisplayDraft(true);
        const res = await api.patch(`/review/${reviewId}`, data);
        if (res.status === 200) {
          if (item?.url !== null) {
            toast.success(t("Review_Saved_successfully"));
          } else {
            toast.success(t("Review_Saved_successfully_draft"));
          }

          let tempArray = { ...allData };
          if (res?.data?.data?.replyFetchedByReviewAgent === true) {
            tempArray.results[index]["replyFetchedByReviewAgent"] = true;
          }
          tempArray.results[index]["reviewReply"] = item?.replyToEdit;
          tempArray.results[index]["isDraft"] = true;

          console.log(tempArray, "tempArray");

          setReviewsData(tempArray);

          setShowReplyComment(false);
          setDisplayDraft(true);
          setLanguage("");
          setSelectedTemplate([]);
          /*   if (item?.url !== null) {
         
            setReplyByOpenAi(true);
          } else {
            setReplyByOpenAi(false);
          } */
          setSaveLoading(false);
          if (item?.url !== null) {
            if (
              handleCheckEnabledForReply(
                item?.thirdPartyReviewSourcesId,
                item?.locationId
              ) === false
            ) {
              window.open(item.url, "_blank");
            }
          }
        } else {
          toast.error("Something went wrong");
        }
        setSaveLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);

      setIsLoading(false);
    }
  };

  const deleteReply = async (reviewId) => {
    const data = {
      id: reviewId,
      locationId: item?.locationId,
      reviewId: item?.id,
    };

    try {
      setIsLoading(true);

      const res = await api.put(`/google/review`, data);

      if (res.status === 200) {
        toast.success("sucessfully deleted reply on review");
        setOpen(false);
        let tempArray = { ...allData };

        tempArray.results[index]["reviewReply"] = null;
        tempArray.results[index]["replyToEdit"] = "";
        //   setReplyComment("");

        setReviewsData(tempArray);
        setIsLoading(false);

        setIsLoading(false);
        refreshTags(item?.locationId);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setOpen(false);
      setIsLoading(false);
    }
  };

  const saveSelectedTags = async () => {
    try {
      setIsLoading(true);
      const res = await api.post(`tags/createReviewTags/${item?.id}`, {
        data: selectedTags,
      });
      if (res.status === 201) {
        closeTagsModelHanlder();
        refreshTags(item?.locationId);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getDetectedLanguage = async (callApi) => {
    if (item?.ratingText) {
      let splittedText = "";
      if (item?.ratingText && item?.ratingText.includes("(Original)\n")) {
        splittedText = item?.ratingText.split("(Original)\n")[1];
      } else if (
        item?.ratingText &&
        item?.ratingText.includes("(Translated by Google)")
      ) {
        splittedText = item?.ratingText.split("(Translated by Google)")[0];
      } else {
        splittedText = item?.ratingText;
      }
      try {
        setIsLoading(true);
        const res = await api.post(`/languge/detact`, {
          text: splittedText,
        });
        if (res.status === 200) {
          setDetectedLanguage(res?.data?.data?.detactLanguge);
          if (callApi === true) {
            handleReviewWithAi(res?.data?.data?.detactLanguge);
          }

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleTranslateText = async (text) => {
    setTranslationLoading(true);

    let splittedTextForLangDetect = "";
    if (item?.ratingText && item?.ratingText.includes("(Original)\n")) {
      splittedTextForLangDetect = item?.ratingText.split("(Original)\n")[1];
    } else if (
      item?.ratingText &&
      item?.ratingText.includes("(Translated by Google)")
    ) {
      splittedTextForLangDetect = item?.ratingText.split(
        "(Translated by Google)"
      )[0];
    } else {
      splittedTextForLangDetect = item?.ratingText;
    }
    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (detectdResponse?.data?.data?.detactLanguge !== i18nextLng) {
        let formattedText = text;
        if (text?.includes("(Original)")) {
          let splittedText = text.split("(Original)");
          formattedText = splittedText[1];
        }

        try {
          const res = await api.post(`/translation/mutipleText`, {
            texts: [formattedText],
            language: [i18nextLng],
          });
          if (res.status === 200) {
            if (res?.data?.data?.length > 0) {
              let str = `(${t("translated_text")} ${loginUser?.language}) ${
                res?.data?.data[0]?.text
              }`;
              let tempArray = { ...allData };
              tempArray.results[index]["translatedText"] = str;

              setReviewsData(tempArray);
              // item["translatedText"] = str;
            }
            setTranslationLoading(false);
            toast.success("Translated successfully");
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslationLoading(false);
        }
      } else {
        toast.info(`Already translated in ${loginUser?.language}`);
        setTranslationLoading(false);
      }
    }
  };

  const handleTranslateTextToOriginal = () => {
    let tempArray = { ...allData };

    delete tempArray.results[index]["reviewReplyTranslated"];
    tempArray.results[index]["replyToEdit"] = item?.reviewReply;
    // setReplyComment(item?.reviewReply);
    delete tempArray.results[index]["translatedText"];

    setReviewsData(tempArray);
  };

  const handleTranslateReviewReplyText = async (text) => {
    // setDisplayReplyComment(false);
    setTranslationLoading(true);

    let splittedTextForLangDetect = "";
    if (text && text.includes("(Original)\n")) {
      splittedTextForLangDetect = text.split("(Original)\n")[1];
    } else if (text && text.includes("(Translated by Google)")) {
      splittedTextForLangDetect = text.split("(Translated by Google)")[0];
    } else {
      splittedTextForLangDetect = text;
    }
    const detectdResponse = await api.post(`/languge/detact`, {
      text: splittedTextForLangDetect,
    });

    if (detectdResponse.status === 200) {
      if (detectdResponse?.data?.data?.detactLanguge !== i18nextLng) {
        let formattedText = text;
        if (text?.includes("(Original)")) {
          let splittedText = text.split("(Original)");
          formattedText = splittedText[1];
        }

        try {
          const res = await api.post(`/translation/mutipleText`, {
            texts: [formattedText],
            language: [i18nextLng],
          });
          if (res.status === 200) {
            if (res?.data?.data?.length > 0) {
              let str = `(Original) ${item?.reviewReply}\n (${t(
                "translated_text"
              )} ${loginUser?.language}) ${res?.data?.data[0]?.text}`;

              // setReplyComment(str);
              let tempArray = { ...allData };
              tempArray.results[index]["reviewReplyTranslated"] = str;
              tempArray.results[index]["replyToEdit"] = str;

              setReviewsData(tempArray);
              //  item["reviewReplyTranslated"] = str;
            }
            setTranslationLoading(false);
            {
              (item?.ratingText === null || item?.ratingText === "") &&
                toast.success("Translated successfully");
            }
          }
        } catch (error) {
          toast.error("Something went wrong");
          setTranslationLoading(false);
        }
      } else {
        {
          (item?.ratingText === null || item?.ratingText === "") &&
            toast.info(`Already translated in ${loginUser?.language}`);
        }
        setTranslationLoading(false);
      }
    }
  };

  function handleDisplayRatingText(text) {
    let splittedText = text.split("(Original)");

    let original = "";
    let translation = "";
    if (splittedText?.length > 0) {
      translation = !item?.translatedText && splittedText[0];
      original = "(Original)" + splittedText[1];
    }

    return {
      translation: translation,
      original: original,
    };
  }
  const getTranslatedText = async (lang) => {
    try {
      setLoader(true);
      const res = await api.post(`/languge/translate`, {
        text: item?.replyToEdit
          ? item?.replyToEdit
          : item?.reviewReply !== null
          ? item?.reviewReply
          : "",
        targetedLanguage: lang,
      });
      if (res.status === 200) {
        setLoader(false);
        setReplyRequired(false);
        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
          res?.data?.data?.translatedText,
          item?.reviewerTitle,
          reviewLocation?.internalName,
          item.starRating,
          item.location
        );
        setReviewsData(tempArray);
        /*  setReplyComment(
          personalizedKeywordsHandler(
            res?.data?.data?.translatedText,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          )
        ); */
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleReviewWithAi = async (lang) => {
    let splittedText = "";
    if (item?.ratingText && item?.ratingText.includes("(Original)\n")) {
      splittedText = item?.ratingText.split("(Original)\n")[1];
    } else if (
      item?.ratingText &&
      item?.ratingText.includes("(Translated by Google)")
    ) {
      splittedText = item?.ratingText.split("(Translated by Google)")[0];
    } else {
      splittedText = item?.ratingText;
    }

    try {
      setLoader(true);
      const res = await api.get(`/review/${item?.id}/reply`);
      if (res.status === 200) {
        setLoader(false);
        setReplyRequired(false);
        // setDisplayReplyComment(true);
        setRuleExist(res?.data?.data?.rule);

        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = res?.data?.data?.reply;
        setReviewsData(tempArray);
        /*  setReplyComment(
          res?.data?.data?.reply
        
        ); */
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const createTaskHandler = async () => {
    if (addedComment.length <= 11) {
      setDescriptionRequired(true);
    }
    if (reviewLocation?.id === undefined) {
      setLocationRequired(true);
    }
    if (!description) {
      setDescriptionRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });
    if (
      description !== undefined &&
      addedComment !== undefined &&
      item?.locationId !== undefined &&
      selectedUser?.length > 0
    ) {
      try {
        setIsLoading(true);
        const res = await api.post(`/tasks`, {
          title: `${item.ratingValue} star review by ${
            item.reviewerTitle || "reviewer"
          } to ${reviewLocation?.internalName}, ${reviewLocation?.city}`,

          companyId: companyData?.id,
          locationId: item?.locationId,
          status: statusData,
          description: addedComment,
          reviewId: item?.id,
          dueDate: selectedDueDate,
          userIds: userIds?.length > 0 ? userIds.toString() : "",
        });

        if (res.status === 201) {
          toast.success("Task Updated Successfully", {
            onClose: () => {
              setIsLoading(false);
              setOpenTicketModel(false);
              // onCancel(true);
            },
          });
          getLocationGoogleReview();
          // setAllCompanies(res.data.data);
        }
      } catch (error) {
        setIsLoading(false);

        toast.error(t("Error"));
      }
    }
  };
  const updateTaskHandler = async () => {
    if (!addedComment) {
      setDescriptionRequired(true);
    }
    if (!addedComment) {
      setDescriptionRequired(true);
    }
    if (reviewLocation?.id === undefined) {
      setLocationRequired(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });
    if (
      addedComment !== undefined &&
      reviewLocation?.id !== undefined &&
      selectedUser?.length > 0
    ) {
      try {
        setIsLoading(true);
        const res = await api.patch(`/tasks/${item?.task?.id}`, {
          // title: `R-${item?.id}`,
          title: `${item.ratingValue} star review by ${
            item.reviewerTitle || "reviewer"
          } to ${reviewLocation?.internalName},${reviewLocation?.city}`,
          companyId: companyData?.id,
          locationId: item?.locationId,
          status: statusData,
          description: addedComment,
          reviewId: item?.id,
          userIds: userIds?.length > 0 ? userIds.toString() : "",
        });
        if (res.status === 200) {
          toast.success("Task Updated Successfully", {
            onClose: () => {
              setIsLoading(false);
              setOpenTicketModel(false);
              // onCancel(true);
            },
          });
          getLocationGoogleReview();
          // setAllCompanies(res.data.data);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error?.response?.data?.message);
        // toast.error("error");
      }
    }
  };

  const handleTemplateLanguages = (template) => {
    setSelectedTemplate([template]);

    if (item?.ratingText && item?.reviewReply === null) {
      const detectedLanguageDescription = template?.templateDescription?.find(
        (item) =>
          item?.language?.toLowerCase() === detectedLanguage?.toLowerCase()
      );
      setSelectedReply(template.templateDescription);
      //  setSelectedTemplate([template]);

      if (detectedLanguageDescription !== undefined) {
        setLanguage(detectedLanguageDescription?.language);
        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
          detectedLanguageDescription?.text,
          item?.reviewerTitle,
          reviewLocation?.internalName,
          item.starRating,
          item.location
        );
        setReviewsData(tempArray);
        /*  setReplyComment(
          personalizedKeywordsHandler(
            detectedLanguageDescription?.text,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          )
        ); */
        setReplyRequired(false);
      } else {
        const enLangTemp = template?.templateDescription?.find(
          (item) => item?.language?.toLowerCase() === "en"
        );
        if (enLangTemp !== undefined) {
          setLanguage(enLangTemp?.language);
          let tempArray = { ...allData };
          tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
            enLangTemp?.text,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          );
          setReviewsData(tempArray);
          /*  setReplyComment(
            personalizedKeywordsHandler(
              enLangTemp?.text,
              item?.reviewerTitle,
              reviewLocation?.internalName,
              item.starRating,
              item.location
            )
          ); */
          setReplyRequired(false);
        }
      }
    } else if (item?.ratingText && item?.reviewReply) {
      const detectedLanguageDescription = template?.templateDescription?.find(
        (item) =>
          item?.language?.toLowerCase() === detectedLanguage?.toLowerCase()
      );
      setSelectedReply(template.templateDescription);
      //  setSelectedTemplate([template]);

      if (detectedLanguageDescription !== undefined) {
        setLanguage(detectedLanguageDescription?.language);
        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
          detectedLanguageDescription?.text,
          item?.reviewerTitle,
          reviewLocation?.internalName,
          item.starRating,
          item.location
        );
        setReviewsData(tempArray);
        /*  setReplyComment(
          personalizedKeywordsHandler(
            detectedLanguageDescription?.text,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          )
        ); */
        setReplyRequired(false);
      } else {
        getTranslatedText(detectedLanguage);
      }
    } else {
      const enLangTemp = template?.templateDescription?.find(
        (item) => item?.language?.toLowerCase() === "en"
      );
      if (enLangTemp !== undefined) {
        setLanguage(enLangTemp?.language);
        setReplyRequired(false);
        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
          enLangTemp?.text,
          item?.reviewerTitle,
          reviewLocation?.internalName,
          item.starRating,
          item.location
        );
        setReviewsData(tempArray);
        /*   setReplyComment(
          personalizedKeywordsHandler(
            enLangTemp?.text,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          )
        ); */
      }
    }
  };
  const handleDisplayReply = async (value) => {
    setLanguage(value?.props?.value);
    if (selectedTemplate?.length > 0) {
      const selectedTempDes = selectedTemplate[0]?.templateDescription?.find(
        (item) => item?.language === value?.props?.value
      );
      if (selectedTempDes !== undefined) {
        setReplyRequired(false);
        let tempArray = { ...allData };
        tempArray.results[index]["replyToEdit"] = personalizedKeywordsHandler(
          selectedTempDes?.text,
          item?.reviewerTitle,
          reviewLocation?.internalName,
          item.starRating,
          item.location
        );
        setReviewsData(tempArray);
        /*  setReplyComment(
          personalizedKeywordsHandler(
            selectedTempDes?.text,
            item?.reviewerTitle,
            reviewLocation?.internalName,
            item.starRating,
            item.location
          )
        ); */
      } else {
        getTranslatedText(value?.props?.value);
      }
    }

    /*   const data =
      (await selectReply) &&
      selectReply.filter((item) => item.language === value.value);
    setReplyComment(
      personalizedKeywordsHandler(
        data[0]?.text,
        item?.reviewerTitle,
        item?.option?.internalName,
        item.starRating,
        item.location
      )
    ); */
  };
  const handleDisplayDate = (item) => {
    let displayDate = "";

    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Delete_reply")}
          description={
            reviewSource !== null &&
            (reviewSource?.name === "google"
              ? t("delete_Reply")
              : t("review_reply_delete"))
          }
          onCancel={handleClose}
          onConfirm={() => deleteReply(item?.google_review_id)}
          loading={isLoading}
        />
        {/*   <DialogTitle id="responsive-dialog-title">
          {t("delete_Reply")}
        </DialogTitle>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button autoFocus onClick={() => deleteReply(item?.google_review_id)}>
            {t("Confirm")}
          </Button>
        </DialogActions> */}
      </Dialog>
      <Modal
        open={openTicketModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TaskModel
            users={users}
            closeTicketModelHanlder={closeTicketModelHanlder}
            item={item}
            createTaskHandler={createTaskHandler}
            updateTaskHandler={updateTaskHandler}
            isLoading={isLoading}
            statusRequired={statusRequired}
            statusData={statusData}
            handleChangeStatus={handleChangeStatus}
            setUserRequired={setUserRequired}
            handleChangeUser={handleChangeUser}
            setSelectedDueDate={setSelectedDueDate}
            selectedDueDate={selectedDueDate}
            descriptionRequired={descriptionRequired}
            commentChangeHandler={commentChangeHandler}
            addedComment={addedComment}
            selectedUser={selectedUser}
            userRequired={userRequired}
            selectedReviewItem={selectedReviewItem}
            setStatusData={setStatusData}
            setAddedComment={setAddedComment}
            setSelectedUser={setSelectedUser}
            reviewLocation={reviewLocation}
            setReviewLocation={setReviewLocation}
          />
        </Box>
      </Modal>
      {/* Tags Modelllll */}
      <Modal
        open={openTagsModel}
        // onClose={handleCloseModalEditUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <Typography
              sx={{ fontWeight: "700", fontSize: "20px", marginBottom: "8px" }}
            >
              {t("Add_Tags")}
            </Typography>
            <Divider variant="middle" />
            <Grid container spacing={2} sx={{ marginTop: "8px" }}>
              <Grid item sm={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",

                    marginTop: "8px",
                  }}
                >
                  {t("Tag_name")}
                </Typography>

                <TextField
                  sx={{ borderRadius: "8px" }}
                  margin="normal"
                  type="text"
                  name="firstName"
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      let tempArry = [...selectedTags];
                      const find = tempArry.find(
                        (data) => data?.name === e.target.value
                      );
                      if (!find) {
                        tempArry = [...selectedTags, { name: e.target.value }];
                        setSelectedTags(tempArry);
                        e.target.value = "";
                      } else {
                        e.target.value = "";
                      }
                    }
                  }}
                />

                <div styles={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      p: 1,
                      gap: "8px",
                      minWidth: 250,
                      borderRadius: 1,
                    }}
                  >
                    {selectedTags?.map((item, index) => (
                      <Item
                        sx={{
                          background: "#EBEBEC",
                          minWidth: "101px",
                          padding: "10px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                          borderRadius: "800px",

                          // margin: "0.3rem",
                          // padding: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <ClearIcon
                          onClick={() => {
                            let tempArry = [...selectedTags];
                            tempArry.splice(index, 1); // 2nd parameter means remove one item only
                            setSelectedTags(tempArry);
                          }}
                          sx={{
                            color: "red",
                            height: "16px",
                            width: "16px",
                            marginLeft: "1px",
                            float: "right",
                          }}
                        />
                      </Item>
                    ))}
                  </Box>
                </div>
              </Grid>

              <Grid item sm={6}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {t("tag_desc")}
                </Typography>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      border: "1px solid #E0E0E0",
                      display: "flex",
                      flexWrap: "wrap",
                      p: 1,
                      m: 1,
                      // minWidth: 350,
                      borderRadius: 1,
                    }}
                  >
                    {tags?.results?.map((item) => (
                      <Item
                        sx={{
                          background: "#EBEBEC",
                          minWidth: "101px",
                          padding: "6px",
                          margin: "0.3rem",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          borderRadius: "800px",
                        }}
                      >
                        <Typography
                          onClick={() => {
                            let tempArry = [...selectedTags];
                            const find = tempArry.find(
                              (data) => data?.name === item?.name
                            );
                            if (!find) {
                              tempArry = [...selectedTags, item];
                              setSelectedTags(tempArry);
                            }
                          }}
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            marginY: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Item>
                    ))}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </>

          <Grid container>
            <Grid item xs={12} sx={{ marginX: "auto", marginTop: "16px" }}>
              <ReviewsCard
                closeTicketModelHanlder={closeTicketModelHanlder}
                item={selectedReviewItem}
                location={reviewLocation}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            <Grid item xs={6} sm={6} md={3} lg={3}></Grid>
            <Grid item xs={6} sm={6} md={9} lg={9}>
              <Box sx={{ float: "right", display: "flex", gap: "8px" }}>
                <>
                  <CommonButton
                    onSubmit={closeTagsModelHanlder}
                    label={t("Cancel")}
                    size="large"
                    displayWhite="true"
                  />

                  <CommonButton
                    isLoading={isLoading}
                    aria-label="save"
                    size="large"
                    onSubmit={saveSelectedTags}
                    label={t("Save")}
                  />
                </>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Box
        sx={{
          paddingY: "1rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          bgcolor: "#ffff",
          paddingTop: "8px",
          paddingBottom: "8px",
          marginBottom: "8px",
        }}
      >
        <Grid container>
          <Grid item sm={1} md={1} lg={1}>
            {reviewSource !== null &&
            reviewSource?.image &&
            !displayGooglePic ? (
              <img
                style={{ width: 60, height: 60, ml: 2 }}
                src={`${baseURL}/${reviewSource?.image}`}
                alt="source"
                onError={() => {
                  setDisplayGooglePic(true);
                }}
              />
            ) : (
              <img
                style={{ width: 60, height: 60, ml: 2 }}
                src={GoogleLogo}
                alt={"Google Logo"}
              />
            )}
          </Grid>

          <Grid
            item
            sm={7}
            md={6}
            lg={5.5}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <StarRatings
              rating={item?.ratingValue}
              starDimension="20px"
              starRatedColor="gold"
              numberOfStars={5}
              starSpacing="2px"
              name="rating"
            />
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "18px",
                lineHeight: "24px",
                color: "#1B2430",
              }}
            >
              <Highlight
                text={item?.reviewerTitle ? item?.reviewerTitle : ""}
                searchTerm={search}
              />
            </Typography>

            <Grid item sm={11} md={11} lg={11} sx={{ marginY: "8px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#495059",
                }}
              >
                {/* {companyData && companyData?.dateFormat !== null
                  ? handleDisplayDate(item?.date)
                  : formatDate(item?.date)} */}
                {formatDate(item?.date)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={2.6}
            sm={1.2}
            md={2}
            lg={2.5}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              float: "right",
            }}
          >
            {item?.replyFetchedByReviewAgent === true && (
              <BootstrapTooltip title={t("reviewAlreadyReplied")}>
                <ReviewAgentFetched
                  style={{
                    /* color: "#bbbbbb" , */ fill: "#06BDFF",
                    marginRight: "10px",
                    height: 18,
                    width: 18,
                  }}
                />
              </BootstrapTooltip>
            )}
            {item?.reviewReply !== null &&
              (item?.reviewReplyType !== "notReplied" &&
              item?.reviewReplyType !== "notSpecified" &&
              item?.reviewReplyType === "manual" ? (
                <BootstrapTooltip title={t("manually_replied_tooltipText")}>
                  <ManuallyReplied
                    style={{
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "openAi" ||
                item?.reviewReplyType === "dailyJobEmotionAI" ||
                item?.reviewReplyType === "backUpJobEmotionAI" ? (
                <BootstrapTooltip title={t("Ai_icon_tooltipText")}>
                  <AILogo
                    style={{
                      /* color: "#bbbbbb" , */ fill: "#06BDFF",
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "reviewAgent" &&
                item?.isDraft === false ? (
                <BootstrapTooltip title={t("reviewAgentTooltip")}>
                  <ReviewAgentIcon
                    style={{
                      /* color: "#bbbbbb" , */ fill: "#06BDFF",
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "template" ||
                item?.reviewReplyType === "backUpJobTemplate" ||
                item?.reviewReplyType === "dailyJobTemplate" ? (
                <BootstrapTooltip title={t("supervised_icon_tooltipText")}>
                  <RepliedWithSupervised
                    style={{
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "openAiByPastJob" ? (
                <BootstrapTooltip title={t("Ai_black-Icon_tooltipText")}>
                  <AiIconForPastReviewsReplied
                    style={{
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "templateByPastJob" ? (
                <BootstrapTooltip title={t("past_supervised_icon_tooltipText")}>
                  <PastReviewsJobWithTemplates
                    style={{
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : item?.reviewReplyType === "thirdParty" ? (
                <BootstrapTooltip title={t("review_source_replied")}>
                  <ThirdPartyReplied
                    style={{
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              ) : (
                ""
              ))}

            {item?.processedByPastReplyJob === true &&
            item?.reviewReply === null ? (
              <>
                <BootstrapTooltip
                  title={`${t("review_reply_text1")} ${item?.ratingValue} ${t(
                    "review-reply_text2"
                  )} ${
                    item?.ratingText !== null && item?.ratingText !== ""
                      ? t("review_reply_text3")
                      : t("review_reply_text4")
                  }  ${t("review_reply_text_5")} `}
                >
                  <ErrorOutlineOutlined
                    style={{
                      color: "#FF1616",
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              </>
            ) : (
              <></>
            )}

            {displayDraft === true || item?.isDraft === true ? (
              <>
                <BootstrapTooltip title={t("Drafted_Tooltip")}>
                  <DraftLogo
                    style={{
                      /* color: "#bbbbbb" , */ color: "#06BDFF",
                      marginRight: "10px",
                      height: 16,
                      width: 16,
                    }}
                  />
                </BootstrapTooltip>
              </>
            ) : (
              <></>
            )}
            {item?.task?.id && (
              <>
                <AssignmentTurnedInIcon
                  sx={{
                    color: "#a8a8a8",
                    fontSize: "14px",
                    marginRight: "5px",
                  }}
                />{" "}
                <BootstrapTooltip title={`${item?.task?.id} `}>
                  <Typography
                    sx={{
                      color: "#a8a8a8",
                      ml: 0.5,
                      mr: 1.5,
                      cursor: "pointer",
                      paddingRight: "6px",
                    }}
                    onClick={() => {
                      openTicketModelHandler(item);
                    }}
                  >
                    {t("task_id")}
                    {item?.task?.id}
                  </Typography>
                </BootstrapTooltip>
              </>
            )}
          </Grid>

          <Grid
            item
            xs={2.9}
            sm={2.8}
            md={3}
            lg={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              float: "right",
            }}
          >
            <FmdGoodIcon sx={{ color: "#a8a8a8", fontSize: "14px" }} />{" "}
            <BootstrapTooltip
              title={`${reviewLocation?.internalName} , ${reviewLocation?.addressLine1},${reviewLocation?.city}`}
            >
              <Typography sx={{ color: "#a8a8a8", ml: 0.5, mr: 1.5 }}>
                {reviewLocation?.internalName
                  ? reviewLocation?.internalName +
                    " " +
                    (reviewLocation?.addressLine1 !== "" &&
                    reviewLocation?.addressLine1 !== null &&
                    reviewLocation?.addressLine1 !== undefined
                      ? reviewLocation?.addressLine1 + "," + " "
                      : "") +
                    (reviewLocation?.city ? reviewLocation?.city : "")
                  : ""}
              </Typography>
            </BootstrapTooltip>
          </Grid>
        </Grid>
        {item?.ratingText ? (
          <Grid container>
            <Grid item sm={1} md={1} lg={1}></Grid>
            <Grid item sm={11} md={11} lg={11} sx={{ marginY: "8px" }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#495059",
                }}
              >
                {item?.ratingText?.includes("Original") ? (
                  <>
                    <Highlight
                      text={
                        handleDisplayRatingText(item?.ratingText).translation
                      }
                      searchTerm={search}
                    />
                    <br />
                    <Highlight
                      text={handleDisplayRatingText(item?.ratingText).original}
                      searchTerm={search}
                    />
                  </>
                ) : item?.translatedText ? (
                  <Highlight
                    text={`(Original) ${item?.ratingText}`}
                    searchTerm={search}
                  />
                ) : (
                  <Highlight text={item?.ratingText} searchTerm={search} />
                )}
              </Typography>
            </Grid>

            {item?.translatedText && (
              <>
                <Grid item sm={1} md={1} lg={1}></Grid>
                <Grid item sm={11} md={11} lg={11}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#495059",
                    }}
                  >
                    {item?.translatedText}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item sm={1} md={1} lg={1}></Grid>
          </Grid>
        ) : (
          <>
            <Grid container>
              <Grid item sm={1} md={1} lg={1}></Grid>
              <Grid item sm={11} md={11} lg={11} sx={{ marginY: "8px" }}>
                <Typography
                  sx={{
                    color: "#a8a8a8",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("not_reviewed")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {item?.reviewReply || toggleInput ? (
          <Grid
            container
            md={12}
            sx={{ marginBottom: "8px", marginTop: "8px" }}
          >
            <Grid item sm={1} md={1} lg={1}></Grid>
            <Grid item xs={12} sm={11} md={11} lg={11}>
              <Box sx={{ display: "row" }}>
                {item?.replyToEdit !== null ? item?.replyToEdit?.length : 0}
                /4000{" "}
                {(item?.replyToEdit !== null ? item?.replyToEdit?.length : 0) >=
                  4000 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#FF0000",
                      lineHeight: "15px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("text_limit_text")}
                  </Typography>
                )}
              </Box>
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid
                  item
                  xs={11.4}
                  md={
                    showReplyComment === true &&
                    reviewSource !== null &&
                    reviewSource?.name !== "google"
                      ? 9.5
                      : 12
                  }
                  sx={{ margin: 0 }}
                  sm={11}
                  lg={11.5}
                >
                  <TextareaAutosize
                    style={{
                      border:
                        replyRequired || item?.replyToEdit?.length > 4000
                          ? "1px solid #FF0000"
                          : "1px solid #c4c4c4",
                      fontFamily: "Segoe UI",
                      fontStyle: "Normal",
                      padding: "12px 12px 12px 12px",
                      lineHeight: "24px",
                      width: "100%",
                      borderRadius: "4px",
                      color: "#495059",
                      fontWeight: "400",
                      fontSize: "16px",
                      wordSpacing: "normal",

                      "&:hover ": {
                        border: "1px solid #54d2ff",
                      },

                      //   padding: "15px",
                    }}
                    multiline
                    fullWidth
                    // ref={textArea}
                    aria-label="empty textarea"
                    placeholder="Enter Your Reply"
                    value={item?.replyToEdit}
                    disabled={!showReplyComment}
                    onChange={(e) => {
                      // if (e.target?.value?.length < 4000) {
                      //  setDisplayReplyComment(true);
                      setRuleExist(true);
                      let tempArray = { ...allData };
                      tempArray.results[index]["replyToEdit"] = e.target.value;
                      setReviewsData(tempArray);
                      //  setReplyComment(e.target.value);
                      //  }
                      if (e.target?.value?.length <= 0) {
                        setReplyRequired(true);
                      } else {
                        setReplyRequired(false);
                      }
                    }}
                    InputProps={{
                      disableunderline: true,
                    }}
                  />
                </Grid>

                {reviewSource !== null && reviewSource?.name !== "google" && (
                  <Grid
                    xs={0.6}
                    sm={1}
                    md={2.5}
                    lg={0.5}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "right",
                    }}
                  >
                    {item?.replyToEdit?.length > 0 && (
                      <BootstrapTooltip
                        title={
                          reviewSource !== null &&
                          reviewSource?.name === "google"
                            ? t("Copy_Clipboard")
                            : reviewSource !== null &&
                              reviewSource?.name !== "google" &&
                              item?.url !== null
                            ? t("copy_and_reply_message")
                            : t("copy_message")
                        }
                      >
                        <ContentCopyIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(item?.replyToEdit);
                            setDisplayText(true);
                            setTimeout(() => {
                              setDisplayText(false);
                              if (
                                reviewSource !== null &&
                                reviewSource?.name !== "google" &&
                                item?.url !== null
                              ) {
                                postReplyOtherHandler(item?.id);
                              }
                            }, 500);
                          }}
                        />
                      </BootstrapTooltip>
                    )}

                    {displayText && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#495059",
                          lineHeight: "15px",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {t("Copied")}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
              {showReplyComment && ruleExist === false && (
                <Grid container sx={{ marginTop: "16px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex" }}
                  >
                    <ErrorOutlineIcon fontSize="small" />
                    <Typography sx={{ marginLeft: "8px", color: "#495059" }}>
                      {t("review_reply_text")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {showReplyComment && ruleExist === true && (
          <Grid
            justifyContent="flex-end"
            container
            spacing={2}
            marginTop="8px"
            marginBottom="8px"
            alignItems="center"
          >
            <Grid item md={8}></Grid>
          </Grid>
        )}
        <Grid container sx={{ marginBottom: "10px" }}>
          <Grid md={1} xs={1} lg={1}></Grid>
          <Grid xs={12} sm={11} md={11} lg={11}>
            <Stack direction="row" spacing={2}>
              {item?.reviewTags?.map((item) => (
                <Item
                  sx={{
                    background: "#EBEBEC",

                    minWidth: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "700px",
                    flexWrap: "nowrap",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "400", padding: "2px" }}
                  >
                    {item?.tag?.name}
                  </Typography>
                </Item>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item lg={1} md={1}></Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {showReplyComment && (
              <Autocomplete
                id="combo-box-demo"
                options={
                  allTemplate?.results?.length > 0 ? allTemplate?.results : []
                }
                getOptionLabel={(option) => (option?.title ? option.title : "")}
                style={{
                  width: "100%",
                  background: "white",
                  borderRadius: "8px",
                  color: "#495059",
                  height: "40px",
                }}
                // value={locationId}
                onChange={(e, value) => {
                  handleTemplateLanguages(value);
                  setRuleExist(true);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("placeholder_reviews")}
                    {...params}
                    size="medium"
                    //  sx={{ padding: "7px", height: "40px" }}
                  />
                )}
              ></Autocomplete>
            )}

            {selectedTemplate?.length > 0 && language !== "" && (
              <Select
                // defaultValue={"en"}
                sx={{
                  ml: 1,
                  width: "100%",
                  background: "white",
                  height: "51px",
                  color: "#495059",
                }}
                placeholder="Select language for autofill"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                // label="Select"
                onChange={(e, value) => {
                  handleDisplayReply(value);
                  setRuleExist(true);
                }}
              >
                {languagesData.map((item) => (
                  <MenuItem value={item.value}>{item.language}</MenuItem>
                ))}
              </Select>
            )}
          </Grid>

          {showReplyComment === true && (
            <Grid item lg={0.5} md={0.5} style={{ padding: "15px" }}>
              {(item?.ratingText !== null || item?.ratingValue >= 0) && (
                <BootstrapTooltip title={t("Ai_Emotion_Logo_Tooltip")}>
                  <IconButton
                    disabled={loader}
                    onClick={() => {
                      setRuleExist(true);
                      handleReviewWithAi();
                    }}
                    sx={{ padding: "0px" }}
                  >
                    <AiLogoGrey style={{ cursor: "pointer" }} />
                  </IconButton>
                </BootstrapTooltip>
              )}
            </Grid>
          )}

          <Grid item lg={0.5} md={0.5} sx={{ height: "30px" }}>
            {loader ? (
              <CircularProgress
                color="secondary"
                style={{
                  width: "25px",
                  height: "25px",
                  marginLeft: "5px",
                  marginTop: "8px",
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={showReplyComment ? 4.5 : 5.5}
            lg={showReplyComment ? 4.5 : 5.5}
          >
            <Box
              sx={{
                float: "right",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {((item?.ratingText !== null && item?.ratingText !== "") ||
                (item?.reviewReply !== null && item?.reviewReply !== "")) &&
                (translationLoading ? (
                  <CircularProgress
                    color="secondary"
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: "10px",
                      // marginTop: "5px",
                      color: "#757575",
                    }}
                  />
                ) : (
                  !item?.translatedText &&
                  (loginUser?.language === "English" &&
                  item?.ratingText?.includes("Original") ? null : (
                    <BootstrapTooltip
                      title={`${t("translate_text")} ${loginUser?.language}`}
                    >
                      <IconButton
                        disabled={replyRequired}
                        onClick={() => {
                          if (
                            item?.ratingText !== null &&
                            item?.ratingText !== ""
                          ) {
                            handleTranslateText(item?.ratingText);
                          }

                          if (
                            item?.reviewReply !== null &&
                            item?.reviewReply !== ""
                          ) {
                            handleTranslateReviewReplyText(item?.reviewReply);
                          }
                        }}
                        sx={{ cursor: "pointer", padding: "0px" }}
                      >
                        <img
                          style={{ width: 40, height: 40, ml: 2 }}
                          src={TranslateIcon}
                          alt={"Translate Icon"}
                        />
                      </IconButton>
                    </BootstrapTooltip>
                  ))
                ))}
              {item?.translatedText && (
                <BootstrapTooltip title={t("translate_text_to_orignal")}>
                  <IconButton
                    disabled={replyRequired}
                    onClick={() => {
                      handleTranslateTextToOriginal(item);
                    }}
                    sx={{ cursor: "pointer", padding: "0px" }}
                  >
                    <img
                      style={{ width: 40, height: 40, ml: 2 }}
                      src={TranslateIcon}
                      alt={"Translate Icon"}
                    />
                  </IconButton>
                </BootstrapTooltip>
              )}

              {!showReplyComment && (
                <>
                  <Button
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, item)}
                    variant="outlined"
                    color="inherit"
                  >
                    {t("Actions")} <KeyboardArrowDownIcon />
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {item?.task?.id ? (
                      editPermissionTasks && viewPermissionTasks ? (
                        <MenuItem
                          onClick={() => {
                            openTicketModelHandler(item);
                          }}
                        >
                          <Typography>{t("Manage_Task")}</Typography>
                        </MenuItem>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem disabled>
                              <Typography>{t("Manage_Task")}</Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      )
                    ) : addPermissionTasks && viewPermissionTasks ? (
                      <MenuItem
                        onClick={() => {
                          openTicketModelHandler(item);
                        }}
                      >
                        <Typography>{t("Create_Task")}</Typography>
                      </MenuItem>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <MenuItem disabled>
                            <Typography>{t("Create_Task")}</Typography>
                          </MenuItem>
                        </span>
                      </BootstrapTooltip>
                    )}

                    {item?.reviewTags?.length > 0 ? (
                      editPermission && viewPermission ? (
                        <MenuItem
                          onClick={() => {
                            openTagsModelHanlder();
                          }}
                        >
                          <Typography>{t("Manage_Tags")}</Typography>
                        </MenuItem>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem disabled>
                              <Typography>{t("Manage_Tags")}</Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      )
                    ) : addPermission && viewPermission ? (
                      <MenuItem
                        onClick={() => {
                          openTagsModelHanlder();
                        }}
                      >
                        <Typography>{t("Add_Tags")}</Typography>
                      </MenuItem>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <MenuItem disabled>
                            <Typography>{t("Add_Tags")}</Typography>
                          </MenuItem>
                        </span>
                      </BootstrapTooltip>
                    )}
                    {item?.reviewReply &&
                      (deletePermission && viewPermission ? (
                        handleCheckEnabledForReply(
                          item?.thirdPartyReviewSourcesId,
                          item?.locationId
                        ) === true ? (
                          <BootstrapTooltip title={t("comingSoon")}>
                            <span>
                              <MenuItem disabled={true}>
                                <Typography>{t("Delete_reply")}</Typography>
                              </MenuItem>
                            </span>
                          </BootstrapTooltip>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              setOpen(true);
                              setAnchorEl(null);
                            }}
                          >
                            <Typography>{t("Delete_reply")}</Typography>
                          </MenuItem>
                        )
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem disabled>
                              <Typography>{t("Delete_reply")}</Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      ))}
                  </Menu>
                  {item?.reviewReply ? (
                    editPermission && viewPermission ? (
                      handleCheckEnabledForReply(
                        item?.thirdPartyReviewSourcesId,
                        item?.locationId
                      ) === true ? (
                        <BootstrapTooltip title={t("comingSoon")}>
                          <span>
                            <Button variant="contained" disabled={true}>
                              {" "}
                              {t("Edit_Reply")}
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setToggleInput(true);
                            setShowReplyComment(true);

                            if (item?.ratingText) {
                              getDetectedLanguage(false);
                            }
                          }}
                        >
                          {t("Edit_Reply")}
                        </Button>
                      )
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button variant="contained" disabled>
                            {t("Edit_Reply")}
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )
                  ) : addPermission && viewPermission ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setToggleInput(true);
                        setShowReplyComment(true);
                        //  getReviewDetail();
                        if (item?.ratingText) {
                          getDetectedLanguage(true);
                        } else {
                          handleReviewWithAi();
                        }
                      }}
                    >
                      {t("Reply")}
                    </Button>
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <Button variant="contained" disabled>
                          {t("Reply")}
                        </Button>
                      </span>
                    </BootstrapTooltip>
                  )}
                </>
              )}
              {showReplyComment && (
                <>
                  <Box
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <CancelButton
                      sx={{ fontSize: "14px" }}
                      onClick={() => {
                        // setDisplayReplyComment(false);
                        setReplyRequired(false);
                        setToggleInput(false);
                        setShowReplyComment(false);
                        setLanguage("");
                        let tempArray = { ...allData };
                        tempArray.results[index]["replyToEdit"] =
                          item?.reviewReply;
                        setReviewsData(tempArray);
                        //  setReplyComment("");
                      }}
                      label="Cancel"
                    />
                    {reviewSource !== null &&
                      reviewSource?.name !== "google" &&
                      item?.url !== null && (
                        <BootstrapTooltip
                          title={
                            item?.url !== null
                              ? t("copy_reply")
                              : t("disableButton") +
                                `${reviewSource?.name}` +
                                t("disableButtonTwo")
                          }
                        >
                          {/*   <Typography>textttttttttttttt"</Typography> */}
                          {/*  <CommonButton
                          isLoading={isLoading}
                          aria-label="save"
                          size="large"
                          onSubmit={() => window.open(item.url, "_blank")}
                          label={t("Post_it")}
                        /> */}
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  item?.replyToEdit
                                );

                                postReplyOtherHandler(item?.id);
                              }}
                              /*  disabled={
                                reviewSource !== null &&
                                reviewSource?.name !== "google" &&
                                item?.url===null
                              } */
                              //  onClick={() => postReplyHandler()}
                              sx={{
                                height: "52px",
                                // padding: "14px 20px",
                                fontSize: "14px",
                                marginLeft: "10px",
                                borderRadius: "8px",
                                color: "#FFFFFF",
                                /*   background:
                              displayWhite === "true"
                                ? "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x"
                                : "" || displayBlack === "true"
                                ? "#1B2430"
                                : "", */
                                width: "auto",
                                border: "none",
                              }}
                            >
                              {t("Post_it")}
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}

                    {reviewSource !== null &&
                      reviewSource?.name !== "google" &&
                      item?.url === null && (
                        <BootstrapTooltip title={t("Save_reply")}>
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                postReplyOtherHandler(item?.id);
                                /*  navigator.clipboard.writeText(replyComment);
                              setDisplayTextTwo(true);
                                setTimeout(() => {
                                  setDisplayTextTwo(false);
                                }, 3000); */
                              }}
                              disabled={
                                item?.replyToEdit?.length <= 0 ||
                                item?.replyToEdit?.length > 4000 ||
                                saveLoading ||
                                replyRequired
                              }
                              /*  disabled={
                              reviewSource !== null &&
                              reviewSource?.name !== "google" &&
                              item?.url===null
                            } */
                              //  onClick={() => postReplyHandler()}
                              sx={{
                                height: "52px",
                                // padding: "14px 20px",
                                fontSize: "14px",
                                marginLeft: "10px",
                                borderRadius: "8px",
                                color: "#FFFFFF",
                                /*   background:
                              displayWhite === "true"
                                ? "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x"
                                : "" || displayBlack === "true"
                                ? "#1B2430"
                                : "", */
                                width: "auto",
                                border: "none",
                              }}
                            >
                              {saveLoading ? (
                                <CircularProgress
                                  color="secondary"
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    // marginLeft: "5px",
                                    // marginTop: "8px",
                                    color: "#757575",
                                  }}
                                />
                              ) : (
                                t("Post_review")
                              )}
                            </Button>
                          </span>
                        </BootstrapTooltip>
                      )}
                    {displayTextTwo && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#495059",
                          lineHeight: "15px",
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {t("Copied")}
                      </Typography>
                    )}

                    {reviewSource !== null &&
                      reviewSource?.name === "google" && (
                        <CommonButton
                          isLoading={isLoading}
                          disabled={
                            replyRequired ||
                            isLoading ||
                            item?.replyToEdit?.length > 4000
                          }
                          aria-label="save"
                          size="large"
                          onSubmit={() =>
                            postReplyHandler(item.google_review_id)
                          }
                          label={t("Save")}
                        />
                      )}
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default GoogleReviews;
