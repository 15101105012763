import React, { useEffect, useState } from "react";
import { Grid, Card, Paper, Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PlatformOverview from "./PlatformOverview";
import ReviewVelocity from "./ReviewVelocity";
import RatingBreakdown from "./RatingBreakdown";
import RepliesRatingBreakdown from "./RepliesRatingBreakdown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TimeSeriesChart from "../ReviewChart";
import RatingVariance from "./RatingVariance";
import ReviewsAndAverageRating from "./ReviewsAndAverageRating";
import Keywords from "./Keywords";
import ReviewDistributionGraph from "./ReviewDistributionGraph";

import { useStyles } from "../../Style/style";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import EmotionAiTopics from "./EmotionAiTopics";
import useDateFormat from "../../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const ReviewsDetail = ({
  ratingVarianceData,
  ratingVarianceLoading,
  allLocations,
  platformOverviewData,
  platformOverviewLoading,
  reviewVelocityData,
  ratingBreakDownData,
  ratingLoading,
  onlineReputationData,
  reputationLoading,
  reviewTopics,
  selectedTopic,
  handleChangeTopic,
  keywordsData,
  keywordsLoading,
  kFormatter,
  platformData,
  getReviewTopics,
  setReviewTopics,
  topicsLoading,
  actionPlans,
  handleOpenReviewModel,
  locations,
  setLocations,
  startDate,
  endDate,
  filteredLocations,
  filterBreakdownLocation,
  graphData,
  setGraphData,
  sentimentValue,
  language,
  setVarianceType,
  setSelectedLocationId,
  setThirdPartySourceId,
  getAllReviewsData,
  setRatingTextKeywords,
  setRatingBreakdownValue,
  handleDisplayNames,
  setApiCallFrom,
  tooltipContent,
  setKeywordsSentiment,
  allGroups,
  handleDisplayGroupNames,
  setSelectedGroupId,
  downloadFormat,
  setDownloadFormat,
  ratingVarianceRef,
  platformRef,
  keywordsRef,
  reviewDistributionData,
  reviewDistributionLoading,
  reviewAverageRatingData,
  reviewAverageRatingLoading,
  viewPermission,
  handleDisplayGroupNameSimple,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  handleDisplayExportLocationName,
  prevTooltipContent,
  reviewDistributionTableData,
  setFeaturesObj,
  featuresObj,
  topicsData,
  topicsDataLoading,
  setSelectedTopicName,
}) => {
  const classes = useStyles();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [labelsArray, setLabelsArray] = useState([]);

  const { formatDate } = useDateFormat();

  useEffect(() => {
    handleDisplayData();
  }, [onlineReputationData]);

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function handleDisplayLocationName(locationId) {
    let location = {};
    let locationName = "";
    if (allLocations?.length > 0) {
      location = allLocations?.find((item) => item?.id === locationId);
    }

    locationName =
      location?.internalName +
      " " +
      (location?.city !== null && location?.city !== ""
        ? "(" + location?.city + ")"
        : "");
    return locationName;
  }
  function handleDisplayGroupNameOnlineRepuation(groupId) {
    let group = {};

    if (allGroups?.rows?.length > 0) {
      group = allGroups?.rows?.find((item) => item?.id === groupId);
    }
    return group?.name
      ? group.name.charAt(0).toUpperCase() + group.name.slice(1)
      : "";
  }
  const handleDisplayLabel = (locationId, groupId) => {
    if (locationId && !groupId) {
      return handleDisplayLocationName(locationId);
    } else if (!locationId && groupId) {
      return handleDisplayGroupNameOnlineRepuation(groupId);
    } else {
      return "Fallback Label";
    }
  };

  const handleDisplayData = () => {
    if (onlineReputationData?.length > 0) {
      const groupedData = {};

      onlineReputationData.forEach((item) => {
        const locationId = item.locationId;
        const groupId = item.groupId;
        const dateOfAverage = item.dateOfAverage;

        // Create a unique key based on both locationId and groupId
        const key = `${locationId || ""}_${groupId || ""}`;

        if (!groupedData[key]) {
          groupedData[key] = {
            locationId,
            groupId,
            data: [],
            borderColor: getRandomColor(),
          };
        }

        groupedData[key].data.push({
          timeStamp: dateOfAverage,
          value: parseFloat(item.avgRating || 0),
        });
      });

      let obj = JSON.stringify(onlineReputationData);

      const dummyObjjj = JSON.parse(obj);
      let arr = [];
      dummyObjjj?.map((item) => {
        arr.push(item?.dateOfAverage);
      });
      if (arr?.length > 0) {
        const sortedResponse = arr.sort((a, b) => new Date(a) - new Date(b));
        const filteredLabels = new Set(sortedResponse);

        let labels = [];

        filteredLabels.forEach((item) => {
          labels.push(item);
        });

        setLabelsArray(labels);

        const datasets = Object.values(groupedData).map((dataset) => {
          const sortedData = dataset.data.sort(
            (a, b) => new Date(a.timeStamp) - new Date(b.timeStamp)
          );
          let arr = labels.map((label) => {
            const dataItem = sortedData.find(
              (item) => item.timeStamp === label
            );
            return dataItem ? dataItem.value : null;
          });

          return {
            label: handleDisplayLabel(dataset.locationId, dataset.groupId),
            data: arr,
            borderColor: dataset.borderColor || getRandomColor(),
            borderWidth: 2,
            fill: false,
            spanGaps: true,
            pointStyle: "circle",
            pointRadius: 2,
            pointBackgroundColor: dataset.borderColor || getRandomColor(),
          };
        });
        setGraphData(datasets);
      }
    }
  };
  function handleDisplayVariance(lastValue, currentValue) {
    const current = currentValue;
    const lastYear = lastValue === null ? 0 : lastValue;

    if (current === 0 || current === null) {
      return "--";
    }

    if (
      isNaN(current) ||
      isNaN(lastYear) ||
      typeof lastValue === "undefined" ||
      typeof currentValue === "undefined"
    ) {
      return 0;
    }

    let diff;
    let percentage;

    if (current > lastYear) {
      diff = current - lastYear;
      percentage = (diff / lastYear) * 100;

      if (lastYear === 0) {
        diff = 1;
        percentage = 100;
      }
    } else {
      diff = current - lastYear;
      percentage = (diff / current) * 100;
    }

    if (percentage === Infinity || percentage === -Infinity) {
      return 0;
    }

    return diff < 0 ? `${percentage.toFixed(2)}` : percentage.toFixed(2);
  }

  function handleDisplayName(locationId, lastValue, currentValue) {
    let location = {};
    let locationName = "";

    if (allLocations?.length > 0) {
      location = allLocations?.find((item) => item?.id === locationId);
    }

    const variance = handleDisplayVariance(lastValue, currentValue);

    locationName = (
      <div>
        <span className={classes.ratingHeadingsLoc}>
          {location?.internalName}
          {variance > 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowUpwardIcon className={classes.greenIconClass} />
            </BootstrapTooltip>
          ) : variance < 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowDownwardIcon className={classes.redIconClass} />
            </BootstrapTooltip>
          ) : variance === 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowForwardIcon className={classes.orangeIconClass} />
            </BootstrapTooltip>
          ) : (
            ""
          )}
        </span>
        <br />
        <i className={classes.ratingHeadingsLocAddress}>
          {((location?.addressLine1 !== "" &&
            location?.addressLine1 !== null) ||
            (location?.city !== null && location?.city !== "")) &&
            "(" +
              (location?.addressLine1 !== "" && location?.addressLine1 !== null
                ? location?.addressLine1
                : "") +
              (location?.addressLine1 !== "" &&
              location?.addressLine1 !== null &&
              location?.city !== null &&
              location?.city !== ""
                ? ","
                : "") +
              (location?.city !== null && location?.city !== ""
                ? ` ${location?.city} `
                : "") +
              ")"}
        </i>
        <br />
      </div>
    );

    return locationName;
  }
  function handleDisplayGroupName(groupId, lastValue, currentValue) {
    let group = {};
    let groupName = "";

    if (allGroups?.rows?.length > 0) {
      group = allGroups?.rows?.find((item) => item?.id === groupId);
    }

    const variance = handleDisplayVariance(lastValue, currentValue);

    groupName = (
      <div>
        <span className={classes.ratingHeadingsLoc}>
          {group?.name
            ? group.name.charAt(0).toUpperCase() + group.name.slice(1)
            : ""}
          {variance > 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowUpwardIcon className={classes.greenIconClass} />
            </BootstrapTooltip>
          ) : variance < 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowDownwardIcon className={classes.redIconClass} />
            </BootstrapTooltip>
          ) : variance === 0 ? (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`vs ${tooltipContent} - ${formatDate(startDate)}`}
                </h1>
              }
            >
              <ArrowForwardIcon className={classes.orangeIconClass} />
            </BootstrapTooltip>
          ) : (
            ""
          )}
        </span>
      </div>
    );

    return groupName;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.cardbox}>
            <Paper variant="outlined">
              <ReviewsAndAverageRating
                startDate={startDate}
                endDate={endDate}
                setSelectedGroupId={setSelectedGroupId}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                ratingVarianceRef={ratingVarianceRef}
                handleDisplayName={handleDisplayName}
                handleDisplayGroupName={handleDisplayGroupNames}
                reviewAverageRatingData={reviewAverageRatingData}
                reviewAverageRatingLoading={reviewAverageRatingLoading}
                tooltipContent={tooltipContent}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
              <Divider />
              <RatingVariance
                getRandomColor={getRandomColor}
                handleDisplayLocationName={handleDisplayLocationName}
                handleDisplayName={handleDisplayName}
                handleDisplayGroupName={handleDisplayGroupName}
                handleDisplayGroupNames={handleDisplayGroupNames}
                handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                handleDisplayNames={handleDisplayNames}
                ratingVarianceData={ratingVarianceData}
                handleDisplayVariance={handleDisplayVariance}
                ratingVarianceLoading={ratingVarianceLoading}
                kFormatter={kFormatter}
                handleOpenReviewModel={handleOpenReviewModel}
                locations={locations}
                filteredLocations={filteredLocations}
                setVarianceType={setVarianceType}
                setSelectedLocationId={setSelectedLocationId}
                getAllReviewsData={getAllReviewsData}
                startDate={startDate}
                endDate={endDate}
                setSelectedGroupId={setSelectedGroupId}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                tooltipContent={tooltipContent}
                prevTooltipContent={prevTooltipContent}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
              {companyData !== undefined &&
                companyData !== null &&
                (companyData?.id === 175 || companyData?.id === 127) && (
                  <>
                    <Divider />
                    <Box className={classes.mainContainer}>
                      <EmotionAiTopics
                        graphData={graphData}
                        onlineReputationData={onlineReputationData}
                        labelsArray={labelsArray}
                        reviewTopics={reviewTopics}
                        selectedTopic={selectedTopic}
                        handleChangeTopic={handleChangeTopic}
                        getReviewTopics={getReviewTopics}
                        setReviewTopics={setReviewTopics}
                        topicsLoading={topicsLoading}
                        startDate={startDate}
                        endDate={endDate}
                        reputationLoading={reputationLoading}
                        downloadFormat={downloadFormat}
                        setDownloadFormat={setDownloadFormat}
                        viewPermission={viewPermission}
                        setFeaturesObj={setFeaturesObj}
                        featuresObj={featuresObj}
                        topicsData={topicsData}
                        tooltipContent={tooltipContent}
                        prevTooltipContent={prevTooltipContent}
                        topicsDataLoading={topicsDataLoading}
                        handleOpenReviewModel={handleOpenReviewModel}
                        setRatingBreakdownValue={setRatingBreakdownValue}
                        setSelectedTopicName={setSelectedTopicName}
                      />
                    </Box>{" "}
                  </>
                )}
              <Divider />
              {companyData !== undefined &&
                companyData !== null &&
                companyData?.id !== 175 &&
                companyData?.id !== 127 && (
                  <Box className={classes.mainContainer}>
                    <TimeSeriesChart
                      graphData={graphData}
                      onlineReputationData={onlineReputationData}
                      labelsArray={labelsArray}
                      reviewTopics={reviewTopics}
                      selectedTopic={selectedTopic}
                      handleChangeTopic={handleChangeTopic}
                      getReviewTopics={getReviewTopics}
                      setReviewTopics={setReviewTopics}
                      topicsLoading={topicsLoading}
                      startDate={startDate}
                      endDate={endDate}
                      reputationLoading={reputationLoading}
                      downloadFormat={downloadFormat}
                      setDownloadFormat={setDownloadFormat}
                      viewPermission={viewPermission}
                      setFeaturesObj={setFeaturesObj}
                      featuresObj={featuresObj}
                      topicsData={topicsData}
                      tooltipContent={tooltipContent}
                      prevTooltipContent={prevTooltipContent}
                      topicsDataLoading={topicsDataLoading}
                      handleOpenReviewModel={handleOpenReviewModel}
                      setRatingBreakdownValue={setRatingBreakdownValue}
                      setSelectedTopicName={setSelectedTopicName}
                    />
                  </Box>
                )}
              <Box sx={{ paddingTop: "30px" }}>
                <Divider />
              </Box>
              <PlatformOverview
                platformOverviewData={platformOverviewData}
                platformOverviewLoading={platformOverviewLoading}
                platformData={platformData}
                handleOpenReviewModel={handleOpenReviewModel}
                setSelectedLocationId={setSelectedLocationId}
                setThirdPartySourceId={setThirdPartySourceId}
                setApiCallFrom={setApiCallFrom}
                tooltipContent={tooltipContent}
                startDate={startDate}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                platformRef={platformRef}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />

              <Divider />

              <ReviewDistributionGraph
                keywordsData={keywordsData}
                handleDisplayName={handleDisplayNames}
                handleDisplayGroupName={handleDisplayGroupNameSimple}
                reviewDistributionLoading={reviewDistributionLoading}
                startDate={startDate}
                endDate={endDate}
                sentimentValue={sentimentValue}
                language={language}
                locations={locations}
                setRatingTextKeywords={setRatingTextKeywords}
                setSelectedLocationId={setSelectedLocationId}
                handleOpenReviewModel={handleOpenReviewModel}
                setKeywordsSentiment={setKeywordsSentiment}
                reviewDistributionData={reviewDistributionData}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                reviewDistributionTableData={reviewDistributionTableData}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
                tooltipContent={tooltipContent}
              />
              <Divider />
              <RatingBreakdown
                ratingBreakDownData={ratingBreakDownData}
                ratingLoading={ratingLoading}
                handleDisplayName={handleDisplayNames}
                locations={locations}
                filterBreakdownLocation={filterBreakdownLocation}
                handleOpenReviewModel={handleOpenReviewModel}
                setRatingBreakdownValue={setRatingBreakdownValue}
                setSelectedLocationId={setSelectedLocationId}
                setApiCallFrom={setApiCallFrom}
                tooltipContent={tooltipContent}
                startDate={startDate}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                handleDisplayGroupName={handleDisplayGroupNames}
                setSelectedGroupId={setSelectedGroupId}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
              <Divider />

              <RepliesRatingBreakdown
                ratingBreakDownData={ratingBreakDownData}
                ratingLoading={ratingLoading}
                handleDisplayName={handleDisplayNames}
                locations={locations}
                filterBreakdownLocation={filterBreakdownLocation}
                handleOpenReviewModel={handleOpenReviewModel}
                setRatingBreakdownValue={setRatingBreakdownValue}
                setSelectedLocationId={setSelectedLocationId}
                setApiCallFrom={setApiCallFrom}
                tooltipContent={tooltipContent}
                startDate={startDate}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                handleDisplayGroupName={handleDisplayGroupNames}
                setSelectedGroupId={setSelectedGroupId}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
              <Divider />
              <ReviewVelocity
                handleDisplayName={handleDisplayName}
                handleDisplayGroupName={handleDisplayGroupName}
                handleDisplayGroupNames={handleDisplayGroupNames}
                handleDisplayGroupNameSimple={handleDisplayGroupNameSimple}
                ratingVarianceLoading={ratingVarianceLoading}
                reviewVelocityData={reviewVelocityData}
                handleDisplayReviewVelocity={handleDisplayVariance}
                kFormatter={kFormatter}
                locations={locations}
                filteredLocations={filteredLocations}
                handleOpenReviewModel={handleOpenReviewModel}
                setVarianceType={setVarianceType}
                setSelectedLocationId={setSelectedLocationId}
                getAllReviewsData={getAllReviewsData}
                setSelectedGroupId={setSelectedGroupId}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                handleDisplayExportLocationName={
                  handleDisplayExportLocationName
                }
                viewPermission={viewPermission}
                pdfChecked={pdfChecked}
                pdfDownloadChecked={pdfDownloadChecked}
                openDownloadPdfModal={openDownloadPdfModal}
                openReportEmailModal={openReportEmailModal}
                tooltipContent={tooltipContent}
                startDate={startDate}
                endDate={endDate}
                prevTooltipContent={prevTooltipContent}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
              <Divider />
              <Keywords
                keywordsData={keywordsData}
                handleDisplayName={handleDisplayNames}
                keywordsLoading={keywordsLoading}
                startDate={startDate}
                endDate={endDate}
                sentimentValue={sentimentValue}
                language={language}
                locations={locations}
                setRatingTextKeywords={setRatingTextKeywords}
                setSelectedLocationId={setSelectedLocationId}
                handleOpenReviewModel={handleOpenReviewModel}
                setKeywordsSentiment={setKeywordsSentiment}
                keywordsRef={keywordsRef}
                downloadFormat={downloadFormat}
                setDownloadFormat={setDownloadFormat}
                viewPermission={viewPermission}
                setFeaturesObj={setFeaturesObj}
                featuresObj={featuresObj}
              />
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewsDetail;
